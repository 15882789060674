import AxiosInstance from "../AxiosInstance";
export const GET_ALL_PRODUCT_API = async (user_id) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + "/product/get-all",
      {
        params: { user_id },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const GET_PRODUCT_ID_API = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL +
        `/product/get-by-id?product_id=${payload}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_TO_FAVOURITE = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + `/product/get-favourite-product`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const ADD_TO_FAVOURITE = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + `/product/add-to-favourite`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const PURCHASE_PRODUCT = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + `/paypal/create-order`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const VRIFY_ORDER_PRODUCT = async (orderId, transactionId) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL +
        `/paypal/verify-order?order_id=${orderId}&transaction_id=${transactionId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_ORDER_PRODUCT_ALL = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + `/order/get-orders`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_ORDER_DETAILS = async (id) => {
    try {
      const response = await AxiosInstance.get(
        AxiosInstance.defaults.baseURL +`/order/get-order-details?order_id=${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
