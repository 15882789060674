import React from "react";
import { Box, Modal, Backdrop } from "@mui/material";

const CustomModel = (props) => {
  const { open, onClose, children } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        onClick: onClose,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding:'50px 50px',
          ...props.sx,
        }}
      >
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            position:'relative',
            borderRadius:'16px',
            margin:'0 auto',
            height:'800',
            overflowX:'auto'
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModel;
