import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { ManufacturLayoutRoute } from "../../routes";
import Header from "./Header/Header";
const ManufacturLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userCheck = JSON.parse(localStorage.getItem("RoaveBox"));
    const currentPath = window.location.pathname;
    if (userCheck?.token && userCheck?.role === Number(1) && currentPath === '/manufactur') {
      navigate("/manufactur/dashboard");
    } else if (!userCheck?.token || userCheck?.role !== Number(1)) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <div style={{ display: "flex", width: "100%", height: "100vh" }}>
      <Routes>
        {ManufacturLayoutRoute.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              element={
                route.sidebar ? (
                  <>
                    <Sidebar />
                    <div
                      style={{
                        width: "calc(100% - 300px)",
                        height: "100vh",
                        overflow: "auto",
                        padding: "20px",
                        marginLeft: "300px",
                      }}
                    >
                      <Header />
                      {route.component}
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100vh",
                      overflow: "auto",
                    }}
                  >
                    {route.component}
                  </div>
                )
              }
            />
          );
        })}
      </Routes>
    </div>
  );
};

export default ManufacturLayout;
