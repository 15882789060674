import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ICON1 from "../../../Assets/Images/icon1.png";
import ICON2 from "../../../Assets/Images/das.png";
import ICON3 from "../../../Assets/Images/icon11.png";
import ICON4 from "../../../Assets/Images/icon12.png";
import ICON5 from "../../../Assets/Images/icon14.png";
import ICON6 from "../../../Assets/Images/icon13.png";
import ICON7 from "../../../Assets/Images/icon16.png";
import ICON8 from "../../../Assets/Images/icon15.png";
import ICON9 from "../../../Assets/Images/icon17.png";
import ICON10 from "../../../Assets/Images/icon18.png";
import ICON11 from "../../../Assets/Images/icon20.png";
import ICON12 from "../../../Assets/Images/icon21.png";
import ICON13 from "../../../Assets/Images/icon22.png";
import ICON14 from "../../../Assets/Images/icon23.png";
import logoutIcon from '../../../Assets/Images/contactIcon.png';
import BrandLogo from "../../../Assets/Images/logo.png";
import CustomModel from "../../../Components/CustomModel/CustomModel";
import "./Sidebar.scss";
import Swal from "sweetalert2";

const Sidebar = () => {
  const [openDetailsModel, setOpenDetailsModel] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const navLinkRoutes = [
    {
      path: "/admin/dashboard",
      icon: ICON2,
      activeIcon: ICON1,
      title: "Dashboard",
    },
    {
      path: "/admin/website-manager",
      icon: ICON3,
      activeIcon: ICON4,
      title: "Website Manager",
    },
    {
      path: "/admin/manufacture",
      icon: ICON6,
      activeIcon: ICON5,
      title: "Manufacture",
    },
    {
      path: "/admin/product",
      icon: ICON8,
      activeIcon: ICON7,
      title: "Products",
    },
    {
      path: "/admin/pending-order",
      icon: ICON13,
      activeIcon: ICON14,
      title: "Order",
    },
    {
      path: "/admin/notification",
      icon: ICON9,
      activeIcon: ICON10,
      title: "Notification",
    },
    {
      path: "/admin/contact-us",
      icon: ICON11,
      activeIcon: ICON12,
      title: "Contact Us",
    },
  ];
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        Swal.fire({
          position: "center",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          title: "Logged Out",
          text: "You have been successfully logged out.",
        });
        navigate("/login");
      }
    });
  };
  return (
    <Fragment>
      <div className="sidebar">
        <div className="sidebarContainer">
          <div className="brandLogo">
            <img src={BrandLogo} alt="logo" />
          </div>
          <div className="navigationLink">
            <ul className="nav_Item">
              {navLinkRoutes.map((elem, index) => (
                <li key={index}>
                  <NavLink 
                    to={elem.path} 
                    className="navLink"
                    onMouseEnter={() => setHoveredItem(index)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <div
                      className={
                        elem.path === location.pathname || index === hoveredItem
                          ? "active"
                          : "nav_Link"
                      }
                    >
                      <img
                        src={
                          elem.path === location.pathname || index === hoveredItem
                            ? elem.activeIcon
                            : elem.icon
                        }
                        alt="icon"
                      />
                      <span className="text">{elem.title}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="logOutButton" onClick={() => handleLogout()}>
              <button type="button"> <img src={logoutIcon} alt="logout"/> Log Out</button>
            </div>
          </div>
        </div>
      </div>
      <CustomModel
        show={openDetailsModel}
        onCloseClick={() => setOpenDetailsModel(false)}
      >
        <div className="deleteModel">
          <h6>Are you sure you want to Logout?</h6>
          <div className="action">
            <button
              type="button"
              className="cBtn yes"
              onClick={() => handleLogout()}
            >
              Yes
            </button>
            <button
              type="button"
              className="cBtn no"
              onClick={() => setOpenDetailsModel(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModel>
    </Fragment>
  );
};

export default Sidebar;
