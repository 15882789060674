const Constant = {
    REGEX: {
        /* eslint-disable no-useless-escape */
        EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/,
        SPECIALCHARACTERS: /[!@#\$%\^\&*\)\(+=._-]/g,
        LETTERANDSPECIALCHAR: /^[ A-Za-z_@./#&+-]*$/,
        NUMBER: /[0-9]/,
        NAME: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
        ALPHANUMERIC: /^[ a-zA-Z0-9\.\-\,]+$/i,
        LOWERCASEUPPERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/, // eslint-disable-next-line
        NUMERIC: /^\d*\.?\d*$/,
        NUMONLY: /^\d*$/,
        PERCENTAGE: /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/,
        POSITIVENUMBERS: /^[1-9][0-9]*$/,
        MAX_FILE_SIZE_MB:5,
        MAX_FILE_SIZE_BYTES: 5 * 1024 * 1024
    },
};
export default Constant;
