import React, { Fragment, useMemo, useState, useEffect, useRef } from "react";
import { Box, Button, IconButton } from "@mui/material";
import VIEWICON from "../../../../Assets/Images/edit.png";
import DELETEICON from "../../../../Assets/Images/reject.png";
import USERADD from "../../../../Assets/Images/accept.png";
import {
  GET_ALL_USER_API,
  UPDATE_USER_API,
} from "../../../../Config/Api/users/UsersApi";
import TableContainer from "../../../../Components/TableContainer/TableContainer";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import CustomSearch from "../../../../Components/CustomSearch/CustomSearch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModel from "../../../../Components/CustomModel/CustomModel";
import { Input, Toast } from "reactstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiSolidCheckCircle } from "react-icons/bi";
import { IoEyeOutline } from "react-icons/io5";
const ManufactureRequest = ({ activeTab }) => {
  const navigate = useNavigate();
  const loadingActionRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchProduct, setSearchProduct] = useState("");
  const [menufactureRequestList, setMenufactureRequestList] = useState([]);
  const debouncedSearch = useDebounce(searchProduct, 400);
  const [openDetailsModel, setOpenDetailsModel] = useState(false);
  const [rejectReason, setrejectReason] = useState("");
  const [selecteduserid, setselecteduserid] = useState(null);
  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (d) => `${d.id}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">{Number(row.row.id) + 1}</div>
          );
        },
      },
      {
        Header: "Profile Photo",
        accessor: (d) => `${d.profile_img}`,
        Cell: (row) => {
          return (
            <Box>
              <img
                src={row.row.original.profile_img}
                alt="profilephoto"
                width={"45px"}
                height={"45px"}
                style={{ objectFit: "cover" }}
              />
            </Box>
          );
        },
      },
      {
        Header: "Username",
        accessor: (d) => `${d.f_name} ${d.l_name}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row.row.original.f_name} {row.row.original.l_name}
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: (d) => `${d.email}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">{row.row.original.email}</div>
          );
        },
      },
      {
        Header: "Address",
        accessor: (d) => `${d.address}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">{row.row.original.address}</div>
          );
        },
      },
      {
        Header: "Mobile Number",
        accessor: (d) => `${d.phno}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">{row.row.original.phno}</div>
          );
        },
      },
      {
        Header: "Action",
        Cell: (row) => {
          return (
            <Box  sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:"8px"}}>
              <IconButton
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                aria-label="view"
                onClick={() =>
                  navigate("/admin/manufaturer-details", {
                    state: {
                      userId: row?.row?.original?.id,
                    },
                  })
                }
              >
                 <IoEyeOutline fontSize={"18px"} color="#46ADF2" />
              </IconButton>
              <IconButton
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                aria-label="add"
                onClick={() =>
                  updateMenufactureStatus(row?.row?.original?.id, 1)
                }
              >
                   <BiSolidCheckCircle fontSize={"18px"} color="green" />
              </IconButton>
              <IconButton
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                aria-label="delete"
                // }
                onClick={() => OpenReasonModal(row?.row?.original?.id)}
              >
                 <RiDeleteBin6Line fontSize={"18px"} color="red" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, []);
  useEffect(() => {
    if (activeTab === "manufacturerRequests") {
      getAllMenufactureList();
    }
  }, [debouncedSearch, activeTab]);
  const OpenReasonModal = (id) => {
    setselecteduserid(id);
    setOpenDetailsModel(true);
  };

  let rejectThisrrquest = async () => {
    if (!rejectReason.trim()) {
      toast("Please Add Reason!");
      return;
  } else {
      try {
        const response = await UPDATE_USER_API({
          user_id: selecteduserid,
          is_approve: -1,
          reason: rejectReason,
        });
        if (response?.s === 1 && response) {
          setOpenDetailsModel(false);
          toast.success(response?.m);
          getAllMenufactureList();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const getAllMenufactureList = async () => {
    try {
      const response = await GET_ALL_USER_API({
        params: {
          role: 1,
          count: 0,
          search: debouncedSearch,
          is_approve: 0,
        },
      });
      if (response && response?.s === 1) {
        setMenufactureRequestList(response?.r);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const updateMenufactureStatus = async (id, action) => {
    if (loadingActionRef.current) return;
    loadingActionRef.current = true;
    try {
      const response = await UPDATE_USER_API({
        user_id: id,
        is_approve: action,
      });
      if (response?.s === 1 && response) {
        if (action === 1) {
          toast.success(response?.m);
        } else if (action === -1) {
          toast.success(response?.m);
        }
      } else {
        toast.error(response?.m);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      loadingActionRef.current = false;
      getAllMenufactureList();
    }
  };

  return (
    <Fragment>
      <CustomSearch
        value={searchProduct}
        onClear={() => {
          setSearchProduct("");
        }}
        onChange={(e) => setSearchProduct(e.target.value)}
        placeholder="Search Manufacture"
      />
      <Box sx={{ marginTop: "28px" }}>
        <TableContainer
          columns={columns}
          data={menufactureRequestList}
          isGlobalFilter={true}
          customPageSize={30}
          isAddButton={false}
          pagination={false}
          noData={menufactureRequestList.length > 0 ? false : true}
          noDataTitle={"No Found Manufacturer"}
          isLoading={isLoading}
        />
      </Box>

      <CustomModel
        show={openDetailsModel}
        onCloseClick={() => setOpenDetailsModel(false)}
      >
        <div className="deleteModel">
          <h6 style={{ textAlign: "start" }}>
            Reason for rejecting the request !
          </h6>
          <Input
            placeholder="Enter Reason :"
            type="textarea"
            rows="5"
            onChange={(e) => setrejectReason(e.target.value)}
          />
          <div style={{marginTop:"10px"}} className="action">
            <Button
            sx={{textTransform:"capitalize" }}
            variant="contained" color="error"
              className="cBtn yes"
              onClick={() => rejectThisrrquest()}
            >
              Reject It
            </Button>
            <Button
             sx={{textTransform:"capitalize"}}
             variant="contained"
              className="cBtn no"
              onClick={() => setOpenDetailsModel(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </CustomModel>
    </Fragment>
  );
};

export default ManufactureRequest;
