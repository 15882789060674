import React from "react";
import "./ManufacturFavouriteProductDetails.scss";
import PRODUCTIMAGE from "../../../Assets/Images/image1.jpg";
import { Col, Row } from "reactstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const ManufacturFavouriteProductDetails = () => {
    const navigate = useNavigate();
  return (
    <div className="manufacturFavouriteProduct">
      <div className="manufacturContainer">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#46ADF2",
            padding: "10px 15px",
            width: "100%",
            position: "fixed",
            top: "0px",
            left: "0px",
            right: "0px",
            zIndex: "10",
          }}
        >
          <button
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              backgroundColor: "#ffffff",
              outline: "none",
              border: "none",
            }}
            onClick={() => navigate(-1)}
          >
            <FaArrowLeftLong color="#46ADF2" fontSize={"22px"} />
          </button>
          <h6 style={{ color: "#ffffff", fontSize: "18px", fontWeight: "500" }}>
            Favourite Product Details
          </h6>
          <div></div>
        </div>
        <div className="purchaseHistoryMain">
          <div
            style={{
              width: "100%",
              borderRadius: "10px",
              background: "#fff",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "20px",
              marginBottom: "20px",
              cursor:'pointer'
            }}
            onClick={() => navigate('/manufactur/favourite-product/favourite-product-details')}
          >
            <Row>
              <Col xs={12} sm={12} md={3} lg={2}>
                <div className="product-image">
                  <img
                    src={PRODUCTIMAGE}
                    alt="product-image"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={7}>
                <h6
                  style={{
                    color: "#000",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Plain Shipping Box
                </h6>
                <p
                  style={{
                    color: "#000",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    marign: "5px 0px 5px 0px",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus mollis sit aliquam sit nullam neque ultrices.
                </p>
                <h6>$23</h6>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div
                  className="product-date"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    alignItems: "flex-end",
                  }}
                >
                  <p
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Purchase Date : 12-12-23
                  </p>
                  <button
                    type="button"
                    style={{
                      background: "#46ADF2",
                      padding: "8px 10px",
                      fontSize: "14px",
                      fontWeight: "400",
                      borderRadius: "6px",
                      outline: "none",
                      border: "none",
                      color: "#ffffff",
                    }}
                  >
                    Download Blueprint
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{
              width: "100%",
              borderRadius: "10px",
              background: "#fff",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <Row>
              <Col xs={12} sm={12} md={3} lg={2}>
                <div className="product-image">
                  <img
                    src={PRODUCTIMAGE}
                    alt="product-image"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={7}>
                <h6
                  style={{
                    color: "#000",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Plain Shipping Box
                </h6>
                <p
                  style={{
                    color: "#000",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    marign: "5px 0px 5px 0px",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus mollis sit aliquam sit nullam neque ultrices.
                </p>
                <h6>$23</h6>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div
                  className="product-date"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    alignItems: "flex-end",
                  }}
                >
                  <p
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Purchase Date : 12-12-23
                  </p>
                  <button
                    type="button"
                    style={{
                      background: "#46ADF2",
                      padding: "8px 10px",
                      fontSize: "14px",
                      fontWeight: "400",
                      borderRadius: "6px",
                      outline: "none",
                      border: "none",
                      color: "#ffffff",
                    }}
                  >
                    Download Blueprint
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{
              width: "100%",
              borderRadius: "10px",
              background: "#fff",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <Row>
              <Col xs={12} sm={12} md={3} lg={2}>
                <div className="product-image">
                  <img
                    src={PRODUCTIMAGE}
                    alt="product-image"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={7}>
                <h6
                  style={{
                    color: "#000",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Plain Shipping Box
                </h6>
                <p
                  style={{
                    color: "#000",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    marign: "5px 0px 5px 0px",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus mollis sit aliquam sit nullam neque ultrices.
                </p>
                <h6>$23</h6>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div
                  className="product-date"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    alignItems: "flex-end",
                  }}
                >
                  <p
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Purchase Date : 12-12-23
                  </p>
                  <button
                    type="button"
                    style={{
                      background: "#46ADF2",
                      padding: "8px 10px",
                      fontSize: "14px",
                      fontWeight: "400",
                      borderRadius: "6px",
                      outline: "none",
                      border: "none",
                      color: "#ffffff",
                    }}
                  >
                    Download Blueprint
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManufacturFavouriteProductDetails;
