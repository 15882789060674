import React, { Fragment } from "react";
import { FormFeedback, Input } from "reactstrap";
import'./CustomTextField.scss';
const CustomTextField = ({
  type,
  placeholder,
  name,
  onChange,
  onBlur,
  value,
  onKeyUp,
  maxLength,
  invalid,
  errorMessage,
  rows,
  style
}) => {
  return (
    <Fragment>
      <Input
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        onKeyUp={onKeyUp}
        maxLength={maxLength}
        invalid={invalid}
        rows={rows}
        className="customTextField"
        style={style}
      />
      {invalid && <FormFeedback type="invalid">{errorMessage}</FormFeedback>}
    </Fragment>
  );
};

export default CustomTextField;
