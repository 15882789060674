import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import { IoEyeOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  GET_ALL_USER_API,
  UPDATE_USER_STATUS,
} from "../../../Config/Api/users/UsersApi";
import { RiUserForbidLine } from "react-icons/ri";
import "./AdminDashboard.scss";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import blokedusericon from "../../../utils/BlockedUser.svg";
import dummyuser from "../../../Assets/Images/dummy-profile.jpg";
import { GET_ANALYTICS_DATA } from "../../../Config/Api/Admin/DashboardManagert";
import DashboardChart from "./DashboardChart";
const AdminDashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  let [loading, setloading] = useState(false);
  const [AnyaliticsData, setAnyalyticsData] = useState({});
  const [recentUsersList, setRecentUsersList] = useState([]);
  const [cardDashboard, setCardDashboard] = useState([
    {
      title: "Total Manufacturers",
    },
    {
      title: "Total Royalty Users",
    },
    {
      title: "Total Designs Purchased",
    },
    {
      title: "Total Revenue",
    },
  ]);

  let UpdateUser = (values) => {
    Swal.fire({
      title: ` Are you sure  ?`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${
        values?.type === 0
          ? "Delete!"
          : values?.type === -1
          ? "Block it!"
          : "Unblock it !"
      } `,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await UPDATE_USER_STATUS({
            status: values?.type,
            user_id: values?.Userid,
          });

          if (res?.s == 1) {
            getUser();
            Swal.fire({
              position: "center",
              icon: "success",
              title:
                values?.type === 0
                  ? "Deleted"
                  : values?.type === -1
                  ? "Blocked "
                  : "Unblocked",
              text: ` Account has been ${
                values?.type === 0
                  ? "Deleted"
                  : values?.type === -1
                  ? "Blocked "
                  : "Unblocked"
              }`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (d) => `${d.id}`,
        Cell: (row) => {
          return (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {Number(row.row.id) + 1}
            </Typography>
          );
        },
      },
      {
        Header: "Profile Photo",
        accessor: (d) => `${d.profile_img}`,
        Cell: (row) => {
          return (
            <Box className="text textOverflow">
              <img
                src={
                  row?.row?.original?.profile_img === null
                    ? dummyuser
                    : row?.row?.original?.profile_img
                }
                alt="profile_img"
                width={"45px"}
                height={"45px"}
                style={{ objectFit: "cover" }}
              />
            </Box>
          );
        },
      },
      {
        Header: "Username",
        accessor: (d) => `${d.f_name} ${d.l_name}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              {`${row.row.original.f_name} ${row.row.original.l_name}`}
            </Typography>
          );
        },
      },
      {
        Header: "Email",
        accessor: (d) => `${d.email}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              {row.row.original.email}
            </Typography>
          );
        },
      },
      {
        Header: "Address",
        accessor: (d) => `${d.address}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              {row.row.original.address}
            </Typography>
          );
        },
      },
      {
        Header: "Mobile Number",
        accessor: (d) => `${d?.phno_cc} ${d.phno}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              {`${row.row.original.phno_cc}${row.row.original.phno}`}
            </Typography>
          );
        },
      },
      {
        Header: "Action",
        Cell: (row) => {
          return (
            <div
              className="action"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <IconButton
                aria-label="view"
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                onClick={() =>
                  navigate("/admin/manufaturer-details", {
                    state: {
                      userId: row?.row?.original?.id,
                    },
                  })
                }
              >
                <IoEyeOutline fontSize={"18px"} color="#46ADF2" />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() =>
                  UpdateUser({ type: 0, Userid: row?.row?.original?.id })
                }
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
              >
                <RiDeleteBin6Line fontSize={"18px"} color="red" />
              </IconButton>
              <IconButton
                aria-label="edit"
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                onClick={() =>
                  UpdateUser({
                    type: row?.row?.original?.status === 1 ? -1 : 1,
                    Userid: row?.row?.original?.id,
                  })
                }
              >
                {row?.row?.original?.status == 1 ? (
                  <RiUserForbidLine fontSize={"18px"} color="#FF9356" />
                ) : (
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={blokedusericon}
                  />
                )}

                {/* <RiUserForbidLine fontSize={"18px"} color="#FF9356" /> */}
              </IconButton>
            </div>
          );
        },
      },
    ];
  }, []);

  const getUser = async () => {
    setIsLoading(true);
    await GET_ALL_USER_API({ count: 5 })
      .then((res) => {
        setIsLoading(false);
        if (res.s === 1) {
          setRecentUsersList(res?.r.slice(0, 5));
        }
      })
      .catch((res) => {
        setIsLoading(false);
      }, 1000);
  };

  const [timeRange, setTimeRange] = useState(1); // Default to month
  const [chartData, setChartData] = useState();
  const handleChangeTimeRange = (event) => {
    setTimeRange(event.target.value);
  };

  const getanaylytics = async (timeRange) => {
    setloading(true);
    try {
      let response = await GET_ANALYTICS_DATA(timeRange);
      if (response?.s == 1) {
        setloading(false);
        setCardDashboard([
          {
            title: "Total Manufacturers",
            count: response?.r.total_manufacturer,
          },
          {
            title: "Total Royalty Users",
            count: response?.r.total_royalty_user,
          },
          {
            title: "Total Designs Purchased",
            count: response?.r.total_design_purchase,
          },
          {
            title: "Total Revenue",
            count: response?.r.total_revenue,
            isclikeble: true,
          },
        ]);
        setChartData(response?.r?.chart);
      }
    } catch {
      setloading(false);
    }
  };
  useEffect(() => {
    getUser();
    getanaylytics(timeRange);
  }, [timeRange]);

  let RedirecttoRevenue = () => {
    navigate("/admin/dashboard/revenue-details");
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          {cardDashboard.map((elem, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                xxl={3}
                key={index}
              >
                <Box
                  onClick={elem?.isclikeble ? RedirecttoRevenue : undefined}
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid rgba(70, 173, 242, 1)",
                    padding: "20px",
                    cursor: elem?.isclikeble ? "pointer" : "",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "",
                    }}
                  >
                    {elem?.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "20px",
                      fontWeight: "600",
                      // padding: "10px 0px 0px 0px",
                      height: "20px",
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      elem?.count
                    )}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {/* --- Graph ----------- */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Select
            value={timeRange}
            onChange={handleChangeTimeRange}
            sx={{
              padding: "0px 40px 0px 0px",
              border: "1px solid rgba(70, 173, 242, 1)",
              outline: "none",
            }}
          >
            <MenuItem value={1}>Week </MenuItem>
            <MenuItem value={2}> Month </MenuItem>
            <MenuItem value={3}> Year </MenuItem>
          </Select>
        </Box>
        <Box>
          <DashboardChart dashchartData={chartData} timeRange={timeRange} />
        </Box>

        <Box
          className="recentUser"
          sx={{
            marginTop: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#000",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Poppins",
                marginBottom: "10px",
              }}
            >
              Recent Users
            </Typography>
            <Button
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000",
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                textTransform: "capitalize",
              }}
              onClick={() => navigate("/admin/manufacture")}
            >
              View All
            </Button>
          </Box>
          <TableContainer
            columns={columns}
            data={recentUsersList}
            isGlobalFilter={true}
            customPageSize={5}
            isAddButton={false}
            pagination={false}
            noData={recentUsersList.length > 0 ? false : true}
            noDataTitle={"No Recent Users"}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default AdminDashboard;
