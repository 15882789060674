import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import AxiosInstance from "../../../Config/AxiosInstance";
import useDebounce from "../../../Components/useDebounce/useDebounce";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomModel from "../../../Components/Model/Model";
import { Form, FormFeedback, Input, Label } from "reactstrap";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { REPLAY_CONTACT_US_API } from "../../../Config/Api/AuthApi";
const ContactUs = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchContactUs, setSearchContactUs] = useState("");
  const [contactUsList, setContactUsList] = useState([]);
  const debouncedSearch = useDebounce(searchContactUs, 400);
  const [showContactUsModel, setShowContactusModel] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (d) => `${d.id}`,
        Cell: (row) => {
          return <p className="text textOverflow">{Number(row.row.id) + 1}</p>;
        },
      },
      {
        Header: "Name",
        accessor: (d) => `${d.name}`,
        Cell: (row) => {
          return <p className="text textOverflow">{row.row.original.name}</p>;
        },
      },
      {
        Header: "Email",
        accessor: (d) => `${d.email}`,
        Cell: (row) => {
          return <p className="text textOverflow" >{row.row.original.email}</p>;
        },
      },
      {
        Header: "Message",
        accessor: (d) => `${d.message}`,
        Cell: (row) => {
          return (
            <Box sx={{ width: "250px", margin: "0 auto" }}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      padding: "8px",
                      fontSize: "0.8rem",
                      "& .MuiTooltip-arrow": {
                        color: "#000",
                      },
                    },
                  },
                }}
                placement="top"
                arrow
                title={
                  <Box>
                    <Typography>
                      {row.row.original.message}
                    </Typography>
                  </Box>
                }
              >
                <Typography
                  noWrap
                  sx={{
                    m: "auto",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                    textAlign: "center",
                    
                  }}
                >
                  {row.row.original.message}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <div className="action">
              <IconButton
                aria-label="check"
                sx={{
                  outline: "none",
                  border: "none",
                  backgroundColor:
                    row?.original?.is_reply === 1 ? "#E7E9EB" : "#ffffff",
                  boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  padding: "0px",
                  width: "40px",
                  height: "40px",
                  border: "1px solid transparent",
                  transition: "0.5s",
                  "&:hover": {
                    border: "1px solid #46ADF2",
                  },
                }}
                disabled={row?.original?.is_reply === 1}
                onClick={() => {
                  if (row?.original?.is_reply !== 1) {
                    setShowContactusModel(true);
                    setSelectedRowId(row?.original?.id);
                  }
                }}
              >
                <ReplyIcon
                  sx={{
                    color: row?.original?.is_reply === 1 ? "#000" : "#46ADF2",
                  }}
                />
              </IconButton>
            </div>
          );
        },
      },
    ];
  }, []);
  useEffect(() => {
    getContactUs();
  }, [debouncedSearch]);
  const getContactUs = async () => {
    try {
      const contactUsResponse = await AxiosInstance.get(
        "/contact/get-contact-us-user",
        {
          params: {
            count: 0,
            search: debouncedSearch,
          },
        }
      );
      if (
        contactUsResponse &&
        contactUsResponse.data &&
        contactUsResponse.data.s === 1
      ) {
        setContactUsList(contactUsResponse.data.r);
      } else {
        toast.error(contactUsResponse?.data?.m || "Unknown error occurred");
      }
    } catch (error) {
      console.log("error", error);
      if (error.response) {
        const { status } = error.response;
        if (status === 401) {
          toast.error(status);
        } else if (status === 404) {
          toast.error("404 - Not Found");
        } else {
          toast.error("An error occurred while fetching contact data.");
        }
      } else {
        toast.error("Network error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const ContactUsValidation = useFormik({
    initialValues: {
      title: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      message: Yup.string()
        .max(150, "Message must be at most 150 characters")
        .required("Message is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        const formData = new FormData();
        formData.append("req_id", selectedRowId);
        formData.append("title", values.title);
        formData.append("message", values.message);
        try {
          const response = await REPLAY_CONTACT_US_API(formData);
          if (response?.s === 1) {
            toast.success(response?.m);
          } else {
            toast.error(response?.m);
          }
          resetForm();
          setSelectedRowId(null);
          setShowContactusModel(false);
          getContactUs();
        } catch (error) {
          console.log("error", error);
        }
      }
    },
  });
  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            padding: "15px 15px 15px 15px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "32px",
              color: "black",
            }}
            variant="h5"
          >
            Contact Us
          </Typography>
          <Box
            sx={{
              width: "100%",
              margin: "24px 0px 0px 0px",
            }}
          >
            <CustomSearch
              value={searchContactUs}
              onClear={() => {
                setSearchContactUs("");
              }}
              onChange={(e) => setSearchContactUs(e.target.value)}
              placeholder="Search Contact"
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              margin: "24px 0px 0px 0px",
            }}
          >
            <TableContainer
              columns={columns}
              data={contactUsList}
              isGlobalFilter={true}
              customPageSize={30}
              isAddButton={false}
              pagination={false}
              noData={contactUsList.length > 0 ? false : true}
              noDataTitle={"No Contact Found"}
              isLoading={isLoading}
              maxHeight={{ height: "calc(100vh - 250px)", overflowY: "auto" }}
            />
          </Box>
        </Box>
      </Box>
      <CustomModel
        open={showContactUsModel}
        onClose={() => {
          setShowContactusModel(false);
          ContactUsValidation.resetForm();
          setSelectedRowId(null);
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              ContactUsValidation.handleSubmit();
              return false;
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Label htmlFor="name">Title</Label>
                <Input
                  type="text"
                  placeholder="Enter Title"
                  name="title"
                  className="form-control"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    ContactUsValidation.handleChange(e);
                  }}
                  style={{
                    padding: "16px 12px 16px 12px",
                  }}
                  onBlur={ContactUsValidation.handleBlur}
                  value={ContactUsValidation.values.title}
                  onKeyUp={ContactUsValidation.handleBlur}
                  invalid={
                    ContactUsValidation.touched.title &&
                    ContactUsValidation.errors.title
                      ? true
                      : false
                  }
                  autoComplete="off"
                />
                {ContactUsValidation.touched.title &&
                ContactUsValidation.errors.title ? (
                  <FormFeedback type="invalid">
                    {ContactUsValidation.errors.title}
                  </FormFeedback>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Label htmlFor="message">Message</Label>
                <Input
                  type="textarea"
                  placeholder="Enter Message"
                  name="message"
                  className="form-control"
                  maxLength={150}
                  rows={5}
                  style={{
                    resize: "none",
                    padding: "16px 12px 16px 12px",
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    ContactUsValidation.handleChange(e);
                  }}
                  onBlur={ContactUsValidation.handleBlur}
                  value={ContactUsValidation.values.message}
                  onKeyUp={ContactUsValidation.handleBlur}
                  invalid={
                    ContactUsValidation.touched.message &&
                    ContactUsValidation.errors.message
                      ? true
                      : false
                  }
                  autoComplete="off"
                />
                {ContactUsValidation.touched.message &&
                ContactUsValidation.errors.message ? (
                  <FormFeedback type="invalid">
                    {ContactUsValidation.errors.message}
                  </FormFeedback>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  type="submit"
                  className="submitBtn"
                  btn_title="Reply"
                  loading={isLoading}
                  disabled={
                    !(ContactUsValidation.dirty && ContactUsValidation.isValid)
                  }
                  style={{
                    width: "100%",
                    height: "55px",
                    fontSize: "18px",
                    fontWeight: "500px",
                    backgroundColor: "#46adf2",
                    outline: "none",
                    border: "none",
                    cursor: "pointer",
                    ...(ContactUsValidation.dirty &&
                    !ContactUsValidation.isValid
                      ? {
                          backgroundColor: "#6c757d",
                          color: "#ffffff",
                          cursor: "not-allowed",
                        }
                      : {}),
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </CustomModel>
    </Fragment>
  );
};

export default ContactUs;
