import { useState, useEffect, Fragment } from "react";
import { Line } from "react-chartjs-2";
import { Box } from "@mui/material";
import "chart.js";
import "chart.js/auto";

const DashboardChart = ({ dashchartData,timeRange }) => {
     
  const [chartData, setChartData] = useState(getChartData(dashchartData));

  useEffect(() => {
    setChartData(getChartData(dashchartData));
  }, [dashchartData]);

  function getChartData(dashchartData) {
    const labels = [];
    const values = [];

    // Iterate over each object in dashchartData to extract labels and values
    dashchartData?.forEach((dayData) => {
      const day = Object.keys(dayData)[0]; // Extract day name
      const value = Object.values(dayData)[0]; // Extract value
      labels.push(day);
      values.push(value);
    });

    const data = {
      labels: labels,
      datasets: [
        {
            label: timeRange === 1
            ? "Weekly Data"
            : timeRange === 2
            ? "Monthly Data"
            : "Last 5 Years Data",
          
          data: values,
          borderWidth: 1,
          fill: true,
          backgroundColor: "#46ADF2", // Background color for the line
        },
      ],
    };

    return data;
  }

  return (
    <Fragment>
      <Box className="mt-10">
        <Line
          data={chartData}
          height={300}
          width={600}
          options={{
            tension: 0.5,
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  beginAtZero: true,
                },
              },
            },
            legend: { display: false },
          }}
        />
      </Box>
    </Fragment>
  );
};

export default DashboardChart;
