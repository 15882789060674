import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form, FormFeedback, Input, NavLink } from "reactstrap";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { LOGIN_API } from "../../../Config/Api/AuthApi";
import Constant from "../../../utils/constant";
import { v4 as uuidv4 } from "uuid";
import "./Login.scss";
import Swal from "sweetalert2";
const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginValidation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter your email address")
        .matches(Constant.REGEX.EMAIL, "Please provide a valid email address"),
      password: Yup.string()
        .required("Password Is Required")
        .matches(
          /^().{6,20}$/,
          "Password should contain at least one capital letter and be between 6 and 20 characters"
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const response = await LOGIN_API({
            email: values.email,
            password: values.password,
          });
          if (response) {
            if (response.s) {
              Swal.fire({
                position: "center",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                title: "success",
                text: response?.m,
              });
              window.localStorage.setItem(
                "RoaveBox",
                JSON.stringify(response?.r)
              );
              if (response?.r?.role === Number(1)) {
                navigate("/manufactur/dashboard");
              }
              if (response?.r?.role === 2) {
                navigate("/admin/dashboard");
              }
              const deviceId = uuidv4();
              const currentTime = new Date();
              const hours = currentTime.getHours();
              const minutes = currentTime.getMinutes();
              const seconds = currentTime.getSeconds();
              const milliseconds = currentTime.getMilliseconds();
              const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds}`;
              const deviceIdWithTime = deviceId + formattedTime;
              localStorage.setItem("deviceId", deviceIdWithTime)
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                title: "error",
                text: response?.m,
              });
            }
          }
          resetForm();
        } catch (error) {
          console.log("error", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <div className="loginWrapper">
      <div className="loginCard">
        <div className="loginHeader">
          <h5 className="title">Welcome Back</h5>
          <p>Please Enter Your Email & Password to Login</p>
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            LoginValidation.handleSubmit();
            return false;
          }}
        >
          <div className="inputGroup">
            <Input
              type="text"
              placeholder="Email Address"
              name="email"
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                LoginValidation.handleChange(e);
              }}
              onBlur={LoginValidation.handleBlur}
              value={LoginValidation.values.email}
              onKeyUp={LoginValidation.handleBlur}
              invalid={
                LoginValidation.touched.email && LoginValidation.errors.email
                  ? true
                  : false
              }
              autoComplete="off"
            />
            {LoginValidation.touched.email && LoginValidation.errors.email ? (
              <FormFeedback type="invalid">
                {LoginValidation.errors.email}
              </FormFeedback>
            ) : null}
          </div>
          <div className="inputGroup">
            <div className="inputGroupPasword">
              <Input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                name="password"
                value={LoginValidation.values.password}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  LoginValidation.handleChange(e);
                }}
                onBlur={LoginValidation.handleBlur}
                onKeyUp={LoginValidation.handleBlur}
                invalid={
                  LoginValidation.touched.password &&
                  LoginValidation.errors.password
                    ? true
                    : false
                }
                autoComplete="off"
              />
              <Button
                aria-label="show"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </Button>
              {LoginValidation.touched.password &&
              LoginValidation.errors.password ? (
                <FormFeedback type="invalid">
                  {LoginValidation.errors.password}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <NavLink
            to={"/forgot-password"}
            onClick={() => navigate("/forgot-password")}
            className="goForgot"
          >
            Forgot Password ?
          </NavLink>
          <div className="submitButton">
            <CustomButton
              type="submit"
              className="submitBtn"
              btn_title="Login"
              loading={isLoading}
              disabled={!(LoginValidation.dirty && LoginValidation.isValid)}
            />
            <NavLink
              to={"/signup"}
              className="allReady"
              onClick={() => navigate("/signup")}
            >
              Don’t have an account? Sign Up
            </NavLink>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
