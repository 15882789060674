import {Box, IconButton} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import VIEWICON from "../../../Assets/Images/edit.png";
import DELETEICON from "../../../Assets/Images/delete.png";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { toast } from "react-toastify";
import {DELETE_PRODUCT_API,GET_ALL_PRODUCT_API, ORDER_PRODUCT} from "../../../Config/Api/Admin/Product/ProductApi";
import Skeleton from "react-loading-skeleton";
import { IoEyeOutline } from "react-icons/io5";
const PendingOrder = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [OrderList, setOrderList] = useState([]);
  // const [filteredProductList, setFilteredProductList] = useState([]);
  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (d) => `${d.id}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {Number(row.row.id) + 1}
            </div>
          );
        },
      },
      {
        Header: "Product",
        accessor: (d) => `${d?.product_details?.cover_img}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              <img
                srcSet={row.row.original?.product_details.cover_img}
                alt="profilephoto"
                width={"45px"}
                height={"45px"}
                style={{ objectFit: "cover" }}
              />
            </div>
          );
        },
      },
      {
        Header: "Product Name",
        accessor: (d) => `${d.name}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row.row.original?.product_details?.name}
            </div>
          );
        },
      },
      {
        Header: "Transaction Id ",
        accessor: (d) => `${d.name}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row.row.original.transaction_id}
            </div>
          );
        },
      },
      {
        Header: "Payer Id ",
        accessor: (d) => `${d.name}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row.row.original.payer_id}
            </div>
          );
        },
      },
      {
        Header: "Royalty Amount",
        accessor: (d) => `${d.royalty_amount}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row.row.original.royalty_amount}
            </div>
          );
        },
      },
      {
        Header: "View More",
        Cell: (row) => {
          return (
            <Box sx={{display:"flex", justifyContent:"center"}}>
              <IconButton
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                aria-label="view"
                onClick={() =>
                  navigate("/admin/pending-order/Order-details", {
                    state: {
                      oderdata: row?.row?.original,
                      pId: row?.row?.original?.id,
                    },
                  })
                }
              >
         <IoEyeOutline fontSize={"18px"} color="#46ADF2" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, []);
  useEffect(() => {
    getOrder();
  }, []);
 
  const getOrder = async () => {
    try {
      setIsLoading(true)
      const response = await ORDER_PRODUCT();
      if (response) {
        if (response?.s) {
          setOrderList(response?.r);
        } else {
          toast.error(response.m);
        }
      }
    } catch (error) {
      toast.error(error.m);
    } finally {
      setIsLoading(false);
    }
  };
  // const handleDeleteProduct = async (productId) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await DELETE_PRODUCT_API(productId);
  //     if (response) {
  //       if (response?.s) {
  //         // getAllProduct();
  //       } else {
  //         toast.error(response.m);
  //       }
  //     }
  //   } catch (error) {
  //     toast.error(error.m);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6
          style={{
            fontSize: "32px",
            fontWeight: "500",
          }}
          variant="h4"
        >
         Orders
        </h6>
        
      </div><>
          {/* <div
            style={{
              marginTop: "40px",
            }}
          >
            <CustomSearch
              value={searchProduct}
              onClear={() => {
                setSearchProduct("");
              }}
              onChange={(e) => setSearchProduct(e.target.value)}
              placeholder="Search Product"
            />
          </div> */}
          <div
            style={{
              marginTop: "40px",
            }}
          >
            <TableContainer
              columns={columns}
              data={OrderList}
              isGlobalFilter={true}
              customPageSize={30}
              isAddButton={false}
              pagination={false}
              noData={setOrderList.length > 0 ? false : true}
              noDataTitle={"No Product"}
              isLoading={isLoading}
            />
          </div>
        </>
    </Fragment>
  );
};

export default PendingOrder;