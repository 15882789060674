import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { BsArrowRight } from "react-icons/bs";
import "./ManufacturContactUs.scss";
import { toast } from "react-toastify";
import { ADD_CONTACT_US_API } from "../../../../Config/Api/AuthApi";
const ManufacturContactUs = () => {
  const [busy, setBusy] = useState(false);
  const contactUsValidation = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name field is required"),
      email: Yup.string()
        .email("Please Enter valid Email Address")
        .required("Email Address Is Required"),
      message: Yup.string().required("Message field is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setBusy(true);
      if (busy) {
        return ;
      } else {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("message", values.message);
        try {
          const response = await ADD_CONTACT_US_API(formData);
          if (response?.s === 1) {
            toast.success("Your message has been sent successfully.");
            resetForm();
          }
          resetForm();
        } catch (error) {
          toast.error(error.m);
          setBusy(false);
        } finally {
          setBusy(false);
        }
      }
    },
  });
  return (
    <Fragment>
      <div className="contactUs">
        <div className="contactUsBg">
          <div className="conactUsCard">
            <div className="titleSection">
              <h5 className="title">Contact us</h5>
              <p>We want to hear from you</p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                contactUsValidation.handleSubmit();
                return false;
              }}
            >
              <div className="formGroup">
                <div className="formGroupInner">
                  <input
                    type={"text"}
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      contactUsValidation.handleChange(e);
                    }}
                    onBlur={contactUsValidation.handleBlur}
                    value={contactUsValidation.values.name}
                    onKeyUp={contactUsValidation.handleBlur}
                    invalid={
                      contactUsValidation.touched.name &&
                      contactUsValidation.errors.name
                        ? true
                        : false
                    }
                  />
                  {contactUsValidation.touched.name &&
                  contactUsValidation.errors.name ? (
                    <div
                      type="invalid"
                      style={{
                        paddingLeft: "15px",
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      {contactUsValidation.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="formGroup">
                <div className="formGroupInner">
                  <input
                    type={"text"}
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      contactUsValidation.handleChange(e);
                    }}
                    onBlur={contactUsValidation.handleBlur}
                    value={contactUsValidation.values.email}
                    onKeyUp={contactUsValidation.handleBlur}
                    invalid={
                      contactUsValidation.touched.email &&
                      contactUsValidation.errors.email
                        ? true
                        : false
                    }
                  />
                  {contactUsValidation.touched.email &&
                  contactUsValidation.errors.email ? (
                    <div
                      type="invalid"
                      style={{
                        paddingLeft: "15px",
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      {contactUsValidation.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="formGroup">
                <div className="formGroupInner">
                  <textarea
                    type={"textarea"}
                    className="form-control"
                    placeholder="Please type your message here..."
                    name="message"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      contactUsValidation.handleChange(e);
                    }}
                    onBlur={contactUsValidation.handleBlur}
                    value={contactUsValidation.values.message}
                    onKeyUp={contactUsValidation.handleBlur}
                    invalid={
                      contactUsValidation.touched.message &&
                      contactUsValidation.errors.message
                        ? true
                        : false
                    }
                  />
                  {contactUsValidation.touched.message &&
                  contactUsValidation.errors.message ? (
                    <div
                      type="invalid"
                      style={{
                        paddingLeft: "15px",
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      {contactUsValidation.errors.message}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="formGroup">
                <button
                  className={`customBtn`}
                  type={"submit"}
                  disabled={
                    !(contactUsValidation.dirty && contactUsValidation.isValid)
                  }
                >
                  Send Message
                  <div className="icon">
                    <BsArrowRight />
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ManufacturContactUs;
