import AxiosInstance from "../../AxiosInstance";
export const ADD_HELP_SUPPORT_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/web-management/add-faq",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DELETE_HELP_SUPPORT_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/web-management/delete-faq",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UPDATE_HELP_SUPPORT_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/web-management/update-faq",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_HELP_SUPPORT_API = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL +
        `/web-management/get-faq?count=${payload}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
