import AxiosInstance from "../AxiosInstance";
export const LOGIN_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/auth/login', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const SIGNUP_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/auth/signup', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const FORGOT_PASSWORD_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/auth/forgot-password', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const COUNTRY_LIST_API = async () => {
    try {
        const response = await AxiosInstance.get(AxiosInstance.defaults.baseURL + '/auth/country-list');
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const STATE_LIST_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + `/auth/state-list?`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const CITY_LIST_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + `/auth/city-list?`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const ADD_CONTACT_US_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + `/contact/add-contact-us`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const REPLAY_CONTACT_US_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + `/contact/reply-contact-us`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};