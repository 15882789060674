import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import {
  ADD_TO_FAVOURITE,
  GET_ALL_PRODUCT_API,
  GET_TO_FAVOURITE,
} from "../../../../Config/Api/ProductApi";
import "./ManufacturProductShowCase.scss";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa6";
import { CiHeart } from "react-icons/ci";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ManufacturProductShowCase = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const user_id = JSON.parse(localStorage.getItem("RoaveBox")).id;
      setIsLoading(true);
      try {
        const [productsResponse, favoritesResponse] = await Promise.all([
          GET_ALL_PRODUCT_API(user_id),
          GET_TO_FAVOURITE(),
        ]);
        if (productsResponse && productsResponse.s === 1) {
          const products = productsResponse.r;
          setProductList(products);
          setLoadingProducts(new Array(products.length).fill(false));
        }
        if (favoritesResponse && favoritesResponse.s === 1) {
          const favIds = favoritesResponse.r.map((fav) => fav.product_id);
          setFavorites(favIds);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const toggleFavorite = async (productId, index) => {
    if (loadingProducts[index]) return;
    try {
      setLoadingProducts((prevLoading) => {
        const newLoading = [...prevLoading];
        newLoading[index] = true;
        return newLoading;
      });

      if (favorites.includes(productId)) {
        await ADD_TO_FAVOURITE({ product_id: productId });
        setFavorites((prevFavorites) =>
          prevFavorites.filter((id) => id !== productId)
        );
        toast.success("Removed from favorites.");
      } else {
        await ADD_TO_FAVOURITE({ product_id: productId });
        setFavorites((prevFavorites) => [...prevFavorites, productId]);
        toast.success("Added to favorites.");
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    } finally {
      setLoadingProducts((prevLoading) => {
        const newLoading = [...prevLoading];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  return (
    <div className="productShowcase">
      <Row>
        {isLoading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={3}
              xl={3}
              xxl={3}
              key={index}
              className="mb-3"
            >
              <Skeleton height={200} />
            </Col>
          ))
        ) : productList?.length > 0 ? (
          productList?.map((elem, index) => (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={4}
              xxl={3}
              key={index}
              className="mb-3"
            >
              <div className="productShowCaseList">
                <button
                  type="button"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    background: "#46adf2",
                    color: "#ffffff",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "4px 8px",
                  }}
                >{`$ ${elem?.royalty_amount}`}</button>
                <div className="productShowcaseImage">
                  <LazyLoadImage
                    src={elem?.cover_img}
                    alt="Uploaded Image"
                    effect="blur"
                  />
                </div>
                <p>{elem?.name}</p>
                <div className="viewProductDetetails">
                  <button
                    type="button"
                    onClick={() =>
                      navigate("/manufactur/product-showcase/product-details", {
                        state: {
                          productId: elem?.id,
                        },
                      })
                    }
                  >
                    View Product
                  </button>
                </div>
                <button
                  type="button"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                  onClick={() => toggleFavorite(elem.id, index)}
                  disabled={loadingProducts[index]}
                >
                  {favorites.includes(elem.id) ? (
                    <FaHeart fontSize="24px" color="red" />
                  ) : (
                    <CiHeart fontSize="32px" />
                  )}
                </button>
              </div>
            </Col>
          ))
        ) : (
          <div
            className="notFound"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "100px 0px",
            }}
          >
            No Product Found
          </div>
        )}
      </Row>
    </div>
  );
};

export default ManufacturProductShowCase;
