import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { GET_REVENUE_DATA } from "../../../Config/Api/Admin/DashboardManagert";
import { toast } from "react-toastify";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const RevenueDetails = () => {
  const [productRevenueList, setProductRevenueList] = useState([]);
  let [totalrevenue, setTotalrevenue] = useState(0);

  const [timeRange, setTimeRange] = useState(1); // Default to month
  const handleChangeTimeRange = (event) => {
    setTimeRange(event.target.value);
  };
  useEffect(() => {
    getRevenueData(timeRange);
  }, [timeRange]);

  let [loadData, setloadData] = useState(false);
  let getRevenueData = async (timeRange) => {
    try {
      setloadData(true);
      let response = await GET_REVENUE_DATA(timeRange);
      if (response?.s === 1) {
        setProductRevenueList(response?.r?.result);
        setTotalrevenue(response?.r?.total_revenue);
        setloadData(false);
      } else {
        toast("Something wents wrong !");
        setloadData(false);
      }
    } catch (err) {
      toast("Something wents wrong !");
    }
  };
  let navigate = useNavigate();
  let backtoDashboard = () => {
    navigate(-1);
  };
  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (d) => `${d.id}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              {Number(row.row.id) + 1}
            </Typography>
          );
        },
      },
      {
        Header: "Product",
        accessor: (d) => `${d.product}`,
        Cell: (row) => {
          return (
            <Box className="text textOverflow">
              <img
                src={row.row.original.cover_img}
                alt="product"
                width={"45px"}
                height={"45px"}
                style={{ objectFit: "cover" }}
              />
            </Box>
          );
        },
      },
      {
        Header: "Product Name",
        accessor: (d) => `${d.prod_name}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              {row.row.original.prod_name}
            </Typography>
          );
        },
      },
      {
        Header: "UserName",
        accessor: (d) => `${d.f_name}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              {row.row.original.f_name} {row.row.original.l_name}
            </Typography>
          );
        },
      },
      {
        Header: "Email",
        accessor: (d) => `${d.email}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              {row.row.original.email}
            </Typography>
          );
        },
      },
      {
        Header: "Total Amount",
        accessor: (d) => `${d.status}`,
        Cell: (row) => {
          return (
            <Typography className="text textOverflow">
              ${row.row.original.total_amount}
            </Typography>
          );
        },
      },
      // {
      //   Header: "Paid",
      //   accessor: (d) => `${d.paid}`,
      //   Cell: (row) => {
      //     return (
      //       <Typography className="text textOverflow">
      //         {row.row.original.paid}
      //       </Typography>
      //     );
      //   },
      // },
      // {
      //   Header: "Action",
      //   Cell: (row) => {
      //     return (
      //       <Box className="action">
      //         <IconButton aria-label="view">
      //           <img src={EDITICON} alt="edit" />
      //         </IconButton>
      //       </Box>
      //     );
      //   },
      // },
    ];
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#46ADF2",
          padding: "10px 15px",
        }}
      >
        <IconButton
          onClick={() => backtoDashboard()}
          sx={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            backgroundColor: "#ffffff",
            "&:hover": { background: "#fff" }, // Corrected hover syntax
          }}
        >
          <FaArrowLeftLong color="#46ADF2" fontSize={"32px"} />
        </IconButton>
        <Typography
          variant="span"
          sx={{ color: "#ffffff", fontSize: "18px", fontWeight: "500" }}
        >
          Revenue Details
        </Typography>
        {/* <CustomButton
          type="button"
          btn_title="Download"
          sx={{
            width: "170px",
            padding: "10px",
            fontSize: "16px",
            fontWeight: "400",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            outline: "none",
            border: "none",
            color: "#4D4E4E",
            textTransform: "capitalize",
          }}
        /> */}
        <Box></Box>
      </Box>
      <Box
        sx={{
          margin: "24px auto 10px auto",
          maxWidth: "90%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "linear-gradient(180deg, #91D3FF 0%, #46ADF2 100%)",
            padding: "20px 15px",
            borderRadius: "8px",
            marginBottom: "24px",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#ffffff",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Revenue
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: "#ffffff",
                fontSize: "29px",
                fontWeight: "700",
                marginTop: "4px",
              }}
            >
              ${totalrevenue}
            </Typography>
          </Box>
          <Select
            value={timeRange}
            onChange={handleChangeTimeRange}
            sx={{
              padding: "0px 10px ",
              background: "#fff",
            }}
          >
            <MenuItem value={1}>Weekly </MenuItem>
            <MenuItem value={2}> Monthly </MenuItem>
            <MenuItem value={3}> Yearly </MenuItem>
          </Select>
        </Box>
        <Box>
          <TableContainer
            columns={columns}
            data={productRevenueList}
            isGlobalFilter={true}
            customPageSize={30}
            isAddButton={false}
            pagination={false}
            noData={productRevenueList.length > 0 ? false : true}
            noDataTitle={"No Product Revenue Found"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RevenueDetails;
 