import React from "react";
import CircularProgress from "../../Assets/Images/giphy.gif";
import { Button } from "reactstrap";
const CustomButton = (props) => {
  const {
    btn_title,
    type,
    disabled,
    loading,
    children,
    className,
    onClick,
    style
  } = props;

  return (
    <Button
      className={"custombtn " + className}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      style={style}
    >
      {loading ? (
        <>
          <img src={CircularProgress} alt="loader" width={'50px'} height={'50px'}/>
        </>
      ) : (
        btn_title
      )}
      {children}
    </Button>
  );
};

export default CustomButton;
