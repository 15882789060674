import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Modal, Row } from 'reactstrap';
import { IoMdCloseCircle } from "react-icons/io";
import { FaPlayCircle } from "react-icons/fa";
import ABOUTUSBG from '../../../../Assets/Images/about-bg.jpg';
import ABOUTUSBG1 from '../../../../Assets/Images/about-bg-1.png';
import ABOUTUSBG2 from '../../../../Assets/Images/about-bg.jpg';
import DEMOVIDEO from '../../../../Assets/Images/demo.mp4'
import './ManufacturAboutUs.scss';
import { GET_ALL_PRODUCT_API } from '../../../../Config/Api/ProductApi';
const ManufacturAboutUs = () => {
    const navigate = useNavigate();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  useEffect(() => {
    const getProductList = async () => {
      setIsLoading(true);
      try {
        const response = await GET_ALL_PRODUCT_API();
        if (response && response.s === 1) {
          const product = response.r;
          setProductList(product);
        }
      } catch (error) {
        console.error("Error fetching product list:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getProductList();
  }, []);
    return (
        <Fragment>
            <div className='manufacturAboutUs'>
                <div className='row'>
                    <div className='col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                        <div className='position-reletive'>
                            <div className='aboutUsImageVideo'>
                                <img
                                    src={ABOUTUSBG}
                                    className="bgImages img-fuild"
                                    alt="about-us"
                                />
                                <button type='button' onClick={() => setModalOpen(true)}>
                                    <FaPlayCircle color="#ffffff" fontSize={"32px"} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Row className='whoWeAreRow'>
                    <Col xs={12} sm={12} md={6} lg={7} xl={7} xxl={7}>
                        <div className='whoWeAreContant'>
                            <h6>Designed for heavy-weight freights</h6>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
                                phasellus mollis sit aliquam sit nullam neque ultrices.Lorem
                                ipsum dolor sit amet consectetur adipiscing eli mattis
                            </p>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
                                phasellus mollis sit aliquam sit nullam neque ultrices.Lorem
                                ipsum dolor sit amet consectetur adipiscing eli mattis
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={5} xl={5} xxl={5}>
                        <div className='whoWeAreImages'>
                            <img
                                src={ABOUTUSBG1}
                                alt="about-bg-1"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='whoWeAreRow1'>
                    <Col xs={12} sm={12} md={6} lg={5} xl={5} xxl={5}>
                        <div className='whoWeAreImages'>
                            <img
                                src={ABOUTUSBG2}
                                alt="about-bg-1"
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={7} xl={7} xxl={7}>
                        <div className='whoWeAreContant'>
                            <h6>Designed for heavy-weight freights</h6>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
                                phasellus mollis sit aliquam sit nullam neque ultrices.Lorem
                                ipsum dolor sit amet consectetur adipiscing eli mattis
                            </p>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
                                phasellus mollis sit aliquam sit nullam neque ultrices.Lorem
                                ipsum dolor sit amet consectetur adipiscing eli mattis
                            </p>
                        </div>
                    </Col>
                </Row>
                <h6 style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#4D4E4E",
                    padding:'24px 0px'

                }}>Watch Our Gallery</h6>
                <Row>
                    {productList && productList?.map((elem, index) => {
                        return (
                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={3} className='mb-2' key={index}>
                                <div className="productShowCaseList">
                                    <div className="productShowcaseImage">
                                        <img src={elem?.cover_img} alt="product" />
                                    </div>
                                    <p>{elem?.name}</p>
                                    <div className="viewProductDetetails">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                navigate("/manufactur/product-showcase/product-details", {
                                                    state: {
                                                        productId: elem?.id,
                                                    },
                                                })
                                            }
                                        >
                                            View Product
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
            <Modal
                centered
                isOpen={modalOpen}
                onClose={() => {
                    setIsVideoPlaying(false);
                    setModalOpen(false);
                }}
                className="videoOpenModel"
            >
                <div className="bgVideo">
                    <div className="bgVideoPadding"><video
                        autoPlay={isVideoPlaying}
                        muted
                        loop
                        controls
                        className="bgVideo"
                    >
                        <source
                            src={DEMOVIDEO}
                            type="video/mp4"
                        />
                    </video>
                    </div>
                </div>
                <button
                    className="closeModel"
                    onClick={() => {
                        setIsVideoPlaying(false);
                        setModalOpen(false);
                    }}
                >
                    <IoMdCloseCircle color="#000" fontSize={"32px"} />
                </button>
            </Modal>
        </Fragment>
    )
}

export default ManufacturAboutUs
