import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Constant from "../../../../utils/constant";
import BreadCrume from "../../../../Components/BreadCrume/BreadCrume";
import UPLOAD_GALLERY_ICON from "../../../../Assets/Images/gallery.png";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import {
  ADD_MEDIA_API,
  ADD_PRODUCT_API,
  GET_BY_ID_PRODUCT_API,
  UPDATE_MEDIA_API,
  UPDATE_PRODUCT_API,
} from "../../../../Config/Api/Admin/Product/ProductApi";
import { FormFeedback, Input } from "reactstrap";
const AddProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [coverImageUploadPreview, setCoverImageUploadPreview] = useState(null);
  const [coverImageUploadFile, setCoverImageUploadFile] = useState(null);
  const [coverVideoUploadFile, setCoverVideoUploadFile] = useState(null);
  const [coverVideoUploadPreview, setCoverVideoUploadPreview] = useState(null);
  const [coverGallery, setCoverGallery] = useState([]);
  const [coverGalleryFile, setCoverGalleryFile] = useState([]);
  const [typeCheck, setTypeCheck] = useState("add");
  const [editProductId, setEditProductId] = useState(null);
  const handleCoverImageOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= Constant.REGEX.MAX_FILE_SIZE_BYTES) {
        const imageUrl = URL.createObjectURL(file);
        setCoverImageUploadFile(file);
        setCoverImageUploadPreview(imageUrl);
      } else {
        toast.error(
          `File size exceeds the limit of ${Constant.REGEX.MAX_FILE_SIZE_MB}MB`
        );
      }
    }
  };
  const handleCoverVideoOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= Constant.REGEX.MAX_FILE_SIZE_BYTES) {
        setCoverVideoUploadFile(file);
        const imageUrl = URL.createObjectURL(file);
        setCoverVideoUploadPreview(imageUrl);
      } else {
        toast.error(
          `File size exceeds the limit of ${Constant.REGEX.MAX_FILE_SIZE_MB}MB`
        );
      }
    }
  };
  const handleCoverGalleryOnChange = (e) => {
    const files = e.target.files;
    if (files) {
      let galleryImages = Array.from(files)
        .map((file) => {
          if (file.size <= Constant.REGEX.MAX_FILE_SIZE_BYTES) {
            const imageUrl = URL.createObjectURL(file);
            return { image: imageUrl, file: file };
          } else {
            toast.error(
              `File size exceeds the limit of ${Constant.REGEX.MAX_FILE_SIZE_MB}MB`
            );
            return null;
          }
        })
        .filter((image) => image !== null);
      setCoverGallery((prevGallery) => [...prevGallery, ...galleryImages]);
      const fileList = galleryImages.map((image) => image.file);
      setCoverGalleryFile((prevFiles) => [...prevFiles, ...fileList]);
    }
  };
  const handleDeleteGalleryImage = (index) => {
    setCoverGallery((prevGallery) => {
      const updatedGallery = [...prevGallery];
      updatedGallery.splice(index, 1);
      return updatedGallery;
    });
  };
  useEffect(() => {
    if (location?.state?.pId) {
      const productId = location?.state?.pId;
      setTypeCheck("edit");
      setEditProductId(productId);
      getProductDetails(productId);
    }
  }, [location?.state?.pId]);
  const getProductDetails = async (id) => {
    try {
      setIsLoading(true);
      const response = await GET_BY_ID_PRODUCT_API(id);
      if (response?.s) {
        const responseProduct = response?.r;
        formik.setValues({
          productName: responseProduct?.name,
          descreption: responseProduct?.description,
          size: responseProduct?.size,
          capacity: responseProduct?.capacity,
          use: responseProduct?.uses,
          royaltyAmount: responseProduct?.royalty_amount,
        });
        if (responseProduct?.cover_img) {
          setCoverImageUploadPreview(responseProduct?.cover_img);
          setCoverImageUploadFile(responseProduct?.cover_img);
        }
        const productMedia = responseProduct?.product_media || [];
        const videoMedia = productMedia.find((media) => media.type === 2);
        if (videoMedia) {
          setCoverVideoUploadPreview(videoMedia.media);
          setCoverVideoUploadFile(videoMedia.media);
        }
        const bannerMedia = productMedia.filter((media) => media.type === 1);
        if (bannerMedia.length > 0) {
          const galleryImages = bannerMedia.map((banner) => ({
            image: banner.media || "",
            thumb: banner.thumb || "",
          }));
          setCoverGallery(galleryImages);
          const fileList = galleryImages.map((image) => image.image);
          setCoverGalleryFile((prevFiles) => [...prevFiles, ...fileList]);
        }
      } else {
        toast.error(response.m);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productName: "",
      descreption: "",
      size: "",
      capacity: "",
      use: "",
      royaltyAmount: "",
    },
    validationSchema: Yup.object({
      productName: Yup.string().required("Please Enter Product Name"),
      descreption: Yup.string().required("Please Enter Descreption"),
      use: Yup.string().required("Please Enter Use"),
      size: Yup.string()
        .matches(/^\d+\s?[Xx]\s?\d+$/, 'Size should be in the format "12 X 12"')
        .required("Please Enter Size"),
      capacity: Yup.string()
        .matches(/^\d+\s?kg$/, 'Capacity should be in the format "20kg"')
        .required("Please Enter Capacity"),
      royaltyAmount: Yup.number()
        .typeError("Rayalty Amount must be a number")
        .required("Please Enter Rayalty Amount"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        if (typeCheck === "edit") {
          try {
            const formData = new FormData();
            formData.append("id", editProductId);
            formData.append("cover_img", coverImageUploadFile);
            formData.append("name", values.productName);
            formData.append("description", values.descreption);
            formData.append("size", values.size);
            formData.append("capacity", values.capacity);
            formData.append("uses", values.use);
            formData.append("royalty_amount", values.royaltyAmount);
            const response = await UPDATE_PRODUCT_API(formData);
            if (response?.s === 1 && response?.r?.id) {
              const videoFormData = new FormData();
              videoFormData.append("id", editProductId);
              videoFormData.append("type", 2);
              videoFormData.append("media", coverVideoUploadFile);
              videoFormData.append("thumb", coverVideoUploadFile);
              const videoResponse = await UPDATE_MEDIA_API(videoFormData);
              if (videoResponse?.r) {
                if (coverGallery?.length > 0) {
                  for (let index = 0; index < coverGallery.length; index++) {
                    const bannerFormData = new FormData();
                    bannerFormData.append("id", editProductId);
                    bannerFormData.append("type", 1);
                    bannerFormData.append("media", coverGalleryFile[index]);
                    bannerFormData.append("thumb", coverGalleryFile[index]);
                    try {
                      const bannerRes = await UPDATE_MEDIA_API(bannerFormData);
                      if (bannerRes?.m) {
                      }
                    } catch (error) {
                      toast.error(error?.m);
                    }
                  }
                }
              } else {
                toast.error(videoResponse.m);
              }
            } else {
              toast.error(response.m);
            }
            toast.success(response?.m);
          } catch (error) {
            console.log("error", error);
          } finally {
            setIsLoading(false);
            resetForm();
            setCoverImageUploadFile(null);
            setCoverImageUploadPreview(null);
            setCoverVideoUploadFile(null);
            setCoverImageUploadPreview(null);
            setCoverGallery([]);
            setCoverGalleryFile([]);
            navigate("/admin/product");
          }
        } else {
          try {
            const formData = new FormData();
            formData.append("cover_img", coverImageUploadFile);
            formData.append("name", values.productName);
            formData.append("description", values.descreption);
            formData.append("size", values.size);
            formData.append("capacity", values.capacity);
            formData.append("uses", values.use);
            formData.append("royalty_amount", values.royaltyAmount);
            const response = await ADD_PRODUCT_API(formData);
            if (response?.s === 1 && response?.r?.id) {
              const videoFormData = new FormData();
              videoFormData.append("product_id", response?.r?.id);
              videoFormData.append("type", 2);
              videoFormData.append("media", coverVideoUploadFile);
              videoFormData.append("thumb", coverVideoUploadFile);
              const videoResponse = await ADD_MEDIA_API(videoFormData);
              if (videoResponse?.r) {
                if (coverGallery?.length > 0) {
                  for (let index = 0; index < coverGallery.length; index++) {
                    const bannerFormData = new FormData();
                    bannerFormData.append("product_id", response?.r?.id);
                    bannerFormData.append("type", 1);
                    bannerFormData.append("media", coverGalleryFile[index]);
                    bannerFormData.append("thumb", coverGalleryFile[index]);
                    try {
                      const bannerRes = await ADD_MEDIA_API(bannerFormData);
                      if (bannerRes?.m) {
                      }
                    } catch (error) {
                      toast.error(error?.m);
                    }
                  }
                }
              }
            } else {
              toast.error(response.m);
            }
            toast.success(response?.m);
          } catch (error) {
            console.log("error", error);
          } finally {
            setIsLoading(false);
            resetForm();
            setCoverImageUploadFile(null);
            setCoverImageUploadPreview(null);
            setCoverVideoUploadFile(null);
            setCoverImageUploadPreview(null);
            setCoverGallery([]);
            setCoverGalleryFile([]);
            navigate("/admin/product");
          }
        }
      }
    },
  });
  return (
    <Fragment>
      <BreadCrume
        showButton={false}
        title={typeCheck === "edit" ? "Update Product" : "Add Product"}
        onClick={() => navigate(-1)}
      />
      <Box
        sx={{
          width: "100%",
          padding: "100px 0px 50px 0px",
          maxWidth: { xs: "95%", xl: "80%" },
          margin: "0 auto",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "150px",
                  overflow: "hidden",
                  background: "#F4F4F4",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!coverImageUploadPreview && (
                  <label htmlFor="cover-upload">
                    <Button
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        outline: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        textTransform: "capitalize",
                      }}
                    >
                      <LazyLoadImage
                        src={UPLOAD_GALLERY_ICON}
                        alt="Upload Icon"
                        effect="blur"
                        width={"42px"}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#000000",
                          margin: "8px 0px !important",
                        }}
                      >
                        Upload Cover Image
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#9C9C9C",
                        }}
                      >
                        {`(JPG,JPEG,PNG)`}
                      </Typography>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="cover-upload"
                        type="file"
                        onChange={handleCoverImageOnChange}
                      />
                    </Button>
                  </label>
                )}
                {coverImageUploadPreview && (
                  <Box>
                    <LazyLoadImage
                      src={coverImageUploadPreview}
                      alt="Uploaded Image"
                      effect="blur"
                      height={"150px"}
                      width={"150px"}
                    />
                    <label htmlFor="cover-upload">
                      <IconButton
                        component="span"
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "16px",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          transition: "0.5s",
                          position: "absolute",
                          right: "5px",
                          top: "5px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        <EditIcon
                          sx={{
                            color: "#46ADF2",
                            fontSize: "26px",
                          }}
                        />
                      </IconButton>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="cover-upload"
                        type="file"
                        onChange={handleCoverImageOnChange}
                      />
                      <IconButton
                        component="span"
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "16px",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          transition: "0.5s",
                          position: "absolute",
                          right: "50px",
                          top: "5px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCoverImageUploadPreview(null);
                          setCoverImageUploadFile(null);
                        }}
                      >
                        <CloseIcon
                          sx={{
                            color: "#46ADF2",
                            fontSize: "26px",
                          }}
                        />
                      </IconButton>
                    </label>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "150px",
                  overflow: "hidden",
                  background: "#F4F4F4",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!coverVideoUploadPreview && (
                  <label htmlFor="coverVideo">
                    <Button
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        outline: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        textTransform: "capitalize",
                      }}
                    >
                      <LazyLoadImage
                        src={UPLOAD_GALLERY_ICON}
                        alt="Upload Icon"
                        effect="blur"
                        width={"42px"}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#000000",
                          margin: "8px 0px !important",
                        }}
                      >
                        Upload Cover Video
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#9C9C9C",
                        }}
                      >
                        {`(MP3,MP4,MP5)`}
                      </Typography>
                      <input
                        accept="video/*"
                        style={{ display: "none" }}
                        id="coverVideo"
                        type="file"
                        onChange={handleCoverVideoOnChange}
                      />
                    </Button>
                  </label>
                )}
                {coverVideoUploadPreview && (
                  <Box>
                    <video
                      controls
                      className="video"
                      name="coverVideo"
                      width={"250px"}
                      height={"150px"}
                      style={{
                        objectFit: "cover",
                      }}
                    >
                      <source src={coverVideoUploadPreview} type="video/mp4" />
                    </video>
                    <label htmlFor="coverVideo">
                      <IconButton
                        component="span"
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "16px",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          transition: "0.5s",
                          position: "absolute",
                          right: "5px",
                          top: "5px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        <EditIcon
                          sx={{
                            color: "#46ADF2",
                            fontSize: "26px",
                          }}
                        />
                      </IconButton>
                      <input
                        accept="video/*"
                        style={{ display: "none" }}
                        id="coverVideo"
                        type="file"
                        onChange={handleCoverVideoOnChange}
                      />
                      <IconButton
                        component="span"
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "16px",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          transition: "0.5s",
                          position: "absolute",
                          right: "50px",
                          top: "5px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCoverVideoUploadFile(null);
                          setCoverVideoUploadPreview(null);
                        }}
                      >
                        <CloseIcon
                          sx={{
                            color: "#46ADF2",
                            fontSize: "26px",
                          }}
                        />
                      </IconButton>
                    </label>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Upload Only Transparent Image
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "150px",
                  overflow: "hidden",
                  background: "#F4F4F4",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <label htmlFor="cover-Gallery">
                  <Button
                    component="span"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      outline: "none",
                      border: "none",
                      backgroundColor: "transparent",
                      textTransform: "capitalize",
                    }}
                  >
                    <LazyLoadImage
                      src={UPLOAD_GALLERY_ICON}
                      alt="Upload Icon"
                      effect="blur"
                      width={"42px"}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000000",
                        margin: "8px 0px !important",
                      }}
                    >
                      Upload Gallery
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#9C9C9C",
                      }}
                    >
                      {`(JPG,JPEG,PNG)`}
                    </Typography>
                    <input
                      multiple
                      accept="image/*"
                      style={{ display: "none" }}
                      id="cover-Gallery"
                      type="file"
                      onChange={handleCoverGalleryOnChange}
                    />
                  </Button>
                </label>
              </Box>
              <Grid container spacing={3}>
                {coverGallery &&
                  coverGallery?.map((gallery, index) => {
                    return (
                      <Grid item xs={12} md={6} lg={3} key={index}>
                        <Card
                          sx={{
                            position: "relative",
                          }}
                        >
                          <CardContent>
                            <LazyLoadImage
                              src={gallery?.image}
                              alt="Upload Icon"
                              effect="blur"
                              width={"100%"}
                              height={"250px"}
                              style={{
                                borderRadius: "10px",
                              }}
                            />
                            <IconButton
                              component="span"
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "16px",
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                transition: "0.5s",
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                },
                              }}
                              onClick={() => handleDeleteGalleryImage(index)}
                            >
                              <CloseIcon
                                sx={{
                                  color: "#46ADF2",
                                  fontSize: "26px",
                                }}
                              />
                            </IconButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                className="form-control"
                placeholder="Product Name"
                name="productName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.productName}
                onKeyUp={formik.handleBlur}
                style={{
                  padding: "16px 12px 16px 12px",
                }}
                invalid={
                  formik.touched.productName && formik.errors.productName
                    ? true
                    : false
                }
              />
              {formik.touched.productName && formik.errors.productName ? (
                <FormFeedback type="invalid">
                  {formik.errors.productName}
                </FormFeedback>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                className="form-control"
                placeholder="Descreption"
                name="descreption"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.descreption}
                onKeyUp={formik.handleBlur}
                style={{
                  padding: "16px 12px 16px 12px",
                }}
                invalid={
                  formik.touched.descreption && formik.errors.descreption
                    ? true
                    : false
                }
              />
              {formik.touched.descreption && formik.errors.descreption ? (
                <FormFeedback type="invalid">
                  {formik.errors.descreption}
                </FormFeedback>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                className="form-control"
                placeholder="Size"
                name="size"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.size}
                onKeyUp={formik.handleBlur}
                style={{
                  padding: "16px 12px 16px 12px",
                }}
                invalid={
                  formik.touched.size && formik.errors.size ? true : false
                }
              />
              {formik.touched.size && formik.errors.size ? (
                <FormFeedback type="invalid">{formik.errors.size}</FormFeedback>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                className="form-control"
                placeholder="Use"
                name="use"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.use}
                onKeyUp={formik.handleBlur}
                style={{
                  padding: "16px 12px 16px 12px",
                }}
                invalid={formik.touched.use && formik.errors.use ? true : false}
              />
              {formik.touched.use && formik.errors.use ? (
                <FormFeedback type="invalid">{formik.errors.use}</FormFeedback>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                className="form-control"
                placeholder="Capacity"
                name="capacity"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.capacity}
                onKeyUp={formik.handleBlur}
                style={{
                  padding: "16px 12px 16px 12px",
                }}
                invalid={
                  formik.touched.capacity && formik.errors.capacity
                    ? true
                    : false
                }
              />
              {formik.touched.capacity && formik.errors.capacity ? (
                <FormFeedback type="invalid">
                  {formik.errors.capacity}
                </FormFeedback>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Input
                type="number"
                className="form-control no-spin-buttons"
                placeholder="Royalty Amount"
                name="royaltyAmount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.royaltyAmount}
                onKeyUp={formik.handleBlur}
                style={{
                  padding: "16px 12px 16px 12px",
                }}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                invalid={
                  formik.touched.royaltyAmount && formik.errors.royaltyAmount
                    ? true
                    : false
                }
              />
              {formik.touched.royaltyAmount && formik.errors.royaltyAmount ? (
                <FormFeedback type="invalid">
                  {formik.errors.royaltyAmount}
                </FormFeedback>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center" }}>
                <CustomButton
                  type="submit"
                  className='addProductSubmit'
                  btn_title={
                    typeCheck === "edit" ? "Update Product" : "Add Product"
                  }
                  loading={isLoading}
                  disabled={!(formik.isValid && formik.dirty && coverGallery?.length > 0 && coverVideoUploadPreview && coverImageUploadFile)}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Fragment>
  );
};

export default AddProduct;
