import AxiosInstance from "../../AxiosInstance";
export const SEND_NOTIFICATION = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + `/notification/send`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_NOTIFICATION = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + `/notification/get-all`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const FCM_NOTIFICATION = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + `/notification/fcm-add-update`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
