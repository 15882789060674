import React, { useEffect, useState } from "react";
import { GET_GENERAL_DOCS } from "../../../../Config/Api/Admin/TearmAndPrivacy/TearmAndPrivacy";
import "./ManufacturPrivacyPolicy.scss";
import { toast } from "react-toastify";
const ManufacturPrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getPrivacyPolicy();
  }, []);
  const getPrivacyPolicy = async () => {
    try {
      setIsLoading(true);
      const response = await GET_GENERAL_DOCS();
      if (response && response.s === 1) {
        const privacyPolicy = response.r;
        setPrivacyPolicy(privacyPolicy);
      } else {
        toast.error("Failed to retrieve privacy policy");
      }
    } catch (error) {
      toast.error("Error occurred while fetching privacy policy");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="manufacturPrivacyPolicy">
      {privacyPolicy ? (
        privacyPolicy?.map((elem, index) => {
          return (
            <div className="privacyProlicyContent" key={index}>
               <p className="descreption" dangerouslySetInnerHTML={{__html: elem?.privacy_policy}}></p>
            </div>
          );
        })
      ) : (
        <div className="notFound">No Data</div>
      )}
    </div>
  );
};

export default ManufacturPrivacyPolicy;
