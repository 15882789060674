import React, { Fragment, useEffect, useState } from "react";
import { BiPlus, BiX } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import { GET_FAQ_API } from "../../../../Config/Api/HelpSupportApi";
import "./ManufacturFaq.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
} from "@mui/material";
const ManufacturFaq = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeFaq, setActiveFaq] = useState(null);
  const [faqList, setFaqList] = useState([]);
  const [currentPage] = useState(1);
  const countPerPage = 1;
  const handleFaqChange = (index) => (event, activeFaq) => {
    setActiveFaq(activeFaq ? index : null);
  };
  useEffect(() => {
    loadFaqList();
  }, []);

  const loadFaqList = async () => {
    setIsLoading(true);
    try {
      const response = await GET_FAQ_API(countPerPage, currentPage);
      if (response && response.s === 1) {
        setFaqList(response.r);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Fragment>
      <div className="manufacturFaq">
        {isLoading && (
          <div className="skeleton">
            <Skeleton count={3} height={130} />
          </div>
        )}
        {!isLoading && (
          <div className="faqSection">
            <div className="faqList">
              {faqList?.length > 0 &&
                faqList?.map((elem, index) => {
                  return (
                    <Accordion
                      key={index}
                      expanded={activeFaq === index}
                      onChange={handleFaqChange(index)}
                    >
                      <AccordionSummary
                        expandIcon={
                          activeFaq === index ? (
                            <div className="customCloseIcon">
                              <BiX color="#00000" />
                            </div>
                          ) : (
                            <div className="customPlusIcon">
                              <BiPlus color={activeFaq === index ? "#000" : "#ffffff"} />
                            </div>
                          )
                        }
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                      >
                        {elem?.question}
                      </AccordionSummary>
                      <AccordionDetails>{elem?.answer}</AccordionDetails>
                    </Accordion>
                  );
                })}
            </div>
          </div>
        )}
        {!isLoading && faqList?.length === 0 && (
          <div
            className="no-data"
            style={{
              padding: "50px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No data found
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default ManufacturFaq;
