import React, { Fragment, useEffect, useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import {
  GET_ORDER_PRODUCT_ALL,
  VRIFY_ORDER_PRODUCT,
} from "../../../Config/Api/ProductApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import { Box, IconButton, Typography } from "@mui/material";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import useDebounce from "../../../Components/useDebounce/useDebounce";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ManufacturPurchaseHistory = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchPurchase, setSearchPurchase] = useState("");
  const [purchaseList, setPurchaseList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const debouncedSearch = useDebounce(searchPurchase, 400);
  useEffect(() => {
    getVerifyOrder();
  }, []);
  useEffect(() => {
    getPurchaseList();
  }, [debouncedSearch]);
  const getPurchaseList = async () => {
    const userDetails = JSON.parse(localStorage.getItem("RoaveBox"));
    const user_id = userDetails.id;
    try {
      const response = await GET_ORDER_PRODUCT_ALL({
        params: {
          user_id: user_id,
          search: debouncedSearch,
          count: pageNumber * 30,
        },
      });
      if (response?.s === 1 && response) {
        setPurchaseList(response?.r);
      }
    } catch (error) {
      console.error("Error fetching purchase list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getVerifyOrder = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get("order_id");
    const token = urlParams.get("token");
    const isCancel = urlParams.get("is_cancel");
    if (isCancel !== "true" && orderId && token) {
      try {
        const response = await VRIFY_ORDER_PRODUCT(orderId, token);
        if (response?.s === 1 && response) {
          navigate("/manufactur/purchase-history");
          toast.success(response?.m);
          getPurchaseList();
        } else {
          toast.error(response?.m);
        }
      } catch (error) {
        console.error("Error occurred while processing the purchase:", error);
      }
    }
  };
  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (d) => `${d.id}`,
        Cell: (row) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {Number(row.row.id) + 1}
            </Typography>
          );
        },
      },
      {
        Header: "Product",
        accessor: (d) => `${d?.product_details?.cover_img}`,
        Cell: ({ row }) => {
          return (
            <LazyLoadImage
              src={row?.original?.product_details.cover_img}
              alt="Uploaded Image"
              effect="blur"
              width="45px"
              height="45px"
            />
          );
        },
      },
      {
        Header: "Product Name",
        accessor: (d) => `${d?.product_details?.name}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row?.original?.product_details?.name}
            </Typography>
          );
        },
      },
      {
        Header: "User Id ",
        accessor: (d) => `${d?.user_id}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row.original?.user_id}
            </Typography>
          );
        },
      },
      {
        Header: "Transaction Id ",
        accessor: (d) => `${d.transaction_id}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row.original?.transaction_id}
            </Typography>
          );
        },
      },
      {
        Header: "Payer Id ",
        accessor: (d) => `${d.payer_id}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row?.original?.payer_id}
            </Typography>
          );
        },
      },
      {
        Header: "Royalty Amount",
        accessor: (d) => `${d?.product_details?.royalty_amount}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row?.original?.product_details?.royalty_amount}
            </Typography>
          );
        },
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <IconButton
                aria-label="view"
                onClick={() =>
                  navigate("/manufactur/purchase-history/view", {
                    state: {
                      purchaseId: row?.original?.id,
                    },
                  })
                }
                sx={{
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
              >
                <VisibilityIcon />
              </IconButton>
              {row?.original?.blueprint && (
                <IconButton
                  aria-label="Download"
                  sx={{
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                  }}
                  target="_blank"
                  download
                  href={`${row?.original?.blueprint?.media}`}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
  }, []);
  useEffect(() => {
    if (!hasMore) return;
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore]);
  return (
    <Fragment>
      <Box
        sx={{
          marginTop: "24px",
        }}
      >
        <CustomSearch
          value={searchPurchase}
          onClear={() => {
            setSearchPurchase("");
          }}
          onChange={(e) => setSearchPurchase(e.target.value)}
          placeholder="Search Purchase"
        />
      </Box>
      <Box sx={{ marginTop: "28px" }}>
        <TableContainer
          columns={columns}
          data={purchaseList}
          isGlobalFilter={true}
          customPageSize={30}
          isAddButton={false}
          pagination={false}
          noData={purchaseList.length > 0 ? false : true}
          noDataTitle={"No Found"}
          isLoading={isLoading}
        />
      </Box>
    </Fragment>
  );
};

export default ManufacturPurchaseHistory;
