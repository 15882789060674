import React, { useEffect, useState } from "react";
import {
  Button,
  CardImg,
  Col,
  Form,
  FormFeedback,
  Input,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../../Components/CustomDropdown/CustomDropdown";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaCamera } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import "./ManufacturEditProfile.scss";
import {
  GET_USER_API_ID,
  UPDATE_USER_API,
} from "../../../Config/Api/users/UsersApi";
import { COUNTRY_LIST_API } from "../../../Config/Api/AuthApi";
import { UPDATE_DOCUMENT_API } from "../../../Config/Api/DocumentApi";
import PhoneInput from "react-phone-input-2";
const ManufacturEditProfile = () => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [userProfileFile, setUserProfileFile] = useState(null);
  const [documentUpload, setDocumentUpload] = useState(null);
  const [documentUploadName, setDocumentUploadName] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [userId, setUserId] = useState(null);
  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 10 * 1024 * 1024) {
        setUserProfile(URL.createObjectURL(file));
        formik.setFieldValue("profile_img", file);
        setUserProfileFile(file);
      } else {
        toast.error("Selected file exceeds 10 MB limit.");
      }
    }
  };
  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        setDocumentUpload(file);
        setDocumentUploadName(file.name);
      } else {
        toast.error("Selected file exceeds 5 MB limit.");
      }
    }
  };
  useEffect(() => {
    const roaveBox = JSON.parse(localStorage.getItem("RoaveBox"));
    if (roaveBox) {
      const userId = roaveBox?.id;
      setUserId(userId);
      getUserDetails(userId);
      getCountryList();
    }
  }, []);
  const getUserDetails = async (userId) => {
    try {
      const response = await GET_USER_API_ID(userId);
      if (response?.s === 1) {
        const roaveBoxUser = response?.r;
        const countryId = roaveBoxUser?.country_id;
        formik.setValues({
          firstName: roaveBoxUser?.f_name || "",
          lastName: roaveBoxUser?.l_name || "",
          email: roaveBoxUser?.email || "",
          phno_cc: roaveBoxUser?.phno_cc || "",
          phoneNumber: roaveBoxUser?.phno || "",
          countryId: countryId || "",
          companyName: roaveBoxUser?.company_name || "",
          companyAddress: roaveBoxUser?.address || "",
          document: null,
          profile_img: null,
        });
        if (roaveBoxUser?.user_document?.length > 0) {
          const pdfUrl = roaveBoxUser?.user_document[0]?.document;
          const pdfName = pdfUrl.substring(pdfUrl.lastIndexOf("/") + 1);
          setDocumentUpload(pdfUrl);
          setDocumentUploadName(pdfName);
        }
        if (roaveBoxUser?.profile_img) {
          setUserProfile(roaveBoxUser?.profile_img);
        }
        if (countryId) {
          const country = countryList?.find((elem) => elem?.id === countryId);
          setSelectedCountry(country);
        }
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  const getCountryList = async () => {
    try {
      const response = await COUNTRY_LIST_API();
      if (response && response.s === 1) {
        const countries = response.r;
        setCountryList(countries);
      }
    } catch (error) {
      console.error("Error fetching Country list:", error);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      phno_cc: "",
      countryId: "",
      companyName: "",
      companyAddress: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Email address is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      countryId: Yup.string().required("Country is required"),
      companyName: Yup.string().required("Company Name is required"),
      companyAddress: Yup.string().required("Company Address is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (busy) {
        return;
      } else {
      }
      if (userId !== null) {
        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("f_name", values.firstName);
        formData.append("l_name", values.lastName);
        formData.append("email", values.email);
        formData.append("phno", values.phoneNumber);
        formData.append("phno_cc", values.phno_cc);
        formData.append("company_name", values.companyName);
        formData.append("address", values.companyAddress);
        formData.append("country_id", values.countryId);
        formData.append("profile_img", userProfileFile);
        setBusy(true);
        try {
          const response = await UPDATE_USER_API(formData);
          if (response?.s === 1) {
            toast.success(response.m);
            if (response?.r?.id) {
              const documentFormData = new FormData();
              documentFormData.append("document", documentUpload);
              documentFormData.append("id", userId);
              const response1 = await UPDATE_DOCUMENT_API(documentFormData);
              if (response1?.s) {
              } else {
                toast.error(response1.m);
              }
            }
            navigate(-1);
          } else {
            toast.error(response.m);
          }
        } catch (error) {
          toast.error(error.m);
          setBusy(false);
        } finally {
          setBusy(false);
          resetForm();
        }
      }
    },
  });
  return (
    <div className="manufacturEditProfile">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <div className="manufacturEditProfileCard">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#46ADF2",
              padding: "10px 15px",
              width: "100%",
              position: "fixed",
              top: "0px",
              left: "0px",
              right: "0px",
              zIndex: "10",
            }}
          >
            <button
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "#ffffff",
                outline: "none",
                border: "none",
              }}
              onClick={() => navigate("/manufactur/dashboard")}
            >
              <FaArrowLeftLong color="#46ADF2" fontSize={"22px"} />
            </button>
            <h6
              style={{ color: "#ffffff", fontSize: "18px", fontWeight: "500" }}
            >
              Edit Profile
            </h6>
            <CustomButton
              type="submit"
              disabled={!(formik.dirty && formik.isValid)}
              btn_title="Update"
              style={{
                width: "170px",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "400",
                borderRadius: "8px",
                backgroundColor: "#ffffff",
                outline: "none",
                border: "none",
                color: "#4D4E4E",
                textTransform: "capitalize",
                height: "60px",
              }}
              loading={busy}
            />
          </div>
          <div
            style={{
              padding: "100px 15px 20px 15px",
            }}
          >
            <Row className="justify-content-center align-items-center">
              <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <div className="uploadUserProfile">
                  <div className="userProfilePrev">
                    {userProfile && (
                      <CardImg
                        alt="logo"
                        src={userProfile}
                        style={{
                          width: "300px",
                          height: "300px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      className="fileUploadInput"
                      name="image"
                      onChange={(e) => handleProfileChange(e)}
                    />
                    {userProfile ? (
                      <Button
                        type="button"
                        className="deleteIcon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setUserProfile(null);
                        }}
                      >
                        <MdClose />
                      </Button>
                    ) : (
                      <Button type="button" className="editBtn">
                        <FaCamera />
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                  >
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="firstName"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.firstName && formik.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {formik.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                  >
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="lastName"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.lastName && formik.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {formik.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                  >
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      name="email"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.email && formik.errors.email
                          ? true
                          : false
                      }
                      autoComplete="off"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <FormFeedback type="invalid">
                        {formik.errors.email}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                  >
                    {/* <Input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? true
                          : false
                      }
                    /> */}
                    <PhoneInput
                      name="phoneNumber"
                      country={"in"}
                      placeholder="Enter Mobile Number"
                      value={formik.values.phno_cc + formik.values.phoneNumber}
                      onChange={(value, country) => {
                        const countryCodeLength = country.dialCode.length;
                        const phno_cc = value.slice(0, countryCodeLength);
                        const phno = value.slice(countryCodeLength);
                        formik.setFieldValue("phoneNumber", phno);
                        formik.setFieldValue("phno_cc", phno_cc);
                      }}
                      inputClass="inputClass"
                    />
                    {/* {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <FormFeedback type="invalid">
                        {formik.errors.phoneNumber}
                      </FormFeedback>
                    ) : null} */}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                  >
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      name="companyName"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.companyName}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.companyName && formik.errors.companyName
                          ? true
                          : false
                      }
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <FormFeedback type="invalid">
                        {formik.errors.companyName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                  >
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Company Address"
                      name="companyAddress"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.companyAddress}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.companyAddress &&
                        formik.errors.companyAddress
                          ? true
                          : false
                      }
                    />
                    {formik.touched.companyAddress &&
                    formik.errors.companyAddress ? (
                      <FormFeedback type="invalid">
                        {formik.errors.companyAddress}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                  >
                    <CustomDropdown
                      selectedValue={
                        selectedCountry
                          ? selectedCountry
                          : { id: formik.values.countryId }
                      }
                      dropDownItems={countryList?.map((c, i) => ({
                        id: c?.id,
                        value: c?.name,
                        label: c?.name,
                        key: i,
                      }))}
                      placeholder={"Select Country"}
                      onSelect={(evt) => {
                        setSelectedCountry(evt);
                        formik.setFieldValue("countryId", evt.id);
                      }}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    className="mb-3"
                  >
                    <div className="identityUpload">
                      <div className="uploadIconBox">
                        <MdOutlineFileUpload fontSize={"24px"} />
                        {documentUpload ? (
                          <p className="text">{documentUploadName}</p>
                        ) : (
                          <p className="text">Identity</p>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.psd,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          className="uploadDocument"
                          name="document"
                          onChange={(e) => handleDocumentChange(e)}
                        />
                        {documentUpload && (
                          <button
                            type="button"
                            className="editButton"
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                              outline: "none",
                              border: "none",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                              width: "30px",
                              height: "30px",
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FaRegEdit fontSize={"18px"} />
                          </button>
                        )}
                        {documentUpload && (
                          <button
                            type="button"
                            className="deleteButton"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDocumentUpload(null);
                              setDocumentUploadName(null);
                            }}
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "40px",
                              zIndex: "2",
                              outline: "none",
                              border: "none",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                              width: "30px",
                              height: "30px",
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <RiDeleteBin6Line fontSize={"18px"} color="red" />
                          </button>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ManufacturEditProfile;
