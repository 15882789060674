import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Form, FormFeedback } from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { FaRegEdit } from "react-icons/fa";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import {
  GET_GENERAL_DOCS,
  UPDATE_GENERAL_DOCS,
} from "../../../../Config/Api/Admin/TearmAndPrivacy/TearmAndPrivacy";
import "./CodeOfConduct.scss";
const CodeOfConduct = () => {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    getcodeofconduct();
  }, [])


  const getcodeofconduct = async () => {
    try {
      setIsLoading(true);
      const response = await GET_GENERAL_DOCS();
      if (response && response.s === 1 && response.r[0]) {
        const code_of_conduct = response.r[0].code_of_conduct || "";
        formik.setValues({
          code_of_conduct: code_of_conduct,
        });
        setIsEditMode(true);
      } else {
        toast.error("Failed to retrieve privacy policy");
      }
    } catch (error) {
      toast.error("Error occurred while fetching privacy policy");
    } finally {
      setIsLoading(false);
    }
  };
 
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code_of_conduct: "",
    },
    validationSchema: Yup.object({
      code_of_conduct: Yup.string().required("code of conduct Is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const response = await UPDATE_GENERAL_DOCS({
          code_of_conduct: values.code_of_conduct,
        });
        if (response && response?.s === 1) {
          toast.success(response?.m);
          getcodeofconduct();
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        resetForm();
        setIsLoading(false);
      }
    },
  });

  return (
    <div className="customQuill">
      <div className="headingSection">
        <h6>Code Of Conduct</h6>
        {isEditMode && (
          <button
            className="editButton"
            type="button"
            onClick={() => setIsEditMode(false)}
          >
            <FaRegEdit fontSize={"18px"} />
          </button>
        )}
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        {isLoading ? (
          <Skeleton height={200} />
        ) : (
          <>
            <ReactQuill
              placeholder="Enter Description"
              value={formik.values.code_of_conduct}
              ref={ref}
              readOnly={isEditMode}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [
                    { align: "right" },
                    { align: "center" },
                    { align: null },
                    { align: "justify" },
                  ],
                ],
              }}
              onChange={(content, delta, source, editor) => {
                content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
                formik.setFieldValue("code_of_conduct", content);
              }}
            />
            {formik.touched.code_of_conduct && formik.errors.code_of_conduct ? (
              <FormFeedback type="invalid">
                {formik.errors.code_of_conduct}
              </FormFeedback>
            ) : null}
          </>
        )}
        <div className="submitButton">
          <CustomButton
            type="submit"
            className="submitBtn"
            btn_title="Save"
            disabled={!(formik.values.code_of_conduct.replace(/<[^>]+>/g, '').trim()) || isEditMode}
            loading={isLoading}
          />
        </div>
      </Form>
    </div>
  );
};

export default CodeOfConduct;
