import React, { Fragment, useEffect, useState } from "react";
import { FaPlayCircle } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap";
import {
  GET_PRODUCT_ID_API,
  PURCHASE_PRODUCT,
} from "../../../../Config/Api/ProductApi";
import BreadCrume from "../../../../Components/BreadCrume/BreadCrume";
import { IoMdCloseCircle } from "react-icons/io";
import "./ProductShowCaseDetails.scss";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import CustomModel from "../../../../Components/Model/Model";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ProductShowCaseDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [productQuantityModel, setShowProductQuantityModel] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  useEffect(() => {
    if (location?.state?.productId) {
      const product_id = location?.state?.productId;
      getProductDetails(product_id);
      setSelectedProductId(product_id);
    }
  }, [location?.state?.productId]);
  const getProductDetails = async (productId) => {
    setIsLoading(true);
    try {
      const res = await GET_PRODUCT_ID_API(productId);
      if (res && res.s === 1) {
        const productMedia = res.r.product_media;
        const sortedMedia = productMedia.sort((a, b) => {
          if (a.type === 2) return -1;
          if (b.type === 2) return 1;
          return 0;
        });
        setIsLoading(false);
        setProductDetails({
          ...res.r,
          product_media: sortedMedia,
        });
      }
    } catch (error) {
      throw error;
    }
  };
  const formik = useFormik({
    initialValues: {
      quantity: "",
    },
    validationSchema: Yup.object().shape({
      quantity: Yup.number()
        .typeError("Quantity must be a number")
        .required("Quantity is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const formData = new FormData();
          formData.append("product_id", selectedProductId);
          formData.append("quantity", values.quantity);
          const response = await PURCHASE_PRODUCT(formData);
          if (response?.s === 1 && response?.r?.links) {
            const approveLink = response.r.links.find(
              (link) => link.rel === "approve"
            );
            if (approveLink) {
              window.location.href = approveLink.href;
              setIsLoading(false);
            } else {
              console.log("Approval link not found in response");
              setIsLoading(false);
            }
          } else {
            console.log("Invalid response or order creation failed");
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error occurred while processing the purchase:", error);
          setIsLoading(false);
        }
      }
    },
  });
  return (
    <Fragment>
      <div className="productShowCaseDetails">
        <div className="productShowCaseContainer">
          <BreadCrume
            showButton={true}
            title={"Product ShowCase Details"}
            title1={"Purchase Now"}
            onClick={() => navigate(-1)}
            onSubmit={() => setShowProductQuantityModel(true)}
          />
          <Row>
            {isLoading ? (
              <>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="mb-3"
                >
                  <Skeleton height={300} />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="mb-3"
                >
                  <Skeleton height={300} />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="mb-3"
                >
                  <Skeleton height={300} />
                </Col>
              </>
            ) : (
              <>
                {productDetails &&
                  productDetails?.product_media?.map((media, index) => (
                    <React.Fragment key={index}>
                      {media?.type === 2 && (
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          className="mb-3"
                        >
                          <div className="backgroundVideo">
                            <div className="backgroundVideoPadding">
                              <video
                                autoPlay={isVideoPlaying}
                                muted
                                loop
                                className="customVideo"
                              >
                                <source src={media?.media} type="video/mp4" />
                              </video>
                              {!isVideoPlaying && (
                                <button
                                  className="playButton"
                                  onClick={() => {
                                    setIsVideoPlaying(true);
                                    setModalOpen(true);
                                  }}
                                >
                                  <FaPlayCircle
                                    color="#ffffff"
                                    fontSize={"32px"}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                      {media?.type === 1 && (
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          lg={4}
                          xl={4}
                          xxl={4}
                          className="mb-3"
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "300px",
                              padding: "10px",
                              border: "1px solid #fff",
                              borderRadius: "8px",
                              overflow: "hidden",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            }}
                          >
                            <img
                              src={media?.media}
                              alt="images"
                              style={{
                                width: "100%",
                                objectFit: "contain",
                                borderRadius: "10px",
                                height: "100%",
                                transition: "transform 0.3s ease",
                              }}
                            />
                          </div>
                        </Col>
                      )}
                    </React.Fragment>
                  ))}
              </>
            )}
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="productFullDetails">
                {isLoading ? (
                  <>
                    <Skeleton count={2} height={24} />
                    <Skeleton count={5} height={16} />
                  </>
                ) : (
                  <>
                    <h6>{productDetails?.name}</h6>
                    <p>{productDetails?.description}</p>
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="productFullDetails" mt={2}>
                {isLoading ? (
                  <>
                    <Skeleton count={3} height={16} />
                  </>
                ) : (
                  <>
                    <h6>Product Details</h6>
                    <p>
                      <span className="span1">Size:</span>
                      <span className="span2">{productDetails?.size}</span>
                    </p>
                    <p>
                      <span className="span1">Capacity:</span>
                      <span className="span2">{productDetails?.capacity}</span>
                    </p>
                    <p>
                      <span className="span1">Use:</span>
                      <span className="span2">{productDetails?.uses}</span>
                    </p>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        centered
        isOpen={modalOpen}
        onClose={() => {
          setIsVideoPlaying(false);
          setModalOpen(false);
        }}
        className="videoOpenModel"
      >
        <div className="bgVideo">
          <div className="bgVideoPadding">
            {productDetails &&
              productDetails.product_media &&
              productDetails.product_media.length > 0 && (
                <video
                  autoPlay={isVideoPlaying}
                  muted
                  loop
                  controls
                  className="bgVideo"
                >
                  <source
                    src={productDetails.product_media[0].media}
                    type="video/mp4"
                  />
                </video>
              )}
          </div>
        </div>
        <button
          className="closeModel"
          onClick={() => {
            setIsVideoPlaying(false);
            setModalOpen(false);
          }}
        >
          <IoMdCloseCircle color="#000" fontSize={"32px"} />
        </button>
      </Modal>
      <CustomModel
        open={productQuantityModel}
        onClose={() => {
          setShowProductQuantityModel(false);
          setSelectedProductId(null);
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: {md:"80vh"},
            overflowY: "auto",
            padding:'0px 10px'
          }}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "28px",
                    fontWeight: "600",
                    color: "#000000",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Purchase Now
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                >
                  {isLoading ? (
                    <Skeleton height={"200px"} />
                  ) : (
                    <LazyLoadImage
                      src={productDetails?.cover_img}
                      alt="Upload Icon"
                      effect="blur"
                      width={"200px"}
                      height={"200px"}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Label
                  htmlFor="name"
                  style={{
                    color: "#252525",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Title
                </Label>
                {isLoading ? (
                  <Skeleton height={"40px"} />
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#252525",
                      fontSize: "16px",
                      lineHeight: "21px",
                      fontWeight: "700",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      textAlign: "justify",
                      wordBreak: "break-all",
                      padding: "16px!important",
                    }}
                  >
                    {productDetails?.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Label
                  style={{
                    color: "#252525",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Descreption
                </Label>
                {isLoading ? (
                  <Skeleton height={"70px"} />
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#252525",
                      fontSize: "16px",
                      lineHeight: "21px",
                      fontWeight: "400",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      textAlign: "justify",
                      wordBreak: "break-all",
                      padding: "16px!important",
                    }}
                  >
                    {productDetails?.description}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Label
                  style={{
                    color: "#252525",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Quantity
                </Label>
                {isLoading ? (
                  <Skeleton height={"40px"} />
                ) : (
                  <Input
                    type="number"
                    placeholder="Enter Quantity"
                    name="quantity"
                    className="form-control no-spin-buttons"
                    onChange={(e) => {
                      const { value } = e.target;
                      formik.handleChange({
                        target: {
                          name: "quantity",
                          value: value.trimStart(),
                        },
                      });
                    }}
                    style={{
                      padding: "16px 12px 16px 12px",
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantity}
                    onKeyUp={formik.handleBlur}
                    invalid={formik.touched.quantity && formik.errors.quantity}
                    autoComplete="off"
                    min="0"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                  />
                )}

                {formik.touched.quantity && formik.errors.quantity ? (
                  <FormFeedback type="invalid">
                    {formik.errors.quantity}
                  </FormFeedback>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  type="submit"
                  className="submitBtn"
                  btn_title="Purchase Now"
                  loading={isLoading}
                  disabled={!(formik.dirty && formik.isValid)}
                  style={{
                    width: "100%",
                    height: "55px",
                    fontSize: "18px",
                    fontWeight: "500px",
                    backgroundColor: "#46adf2",
                    outline: "none",
                    border: "none",
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </CustomModel>
    </Fragment>
  );
};

export default ProductShowCaseDetails;
