import { Navigate } from "react-router-dom";
import AdminLayout from "./Layout/AdminLayout/AdminLayout";
import ManufacturLayout from './Layout/ManufacturLayout/ManufacturLayout';
import Login from "./Pages/Auth/Login/Login";
import Forgot from "./Pages/Auth/Forgot/Forgot";
import SignUp from "./Pages/Auth/SignUp/SignUp";
// AdminRoutes
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";
import RevenueDetails from "./Pages/Admin/RevenueDetails/RevenueDetails";
import WebsiteManager from "./Pages/Admin/WebsiteManager/WebsiteManager";
import Manufacture from "./Pages/Admin/Manufacture/Manufacture";
import ManufaturerDetails from "./Pages/Admin/Manufacture/ManufaturerDetails/ManufaturerDetails";
import Notification from "./Pages/Admin/Notification/Notification";
import Product from "./Pages/Admin/Product/Product";
import AddProduct from "./Pages/Admin/Product/AddProduct/AddProduct";
import ContactUs from "./Pages/Admin/ContactUs/ContactUs";
import PendingOrder from "./Pages/Admin/PendingOrder/PendingOrder";
//ManufacturRoutes
import ManufacturDashboard from "./Pages/Manufactur/ManufacturDashboard/ManufacturDashboard";
import ProductShowCase from "./Pages/Manufactur/ManufacturProductShowCase/ProductShowCase/ManufacturProductShowCase";
import ProductDetails from "./Pages/Manufactur/ManufacturProductShowCase/ProductShowCaseDetails/ProductShowCaseDetails";
import ManufacturHelpSupport from "./Pages/Manufactur/MenufacturHelpSupport/ManufacturHelpSupport";
import ManufacturEditProfile from "./Pages/Manufactur/ManufacturEditProfile/ManufacturEditProfile";
import ManufacturFavouriteProduct from "./Pages/Manufactur/ManufacturFavouriteProduct/ManufacturFavouriteProduct";
import ManufacturFavouriteProductDetails from "./Pages/Manufactur/ManufacturFavouriteProductDetails/ManufacturFavouriteProductDetails";
import ManufacturPurchaseHistory from "./Pages/Manufactur/ManufacturPurchaseHistory/ManufacturPurchaseHistory";
import OrderDetails from "./Pages/Admin/PendingOrder/OrderDetails/OrderDetails";
import ManufacturPurchaseHistoryView from './Pages/Manufactur/ManufacturPurchaseHistory/ManufacturPurchaseHistoryView/ManufacturPurchaseHistoryView'

const routes = [
  {
    path: "/",
    component: <Navigate to="/login" component={<Login />} />,
    exact: true,
  },
  {
    path: "/login",
    component: <Login />,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: <Forgot />,
    exact: true,
  },
  {
    path: "/signup",
    component: <SignUp />,
    exact: true,
  },
  {
    path: "/admin/*",
    component: <AdminLayout />,
    exact: true,
  },
  {
    path: "/manufactur/*",
    component: <ManufacturLayout />,
    exact: true,
  },
];
export const AdminLayoutRoute = [
  {
    path: "/",
    component: <AdminDashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/dashboard",
    component: <AdminDashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/dashboard/revenue-details",
    component: <RevenueDetails />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/website-manager",
    component: <WebsiteManager />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/manufacture",
    component: <Manufacture />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/manufaturer-details",
    authenticate: true,
    component: <ManufaturerDetails />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/product",
    authenticate: true,
    component: <Product />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/product/add-product",
    authenticate: true,
    component: <AddProduct />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/product/update-product",
    authenticate: true,
    component: <AddProduct />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/notification",
    authenticate: true,
    component: <Notification />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/contact-us",
    authenticate: true,
    component: <ContactUs />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/pending-order",
    authenticate: true,
    component: <PendingOrder />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/pending-order/Order-details",
    authenticate: true,
    component: <OrderDetails />,
    exact: true,
    sidebar: false,
  }
];
export const ManufacturLayoutRoute = [
  {
    path: "/",
    component: <ManufacturDashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/dashboard",
    component: <ManufacturDashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/product-showcase",
    component: <ProductShowCase />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/product-showcase/product-details",
    component: <ProductDetails />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/edit-profile",
    component: <ManufacturEditProfile />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/favourite-product",
    component: <ManufacturFavouriteProduct />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/favourite-product/favourite-product-details",
    component: <ManufacturFavouriteProductDetails />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/help-support",
    component: <ManufacturHelpSupport />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/purchase-history",
    component: <ManufacturPurchaseHistory />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/purchase-history/view",
    component: <ManufacturPurchaseHistoryView />,
    exact: true,
    sidebar: false,
  }
];
export default routes;
