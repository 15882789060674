import AxiosInstance from "../../AxiosInstance";
export const ADD_UPDATE_TITLE_MANAGER_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/web-management/update-title', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const GET_UPDATE_TITLE_MANAGER_API = async (payload) => {
    try {
        const response = await AxiosInstance.get(AxiosInstance.defaults.baseURL + '/web-management/get-title', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};