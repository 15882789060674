import React, { Fragment, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import AllMenufacture from "./AllMenufacture/AllMenufacture";
import ManufactureRequest from "./ManufactureRequest/ManufactureRequest";
import "./Manufacture.scss";
const Manufacture = () => {
  const [activeTab, setActiveTab] = useState("manufacturers");
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Fragment>
      <div className="manufactureTab">
        <div className="titleSection">
          <h4 className="title">Manufacture Management</h4>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "manufacturers" ? "active" : ""}
              onClick={() => toggleTab("manufacturers")}
            >
              Manufacturers
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "manufacturerRequests" ? "active" : ""}
              onClick={() => toggleTab("manufacturerRequests")}
            >
              Manufacturer Requests
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="manufacturers">
            <AllMenufacture activeTab={activeTab}/>
          </TabPane>
          <TabPane tabId="manufacturerRequests">
            <ManufactureRequest activeTab={activeTab}/>
          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  );
};

export default Manufacture;
