import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { FaAngleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import "./Orderdetails.scss";
import { MdUploadFile } from "react-icons/md";
import { ADD_BLUEPRINT } from "../../../../Config/Api/Admin/Product/ProductApi";

import { GET_ORDER_DETAILS } from "../../../../Config/Api/Admin/Product/OrderApis";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { toast } from "react-toastify";
const OrderDetails = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  }

  let [orderDetailsData, setorderDetailsData] = useState({});
  let [dataloading, setdataloading] = useState(false);
  let getOrderDetails = async () => {
    setdataloading(true);
    try {
      let res = await GET_ORDER_DETAILS(location?.state?.pId);
      if (res?.s === 1) {
        setdataloading(false);
        setorderDetailsData(res?.r);
      } else {
        setdataloading(false);
      }
    } catch (err) {
      setdataloading(false);
      console.log(err);
    }
  };
  let [uploaddocLoader, setuploaddocLoader] = useState(false)
  
  const handleUpload = async () => {
    if (selectedFile) {
      setuploaddocLoader(true);
      let fomrdata = new FormData();
      fomrdata.append("media", selectedFile);
      fomrdata.append("order_id", location?.state?.oderdata?.id);
      try {
        setuploaddocLoader(true);
        let res = await ADD_BLUEPRINT(fomrdata);
        if (res?.s == 1) {
          toast("Blueprint has been added successfully. ");
          getOrderDetails();
          setuploaddocLoader(false);
        } else {
          toast("Error while uploading blueprint !");
          setuploaddocLoader(false);
        }
      } catch {
        toast("Something Wents wrong please try again ! ");
        setuploaddocLoader(false);
      }
    } else {
      toast("Please Select File !");
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);
  const handleDownload = (docUrl) => {
    const link = document.createElement("a");
    link.href = docUrl;
    link.download = "downloaded_file.doc";
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  };
  return (
    <>
      <div>
        <div className="addProductHeader">
          <button
            className="backButton"
            onClick={() => navigate(-1)}
            type="button"
          >
            <FaAngleLeft fontSize={"28px"} color="#46adf2" />
          </button>
          <h6>Order Details</h6>
          <div></div>
        </div>
        {dataloading ? (
          <span class="loader"></span>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card
                sx={{
                  position: "relative",
                  border: "none ",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box
                        sx={{
                          height: "417px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            orderDetailsData?.product_details?.cover_img &&
                            orderDetailsData?.product_details?.cover_img
                          }
                          alt="driversProfile"
                          style={{
                            width: "350px",
                            height: "350px",
                            //   objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />

                        {orderDetailsData?.blueprint == null ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "20px",
                            }}
                          >
                            <input
                              type="file"
                              accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              id="fileInput"
                            />

                            <label
                              htmlFor="fileInput"
                              style={{
                                border: "1px solid gray",
                                padding: "20px",
                                borderRadius: "10px",
                              }}
                            >
                              {selectedFile ? (
                                <div> {selectedFile?.name}</div>
                              ) : (
                                <MdUploadFile
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </label>

                            <Button
                              style={{
                                width: "70%",
                                padding: "0px 20px",
                                marginTop: "10px",
                                padding: "5px",
                                color: "#fff",
                                textTransform: "capitalize",
                                fontSize: "16px",
                                backgroundColor: selectedFile
                                  ? "rgba(70, 173, 242, 1)"
                                  : "gray",
                              }}
                              onClick={handleUpload}
                              disabled={selectedFile === null ? true : false}
                            >
                              {uploaddocLoader ? (
                                <CircularProgress
                                  style={{ width: "20px", height: "20px" }}
                                />
                              ) : selectedFile ? (
                                "Upload Blueprint"
                              ) : (
                                "Select Blueprint"
                              )}
                              {/* {selectedFile? "Upload Blueprint" :"Select Blueprint"}   */}
                            </Button>
                          </div>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              sx={{
                                backgroundColor: "rgba(70, 173, 242, 1)",
                                padding: "10px 20px",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "5px",
                                textTransform: "capitalize",
                                fontSize: "16px",
                                transition: "background-color 0.3s", // Add transition for smooth effect
                                "&:hover": {
                                  backgroundColor: "rgba(50, 133, 212, 1)", // Darken the background color on hover
                                },
                              }}
                              onClick={() =>
                                handleDownload(
                                  orderDetailsData?.blueprint?.media
                                )
                              }
                            >
                              Download Blueprint <FileDownloadIcon />
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  color: "#46adf2",
                                }}
                              >
                                Basic information
                              </Typography>
                              <Grid sx={{ mt: "2px" }} container spacing={2}>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    Product Name
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.product_details?.name
                                      ? orderDetailsData?.product_details?.name
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    Royalty Amount
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.royalty_amount
                                      ? orderDetailsData?.royalty_amount
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    Capacity
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.product_details?.capacity
                                      ? orderDetailsData?.product_details
                                          ?.capacity
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Size
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.product_details?.size
                                      ? orderDetailsData?.product_details?.size
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  color: "#46adf2",
                                }}
                              >
                                Payment Details
                              </Typography>
                              <Grid sx={{ mt: "2px" }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Total Amount
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.total_amount
                                      ? orderDetailsData?.total_amount
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Transaction Id
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.transaction_id
                                      ? orderDetailsData?.transaction_id
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Payer Id
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.payer_id
                                      ? orderDetailsData?.payer_id
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Payment Status
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData.status ? (
                                      <Typography>
                                        {orderDetailsData?.payment_status}
                                      </Typography>
                                    ) : (
                                      "N?N"
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  paddingBottom: "10px",
                                  color: "#46adf2",
                                }}
                              >
                                User Details
                              </Typography>
                              <Grid sx={{ mt: "2px" }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    User Name
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.user_details?.f_name
                                      ? orderDetailsData?.user_details?.f_name +
                                        " " +
                                        orderDetailsData?.user_details?.l_name
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Company Name
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.user_details
                                      ?.company_name
                                      ? orderDetailsData?.user_details
                                          ?.company_name
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Email
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.user_details?.email
                                      ? orderDetailsData?.user_details?.email
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Phone Number
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "17px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {orderDetailsData?.user_details?.phno_cc
                                      ? orderDetailsData?.user_details
                                          ?.phno_cc +
                                        " " +
                                        orderDetailsData?.user_details?.phno
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default OrderDetails;
