import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Col, Input, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import DELETEICON from "../../../../Assets/Images/delete.png";
import USERADD from "../../../../Assets/Images/user.png";
import DOCUMENTIMAGE from "../../../../Assets/Images/document-img.png";
import ManufaturerImage from "../../../../Assets/Images/dummy-profile.jpg";
import { Box, Button, CircularProgress, Grid, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  GET_USER_API_ID,
  UPDATE_USER_API,
  UPDATE_USER_STATUS,
} from "../../../../Config/Api/users/UsersApi";
import { COUNTRY_LIST_API } from "../../../../Config/Api/AuthApi";
import "./ManufacturerDetails.scss";
import { toast } from "react-toastify";
import { GET_ALL_PRODUCT_API } from "../../../../Config/Api/ProductApi";
import Swal from "sweetalert2";
import CustomModel from "../../../../Components/CustomModel/CustomModel";

const ManufaturerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    if (location?.state?.userId) {
      const userId = location?.state?.userId;
      getUserDetails(userId);
      getHistoryProducts();
    }
  }, []);

  const getUserDetails = async (id) => {
    try {
      setIsLoading(true);
      const response = await GET_USER_API_ID(id);
      if (response?.s === 1) {
        setUserDetails(response?.r);

        getCountryDetails(response?.r?.country_id);
        setIsLoading(false);
      }
    } catch (error) {
      if (error === 401) {
        console.log("error", error);
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCountryDetails = async (id) => {
    try {
      const response = await COUNTRY_LIST_API(id);
      if (response?.s === 1) {
        setCountryList(response?.r);
      }
    } catch (error) {
      if (error === 401) {
        console.log("error", error);
      }
    }
  };

  const getCountryNameById = (countryId) => {
    const country = countryList.find((country) => country.id === countryId);
    return country ? country.name : "N/A";
  };
  const handleDownloadDocument = () => {
    const documentUrl = userDetails?.user_document[0]?.document;
    if (documentUrl) {
      const link = document.createElement("a");
      link.href = documentUrl;
      link.download = "document.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handeldrequestStatus = async (type) => {
    try {
      const response = await UPDATE_USER_API({
        user_id: userDetails?.id,
        is_approve: type,
      });
      
      if (response?.s === 1 && response) {
          toast.success("Request Accepted ! ");
          const userId = location?.state?.userId;
        getUserDetails(userId);
      } else {
        toast.error(response?.m);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  let [PurchesHistory, setPurchesHistory] = useState([]);

  let getHistoryProducts = async () => {
    try {
      let response = await GET_ALL_PRODUCT_API(userDetails?.id);
      setPurchesHistory(response?.r);
    } catch (err) {
      toast("Something wents wrong !");
    }
  };

  let UpdateUser = (values) => {
    Swal.fire({
      title: ` Are you sure  ?`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${
        values?.type === 0
          ? "Delete!"
          : values?.type === -1
          ? "Block it!"
          : "Unblock it !"
      } `,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await UPDATE_USER_STATUS({
            status: values?.type,
            user_id: userDetails?.id,
          });

          if (res?.s == 1) {
            getUserDetails(userDetails?.id);
            Swal.fire({
              position: "center",
              icon: "success",
              title:
                values?.type === 0
                  ? "Deleted"
                  : values?.type === -1
                  ? "Blocked "
                  : "Unblocked",
              text: ` Account has been ${
                values?.type === 0
                  ? "Deleted"
                  : values?.type === -1
                  ? "Blocked "
                  : "Unblocked"
              }`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (values?.type == 0) {
              navigate(-1);
            }
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  }

  const [rejectReason, setrejectReason] = useState("");
  const [openDetailsModel, setOpenDetailsModel] = useState(false);

  const OpenReasonModal = () => {
    setOpenDetailsModel(true);
  };

  let rejectThisrrquest = async () => {
    if (!rejectReason.trim()) {
      toast("Please Add Reason!");
      return;
  } else {
      try {
        const response = await UPDATE_USER_API({
          user_id: userDetails?.id,
          is_approve: -1,
          reason: rejectReason,
        });
        if (response?.s === 1 && response) {
          setOpenDetailsModel(false);
          toast.success(response?.m);
          getUserDetails();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };


  return (
    <div className="manufaturer-Details">

<CustomModel
        show={openDetailsModel}
        onCloseClick={() => setOpenDetailsModel(false)}
      >
        <div className="deleteModel">
          <h6 style={{ textAlign: "start" }}>
            Reason for rejecting the request !
          </h6>
          <Input
            placeholder="Enter Reason :"
            type="textarea"
            rows="5"
            onChange={(e) => setrejectReason(e.target.value)}
          />
          <div style={{marginTop:"10px"}} className="action">
            <Button
            sx={{textTransform:"capitalize" }}
            variant="contained" color="error"
              className="cBtn yes"
              onClick={() => rejectThisrrquest()}
            >
              Reject 
            </Button>
            <Button
             sx={{textTransform:"capitalize"}}
             variant="contained"
              className="cBtn no"
              onClick={() => setOpenDetailsModel(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </CustomModel>
      <div className="breadCrume">
        <div className="leftArrowBack">
          <button type="button" onClick={() => navigate(-1)}>
            <FaArrowLeftLong color="#46ADF2" fontSize={"22px"} />
          </button>
        </div>
        <div className="centerTitle">
          <h6>Manufaturer Details</h6>
        </div>
        {isLoading ? (
          <CircularProgress
            sx={{
              width: "30px",
              height: "30px",
              color: "#fff",
            }}
          />
        ) : (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <div className="buttonGroup">
              <Button
                sx={{
                  color: userDetails.status === 1 ? "red" : "green",
                  background: "red",
                }}
                onClick={() =>
                  UpdateUser({
                    type: userDetails.status === 1 ? -1 : 1,
                  })
                }
                className="actionbutton"
              >
                {" "}
                {userDetails?.status === 1 ? "Block" : "Unblock"}
              </Button>
              <Button
                sx={{ color: "red" }}
                className="actionbutton"
                onClick={() => UpdateUser({ type: 0 })}
              >
                Delete
              </Button>
            </div>

            {userDetails?.is_approve === 0 ? (
              <div className="buttonGroup">
                <Button
                  onClick={() => handeldrequestStatus(1)}
                  className="actionbutton"
                >
                  Accept
                </Button>
                <Button
                  className="actionbutton"
                  onClick={() => OpenReasonModal()}
                >
                  {" "}
                  Reject
                </Button>
              </div>
            ) : userDetails?.is_approve === -1 ? (
              <div className="buttonGroup">
                <Button
                  className="actionbutton"
                  onClick={() => handeldrequestStatus(1)}
                >
                  Accept Request
                </Button>
              </div>
            ) : (
              <div className="buttonGroup"></div>
            )}
          </Box>
        )}
      </div>
      <div className="manufaturer-Details-Card">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            {isLoading ? (
              <Skeleton height={250} />
            ) : (
              <div className="manufaturer-Details-CardImage">
                <img
                  src={
                    userDetails?.profile_img
                      ? userDetails?.profile_img
                      : ManufaturerImage
                  }
                  alt="manufaturer-Details-Card-image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                {isLoading ? (
                  <Skeleton height={30} />
                ) : (
                  <div className="manufaturer-Details-Contant">
                    <ul>
                      <li className="title">Full Name</li>
                      <li>
                        {userDetails?.f_name
                          ? userDetails?.f_name + " " + userDetails?.l_name
                          : "N/A"}
                      </li>
                    </ul>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                {isLoading ? (
                  <Skeleton height={30} />
                ) : (
                  <div className="manufaturer-Details-Contant">
                    <ul>
                      <li className="title">Company Name</li>
                      <li>
                        {userDetails?.company_name
                          ? userDetails?.company_name
                          : "N/A"}
                      </li>
                    </ul>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                {isLoading ? (
                  <Skeleton height={30} />
                ) : (
                  <div className="manufaturer-Details-Contant">
                    <ul>
                      <li className="title">Email Address</li>
                      <li>{userDetails?.email ? userDetails?.email : "N/A"}</li>
                    </ul>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                {isLoading ? (
                  <Skeleton height={30} />
                ) : (
                  <div className="manufaturer-Details-Contant">
                    <ul>
                      <li className="title">Address</li>
                      <li>
                        {userDetails?.address ? userDetails?.address : "N/A"}
                      </li>
                    </ul>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                {isLoading ? (
                  <Skeleton height={30} />
                ) : (
                  <div className="manufaturer-Details-Contant">
                    <ul>
                      <li className="title">Country</li>
                      <li>{getCountryNameById(userDetails?.country_id)}</li>
                    </ul>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                {isLoading ? (
                  <Skeleton height={30} />
                ) : (
                  <div className="manufaturer-Details-Contant">
                    <ul>
                      <li className="title">Mobile Number</li>
                      <li>{userDetails?.phno ? userDetails?.phno : "N/A"}</li>
                    </ul>
                  </div>
                )}
              </Grid>
              {userDetails?.user_document?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={3}>
                  {isLoading ? (
                    <Skeleton height={30} />
                  ) : (
                    <div className="manufaturer-Details-Contant">
                      <p className="title"> Document</p>
                      <div className="documentImage">
                        <img
                          src={DOCUMENTIMAGE}
                          alt="document"
                          className="document"
                        />
                        <Button
                          style={{
                            background: "rgba(70, 173, 242, 1)",
                            border: "none",
                            borderRadius: "10px",
                            padding: "20px",
                          }}
                          type="button"
                          onClick={handleDownloadDocument}
                        >
                          <FileDownloadIcon
                            sx={{
                              width: "40px",
                              height: "40px",
                              color: "#fff",
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>

            <Box sx={{ display: "flex", mt: "20px" }}>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                {isLoading ? (
                  <Skeleton height={30} />
                ) : (
                  <div className="manufaturer-Details-Contant">
                    <ul>
                      <li className="title">Account Status</li>
                      <li
                        style={{
                          padding: "2px 15px",
                          marginTop: "5px",

                          width: "120px",
                          textAlign: "center",
                          background:
                            userDetails?.status === 1 ? "green" : "red",
                          color: "#fff",
                          borderRadius:"10px"
                        }}
                      >
                        {" "}
                        {userDetails?.status === 1 ? "ACTIVE" : "INACTIVE"}
                      </li>
                    </ul>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                {isLoading ? (
                  <Skeleton height={30} />
                ) : (
                  <div className="manufaturer-Details-Contant">
                    <ul>
                      <li className="title">Request Status</li>
                      <li
                        style={{
                          padding: "2px 15px",
                          marginTop: "5px",

                          width: "120px",
                          textAlign: "center",
                          color: "#fff",
                          borderRadius:"10px",
                          background:
                            userDetails?.is_approve === 1
                              ? "green"
                              : userDetails?.is_approve === 0
                              ? "rgba(70, 173, 242, 1)"
                              : "red",
                        }}
                      >
                        {userDetails?.is_approve === 1
                          ? "Accepted"
                          : userDetails?.is_approve === 0
                          ? "Pending"
                          : "Rejected"}
                      </li>
                    </ul>
                  </div>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>

      <div className="PurchaseHistoryCardWrapper">
        <h6 className="title">Purchase History</h6>

        {PurchesHistory &&
          PurchesHistory?.map((el) => (
            <Row style={{ marginBottom: "20px" }}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="purchaseHistoryList">
                  <div className="product-image">
                    <img
                      src={
                        el?.cover_img ||
                        "https://roavebox.s3.amazonaws.com/product_cover/1708666745846-2%20%281%29.png"
                      }
                      alt="product"
                    />
                  </div>
                  <div className="product-Center-Details">
                    <div className="one">
                      <h6> {el?.name}</h6>
                      <span className="desctitlepurchese">
                        {" "}
                        <span>Purchase Date : </span>
                        {new Date(el?.updated_at).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                      </span>
                    </div>
                    <div className="two">
                      <p>{el?.description}</p>
                    </div>
                    <div className="three">
                      <h6>
                        <span className="desctitle">Price : </span> $
                        {el?.royalty_amount}
                      </h6>
                      <h6>
                        <span className="desctitle">Capacity : </span>{" "}
                        {el?.capacity}
                      </h6>
                      <h6>
                        <span className="desctitle">Size : </span> {el?.size}
                      </h6>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
      </div>
    </div>
  );
};

export default ManufaturerDetails;
