import React, { Fragment, useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FcGallery } from "react-icons/fc";
import { Col, Form, FormFeedback, Input, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { toast } from "react-toastify";
import {
  ADD_UPDATE_TITLE_MANAGER_API,
  GET_UPDATE_TITLE_MANAGER_API,
} from "../../../../Config/Api/Admin/TitleManagerApi";
import Skeleton from "react-loading-skeleton";
import "./TitleManager.scss";
const TitleManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [uploadPreview, setUploadPreview] = useState(null);
  const [uploadPreviewVideo, setUploadPreviewVideo] = useState(null);
  const [uploadPreviewFile, setUploadPreviewFile] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  useEffect(() => {
    getTitleManager();
  }, []);
  const getTitleManager = async () => {
    try {
      setIsLoading(true);
      const response = await GET_UPDATE_TITLE_MANAGER_API();
      if (response) {
        if (response?.s) {
          formik.setValues({
            title: response?.r?.title,
            description: response?.r?.description,
          });
          if (response?.r?.active_media === 1) {
            setUploadPreview(response?.r.image);
          } else {
            setUploadPreviewVideo(response?.r.video);
          }
          setIsEditMode(true);
        } else {
          toast.error(response.m);
        }
      }
    } catch (error) {
      toast.error(error.m);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUploadOnChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadPreviewFile(file);
      const formData = new FormData();
      if (file.type.includes("video")) {
        formData.append("active_media", 2);
        formData.append("video", file);
        formData.append("image", null);
        setUploadPreviewVideo(URL.createObjectURL(file));
        setUploadPreview(null);
        setMediaType(2);
      } else {
        formData.append("active_media", 1);
        formData.append("video", null);
        formData.append("image", file);
        setUploadPreview(URL.createObjectURL(file));
        setUploadPreviewVideo(null);
        setMediaType(1);
      }
      try {
        setIsLoading(true);
        const response = await ADD_UPDATE_TITLE_MANAGER_API(formData);
        if (response) {
          if (response?.s) {
            toast.success(response.m);
          } else {
            toast.error(response.m);
          }
        }
      } catch (error) {
        toast.error(error.m);
        setBusy(false);
        setIsLoading(false);
      } finally {
        setBusy(false);
        setIsLoading(false);
      }
    }
  };
  const handleDeleteMediaOnChange = (e) => {
    e.stopPropagation();
    setUploadPreview(null);
    setUploadPreviewVideo(null);
    if (!uploadPreview && !uploadPreviewVideo) {
      setMediaType(null);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      try {
        setIsLoading(true);
        const response = await ADD_UPDATE_TITLE_MANAGER_API(formData);
        if (response) {
          if (response?.s) {
            toast.success(response.m);
            getTitleManager();
          } else {
            toast.error(response.m);
          }
        }
      } catch (error) {
        toast.error(error.m);
        setBusy(false);
        setIsLoading(false);
      } finally {
        setBusy(false);
        setIsLoading(false);
      }
      resetForm();
    },
  });
  return (
    <Fragment>
      <div className="titleManager">
          <div className="titleContainer">
            {isLoading ? <Skeleton height={250}/> : <div className="titleManagerCard">
              <div className="headingSection">
                <h6>Main Heading</h6>
                {isEditMode && (
                  <button
                    className="editButton"
                    type="button"
                    onClick={() => setIsEditMode(false)}
                  >
                    <FaRegEdit fontSize={"18px"} />
                  </button>
                )}
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Heading"
                    name="title"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.title && formik.errors.title ? true : false
                    }
                    disabled={isEditMode}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <FormFeedback type="invalid">
                      {formik.errors.title}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <div className="inputGroupPasword">
                    <Input
                      rows={3}
                      style={{
                        resize: "none",
                      }}
                      type={"textarea"}
                      className="form-control"
                      placeholder="Description"
                      name="description"
                      value={formik.values.description}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.description && formik.errors.description
                          ? true
                          : false
                      }
                      disabled={isEditMode}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <FormFeedback type="invalid">
                        {formik.errors.description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="submitButton">
                  <CustomButton
                    type="submit"
                    className="submitBtn"
                    btn_title="Save"
                    loading={busy}
                    disabled={!(formik.isValid && formik.dirty && !isEditMode)}
                  />
                </div>
              </Form>
            </div>}
            <div className="imageVideoCard">
              <div className="headingSection">
                <h6>Select Image Banner Or Video</h6>
              </div>
              <div className="imageVideoBanner">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={4}>
                  {isLoading ? <Skeleton height={250}/> : <div className="uploadVideoImage">
                      <div className="uploadVideoImageHeight">
                        {uploadPreview && (
                          <img
                            src={uploadPreview}
                            alt="bannerPrev"
                            className="uploadPreview"
                          />
                        )}
                        {uploadPreviewVideo && (
                          <video
                            controls
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              position: "absolute",
                              borderRadius: "17px",
                              zIndex: -1,
                            }}
                          >
                            <source src={uploadPreviewVideo} type="video/mp4" />
                          </video>
                        )}
                        {!uploadPreview && !uploadPreviewVideo && (
                          <button type="button" className="uploadIcon">
                            <FcGallery />
                          </button>
                        )}
                        {(uploadPreview || uploadPreviewVideo) && (
                          <>
                            <button type="input" className="editButton">
                              <FaRegEdit />
                            </button>
                            <button
                              className="deleteButton"
                              onClick={(e) => {
                                handleDeleteMediaOnChange(e);
                              }}
                            >
                              <RiDeleteBin6Line />
                            </button>
                          </>
                        )}
                        <input
                          type="file"
                          accept="image/*,video/*"
                          style={{
                            display: "block",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: "0",
                            bottom: "0",
                            left: "0",
                            right: "0",
                            opacity: "0",
                            cursor: "pointer",
                          }}
                          onChange={(e) => handleUploadOnChange(e)}
                        />
                      </div>
                    </div>}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
      </div>
    </Fragment>
  );
};

export default TitleManager;
