import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAJtaxud4fYZtlK-pDbOHCUJOpJQg-BnXs",
  authDomain: "roavebox-notification.firebaseapp.com",
  projectId: "roavebox-notification",
  storageBucket: "roavebox-notification.appspot.com",
  messagingSenderId: "955265680110",
  appId: "1:955265680110:web:e860e08920ef0671e969ff"
};
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
