import AxiosInstance from "../../../AxiosInstance";

export const GET_ORDER_DETAILS = async (id) => {
    try {
     
      const response = await AxiosInstance.get(
        AxiosInstance.defaults.baseURL +`/order/get-order-details?order_id=${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  