import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Container, Form, FormFeedback, Input, Row } from "reactstrap";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import "./Notification.scss";
import { SEND_NOTIFICATION } from "../../../Config/Api/Notification/Notification";
import { toast } from "react-toastify";
const Notification = () => {
  const [busy, setBusy] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This Field Is Required"),
      description: Yup.string().required("This Field Is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setBusy(true);
      if (busy) {
        return;
      } else {
        try {
          const formData = new FormData();
          formData.append("title", values.title);
          formData.append("message", values.description);
          const response = await SEND_NOTIFICATION(formData);
          if (response?.s === 1 && response) {
            toast.success(response?.m);
          } else {
            toast.error(response?.m);
          }
        } catch (error) {
          console.log("error", error);
        } finally {
          setBusy(false);
          resetForm();
        }
      }
    },
  });
  return (
    <Container fluid>
      <div className="notification">
        <h5 className="title">Notification</h5>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="mb-3"
                >
                  <CustomTextField
                    type="text"
                    placeholder="Notification Title"
                    name="title"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    onKeyUp={formik.handleBlur}
                    maxLength={350}
                    invalid={formik.touched.title && formik.errors.title}
                    errorMessage={formik.errors.title}
                    rows={5}
                    style={{
                      padding: "16px 40px 16px 15px",
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="mb-3"
                >
                  <CustomTextField
                    type="textarea"
                    placeholder="Description"
                    name="description"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    onKeyUp={formik.handleBlur}
                    maxLength={350}
                    invalid={
                      formik.touched.description && formik.errors.description
                    }
                    errorMessage={formik.errors.description}
                    rows={5}
                    style={{
                      padding: "16px 40px 16px 15px",
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <div className="sendMessageButton">
                    <CustomButton
                      type="submit"
                      className="submitBtn"
                      btn_title="Send"
                      loading={busy}
                      disabled={!(formik.dirty && formik.isValid)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default Notification;
