import AxiosInstance from "../../AxiosInstance";
export const ADD_UPDATE_CONTACT_MANAGER_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/web-management/update-contact', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const GET_CONTACT_MANAGER_API = async () => {
    try {
        const response = await AxiosInstance.get(AxiosInstance.defaults.baseURL + '/web-management/get-contact?id=1');
        return response.data;
    } catch (error) {
        throw error;
    }
};