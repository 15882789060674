import AxiosInstance from '../AxiosInstance';
export const GET_FAQ_API = async (payload) => {
    try {
      const response = await AxiosInstance.get(
        AxiosInstance.defaults.baseURL +
          `/web-management/get-faq?count=${payload}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };