import React, { Fragment, useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { toast } from "react-toastify";
import "./HelpSuport.scss";
import {
  ADD_HELP_SUPPORT_API,
  DELETE_HELP_SUPPORT_API,
  GET_HELP_SUPPORT_API,
  UPDATE_HELP_SUPPORT_API,
} from "../../../../Config/Api/Admin/HelpSupportApi";
import Skeleton from "react-loading-skeleton";
const HelpSuport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [typeCheck, setTypeCheck] = useState("add");
  const [editFAQid, setEditFAQId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 0; // Number of items per page

  useEffect(() => {
    loadFaqList();
  }, [currentPage]);

  const loadFaqList = async () => {
    setIsLoading(true);
    try {
      const response = await GET_HELP_SUPPORT_API(countPerPage, currentPage);
      if (response && response.s) {
        setFaqList(response.r);
      } else {
        toast.error(response.m);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteHelpSupport = async (id) => {
    try {
      const response = await DELETE_HELP_SUPPORT_API({ id: id });
      if (response && response.s) {
        toast.success(response?.m)
        setFaqList(response.r);
        loadFaqList();
      } else {
        toast.error(response.m);
      }
    } catch (error) {
      toast.error(error.m);
    }
  };

  const handleEditHelpSupport = (id) => {
    setTypeCheck("edit");
    setEditFAQId(id);
    const editHelpSupport = faqList?.find((elem) => id === elem?.id);
    formik.setValues({
      question: editHelpSupport?.question,
      answer: editHelpSupport?.answer,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required("question address is required"),
      answer: Yup.string().required("answer is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (typeCheck === "edit") {
        const formData = new FormData();
        formData.append("question", values.question);
        formData.append("answer", values.answer);
        formData.append("id", editFAQid);
        try {
          setIsLoading(true);
          const response = await UPDATE_HELP_SUPPORT_API(formData);
          if (response && response.s) {
            toast.success(response.m);
            loadFaqList();
            setTypeCheck("add");
          } else {
            toast.error(response.m);
          }
        } catch (error) {
          toast.error(error.m);
        } finally {
          setIsLoading(false);
        }
        resetForm();
      } else {
        const formData = new FormData();
        formData.append("question", values.question);
        formData.append("answer", values.answer);
        try {
          setIsLoading(true);
          const response = await ADD_HELP_SUPPORT_API(formData);
          if (response && response.s) {
            toast.success(response.m);
            loadFaqList();
            setTypeCheck("add");
          } else {
            toast.error(response.m);
          }
        } catch (error) {
          toast.error(error.m);
        } finally {
          setIsLoading(false);
        }
        resetForm();
      }
    },
  });

  const totalItems = faqList?.length;
  const totalPages = Math.ceil(totalItems / countPerPage);

  return (
    <Fragment>
      <div className="helpSupportManager">
        <>
          <div className="titleContainer">
            <div className="titleManagerCard">
              <div className="headingSection">
                <h6>FAQ’s</h6>
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Question"
                    name="question"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.question}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.question && formik.errors.question
                        ? true
                        : false
                    }
                  />
                  {formik.touched.question && formik.errors.question ? (
                    <FormFeedback type="invalid">
                      {formik.errors.question}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <div className="inputGroupPasword">
                    <Input
                      rows={3}
                      style={{
                        resize: "none",
                      }}
                      type={"textarea"}
                      className="form-control"
                      placeholder="Answer"
                      name="answer"
                      value={formik.values.answer}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.answer && formik.errors.answer
                          ? true
                          : false
                      }
                    />
                    {formik.touched.answer && formik.errors.answer ? (
                      <FormFeedback type="invalid">
                        {formik.errors.answer}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="submitButton">
                  <CustomButton
                    type="submit"
                    className="submitBtn"
                    btn_title="Save"
                    disabled={!(formik.isValid && formik.dirty)}
                  />
                </div>
              </Form>
            </div>
          </div>
          <div className="faqListingCard">
            <div className="headingSection">
              <h6>Your History</h6>
            </div>
            {isLoading ? (
              <Row>
                {[...Array(5)].map((_, index) => (
                  <Col
                    lg={12}
                    sm={12}
                    xxl={12}
                    xl={12}
                    xs={12}
                    md={12}
                    key={index}
                  >
                    <div
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "6px",
                        padding: "20px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <Skeleton width={"90%"} height={20} />
                    </div>
                  </Col>
                ))}
              </Row>
            ) : (
              <Row>
                {faqList?.map((elem, index) => (
                  <Col
                    lg={12}
                    sm={12}
                    xxl={12}
                    xl={12}
                    xs={12}
                    md={12}
                    key={index}
                  >
                    <div
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "6px",
                        padding: "20px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <h6
                        variant="h6"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000",
                          fontFamily: "Poppins",
                          paddingRight: "100px",
                          margin: "0px",
                        }}
                      >
                        {elem?.question}
                      </h6>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gridGap: "5px",
                        }}
                      >
                        <button
                          className="editButton"
                          onClick={() => handleEditHelpSupport(elem?.id)}
                          style={{
                            outline: "none",
                            border: "none",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                            width: "30px",
                            height: "30px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FaRegEdit fontSize={"18px"} />
                        </button>
                        <button
                          className="deleteButton"
                          onClick={() => handleDeleteHelpSupport(elem?.id)}
                          style={{
                            outline: "none",
                            border: "none",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                            width: "30px",
                            height: "30px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <RiDeleteBin6Line fontSize={"18px"} color="red" />
                        </button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </>
      </div>
    </Fragment>
  );
};
export default HelpSuport;
