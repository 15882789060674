import React, { Fragment, useState, useEffect } from "react";
import { Form, FormFeedback, Input } from "reactstrap";
import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Yup from "yup";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import Rating from "react-rating";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import UPLOAD_GALLERY_ICON from "../../../../Assets/Images/gallery.png";
import { toast } from "react-toastify";
import "./Testimonials.scss";
import Skeleton from "react-loading-skeleton";
import {
  ADD_TESTIMONIALS_MANAGER_API,
  DELETE_TESTIMONIALS_MANAGER_API,
  GET_TESTIMONIALS_API,
  UPDATE_TESTIMONIALS_MANAGER_API,
} from "../../../../Config/Api/Admin/Testimonials/Testimonials";
import Swal from "sweetalert2";
const Testimonials = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [testimonialList, setTestimonialList] = useState([]);
  const [coverImageUploadPreview, setCoverImageUploadPreview] = useState(null);
  const [coverImageUploadFile, setCoverImageUploadFile] = useState(null);
  const [ratingValue, setRatingValue] = useState(0);
  const [typeCheck, setTypeCheck] = useState(null);
  const [editTestimonialsId, setEditTestimonialsId] = useState(null);
  const handleCoverImageOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const imageUrl = URL.createObjectURL(file);
        setCoverImageUploadFile(file);
        setCoverImageUploadPreview(imageUrl);
      } else {
        toast.error(`File size exceeds the limit of 5MB`);
      }
    }
  };
  useEffect(() => {
    getTestimonials();
  }, []);
  const getTestimonials = async () => {
    try {
      setIsLoading(true);
      const response = await GET_TESTIMONIALS_API();
      if (response && response.s === 1) {
        setTestimonialList(response?.r);
        setIsLoading(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: response?.m,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const handleDeleteTestimonials = async (id) => {
    try {
      const response = await DELETE_TESTIMONIALS_MANAGER_API({
        testimonial_id: id,
        is_delete: 0,
      });
      if (response && response.s === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          title: "Delete",
          text: response?.m,
        });
        getTestimonials();
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: response?.m,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleEditTestimonials = (id) => {
    setTypeCheck("edit");
    setEditTestimonialsId(id);
    const editTestimonial = testimonialList?.find((elem) => id === elem?.id);
    if (editTestimonial) {
      formik.setValues({
        customersName: editTestimonial?.name,
        description: editTestimonial?.review,
      });
      if (editTestimonial?.customer_img) {
        const imageUrl = editTestimonial.customer_img;
        setCoverImageUploadPreview(imageUrl);
      }

      if (editTestimonial?.rating) {
        setRatingValue(editTestimonial?.rating);
      }
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customersName: "",
      description: "",
    },
    validationSchema: Yup.object({
      customersName: Yup.string().required("Customers Name is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) return;
      if (typeCheck === "edit") {
        try {
          const formData = new FormData();
          formData.append("testimonial_id", editTestimonialsId);
          formData.append("name", values.customersName);
          formData.append("review", values.description);
          if (coverImageUploadFile) {
            formData.append("customer_img", coverImageUploadFile);
          }
          formData.append("rating", ratingValue);
          const response = await UPDATE_TESTIMONIALS_MANAGER_API(formData);
          if (response && response?.s === 1) {
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response?.m,
            });
            getTestimonials();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
              title: "error",
              text: response?.m,
            });
          }
          resetForm();
          setCoverImageUploadPreview(null);
          setCoverImageUploadFile(null);
          setRatingValue(0);
          setIsLoading(false);
          setTypeCheck("add");
        } catch (error) {
          console.log("error", error);
          setCoverImageUploadPreview(null);
          setCoverImageUploadFile(null);
          setRatingValue(0);
          setIsLoading(false);
        }
      } else {
        try {
          const formData = new FormData();
          formData.append("name", values.customersName);
          formData.append("review", values.description);
          formData.append("customer_img", coverImageUploadFile);
          formData.append("rating", ratingValue);
          const response = await ADD_TESTIMONIALS_MANAGER_API(formData);
          if (response && response?.s === 1) {
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response?.m,
            });
            getTestimonials();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
              title: "error",
              text: response?.m,
            });
          }
          resetForm();
          setCoverImageUploadPreview(null);
          setCoverImageUploadFile(null);
          setRatingValue(0);
          setIsLoading(false);
          setTypeCheck("add");
        } catch (error) {
          console.log("error", error);
          setCoverImageUploadPreview(null);
          setCoverImageUploadFile(null);
          setRatingValue(0);
          setIsLoading(false);
          setTypeCheck("add");
        }
      }
    },
  });
  return (
    <Fragment>
      <div className="helpSupportManager">
        <>
          <div className="titleContainer">
            <div className="titleManagerCard">
              <div className="headingSection">
                <h6>Testimonials</h6>
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "150px",
                    overflow: "hidden",
                    background: "#ffffff",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  {!coverImageUploadPreview && (
                    <label htmlFor="cover-upload">
                      <Button
                        component="span"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          outline: "none",
                          border: "none",
                          backgroundColor: "transparent",
                          textTransform: "capitalize",
                        }}
                      >
                        <img
                          src={UPLOAD_GALLERY_ICON}
                          alt="Upload Icon"
                          effect="blur"
                          width={"42px"}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000000",
                            margin: "8px 0px !important",
                          }}
                        >
                          Customers Image
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#9C9C9C",
                          }}
                        >
                          {`(JPG,JPEG,PNG)`}
                        </Typography>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-upload"
                          type="file"
                          onChange={handleCoverImageOnChange}
                        />
                      </Button>
                    </label>
                  )}
                  {coverImageUploadPreview && (
                    <Box>
                      <img
                        src={coverImageUploadPreview}
                        alt="Uploaded Image"
                        effect="blur"
                        height={"150px"}
                        width={"150px"}
                      />
                      <label htmlFor="cover-upload">
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                        >
                          <EditIcon
                            sx={{
                              color: "#46ADF2",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-upload"
                          type="file"
                          onChange={handleCoverImageOnChange}
                        />
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "50px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCoverImageUploadPreview(null);
                            setCoverImageUploadFile(null);
                          }}
                        >
                          <CloseIcon
                            sx={{
                              color: "#46ADF2",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                      </label>
                    </Box>
                  )}
                </Box>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Customers Name"
                    name="customersName"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.customersName}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.customersName &&
                      formik.errors.customersName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.customersName &&
                  formik.errors.customersName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.customersName}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <div className="inputGroupPasword">
                    <Input
                      rows={3}
                      style={{
                        resize: "none",
                      }}
                      type={"textarea"}
                      className="form-control"
                      placeholder="Write Your Review Here"
                      name="description"
                      value={formik.values.description}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.description && formik.errors.description
                          ? true
                          : false
                      }
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <FormFeedback type="invalid">
                        {formik.errors.description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <div className="inputGroupRating">
                    <Rating
                      emptySymbol={
                        <StarOutlineIcon
                          sx={{ fontSize: "32px", color: "gold" }}
                        />
                      }
                      fullSymbol={
                        <StarIcon sx={{ fontSize: "32px", color: "gold" }} />
                      }
                      fractions={2}
                      size={6}
                      initialRating={ratingValue}
                      onChange={(value) => setRatingValue(value)}
                    />
                  </div>
                </div>
                <div className="submitButton">
                  <CustomButton
                    type="submit"
                    className="submitBtn"
                    btn_title={typeCheck === "edit" ? "Update" : "Save"}
                    disabled={
                      !(
                        formik.isValid &&
                        formik.dirty &&
                        coverImageUploadPreview &&
                        ratingValue
                      )
                    }
                  />
                </div>
              </Form>
            </div>
          </div>
          <div className="faqListingCard">
            <div className="headingSection">
              <h6>Customer's Review History</h6>
            </div>
            {isLoading ? (
              <Grid container spacing={3}>
                {[...Array(5)].map((_, index) => (
                  <Grid
                    item
                    lg={3}
                    sm={2}
                    xxl={3}
                    xl={3}
                    xs={12}
                    md={3}
                    key={index}
                  >
                    <div
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "6px",
                        padding: "20px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <Skeleton width={"90%"} height={150} />
                    </div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container spacing={3}>
                {testimonialList?.length > 0 ? (
                  testimonialList?.map((elem, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                      <Box
                        sx={{
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                          backgroundColor: "#ffffff",
                          padding: "20px",
                          borderRadius: "8px",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            border: "1px solid #dddddd",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "50px",
                              height: "50px",
                              margin: "0 auto",
                            }}
                          >
                            <img
                              src={elem?.customer_img}
                              alt="testimonialImage"
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                objectFit: "cover",
                                objectPosition: "top",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              gridGap: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                fontFamily: "Poppins",
                                color: "#000000",
                                textAlign: "center",
                              }}
                            >
                              {elem?.review}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                                fontFamily: "Poppins",
                                color: "#000000",
                                textAlign: "center",
                              }}
                            >
                              {elem?.name}
                            </Typography>
                          </Box>
                          <Rating
                            emptySymbol={
                              <StarOutlineIcon
                                sx={{ fontSize: "22px", color: "gold" }}
                              />
                            }
                            fullSymbol={
                              <StarIcon
                                sx={{ fontSize: "22px", color: "gold" }}
                              />
                            }
                            size={6}
                            initialRating={elem?.rating}
                            readonly={true}
                          />
                          <IconButton
                            sx={{
                              outline: "none",
                              border: "none",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                              width: "30px",
                              height: "30px",
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "absolute",
                              top: "5px",
                              right: "40px",
                            }}
                            onClick={() => handleEditTestimonials(elem?.id)}
                          >
                            <EditIcon
                              sx={{
                                fontSize: "28px",
                                color: "#000000",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            sx={{
                              outline: "none",
                              border: "none",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                              width: "30px",
                              height: "30px",
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                            }}
                            onClick={() => handleDeleteTestimonials(elem?.id)}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: "28px",
                                color: "red",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#000000",
                      width:'100%',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'center',
                      padding:'50px 0px'
                    }}
                  >
                    No Testimonials Found
                  </Box>
                )}
              </Grid>
            )}
          </div>
        </>
      </div>
    </Fragment>
  );
};
export default Testimonials;
