import AxiosInstance from "../../AxiosInstance";

export const GET_ALL_USER_API = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + "/user/get-all",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UPDATE_USER_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/user/update",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_USER_API_ID = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + `/user/get-by-id?user_id=${payload}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const UPDATE_USER_STATUS = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + `/user/account-status`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};