import axios from "axios";
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
AxiosInstance.interceptors.request.use(
  function (config) {
    const roaveBox = JSON.parse(localStorage.getItem("RoaveBox"));
    config.headers.token = roaveBox?.token;
    config.headers.apikey = roaveBox?.apikey;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      console.error("Response Error:", error.response.status);
    } else if (error.request) {
      console.error("Request Error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
