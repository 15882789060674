import React, { Fragment, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import TitleManager from "./TitleManager/TitleManager";
import ContactManager from "./ContactManager/ContactManager";
import HelpSupport from "./HelpSupport/HelpSuport";
import "./WebsiteManager.scss";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./TermsCondition/TermsCondition";
import Values from "./Values/Values";
import CodeOfConduct from "./CodeOfConduct/CodeOfConduct";
import Testimonials from "./Testimonials/Testimonials";

const WebsiteManager = () => {
  const [activeTab, setActiveTab] = useState("tabsTitle");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Fragment>
      <div className="webSiteManager">
        <div className="titleSection">
          <h4 className="title">Website Manager</h4>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "tabsTitle" ? "active" : ""}
              onClick={() => toggleTab("tabsTitle")}
            >
              Title Manager
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "contactManager" ? "active" : ""}
              onClick={() => toggleTab("contactManager")}
            >
              Contact Manager
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "helpSupport" ? "active" : ""}
              onClick={() => toggleTab("helpSupport")}
            >
              Help/Support
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "privacyPolicy" ? "active" : ""}
              onClick={() => toggleTab("privacyPolicy")}
            >
              Privacy policy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "testimonials" ? "active" : ""}
              onClick={() => toggleTab("testimonials")}
            >
              Testimonials
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "terms&Condition" ? "active" : ""}
              onClick={() => toggleTab("terms&Condition")}
            >
              Terms & Condition
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "values" ? "active" : ""}
              onClick={() => toggleTab("values")}
            >
              Values
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "codeOfConduct" ? "active" : ""}
              onClick={() => toggleTab("codeOfConduct")}
            >
              Code Of Conduct
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="tabsTitle">
            <TitleManager />
          </TabPane>
          <TabPane tabId="contactManager">
            <ContactManager />
          </TabPane>
          <TabPane tabId="helpSupport">
            <HelpSupport />
          </TabPane>
          <TabPane tabId="privacyPolicy">
            <PrivacyPolicy />
          </TabPane>
          <TabPane tabId="testimonials">
            <Testimonials />
          </TabPane>
          <TabPane tabId="terms&Condition">
            <TermsCondition />
          </TabPane>
          <TabPane tabId="values">
            <Values />
          </TabPane>
          <TabPane tabId="codeOfConduct">
            < CodeOfConduct/>
          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  );
};

export default WebsiteManager;
