import AxiosInstance from "../AxiosInstance";

export const ADD_DOCUMENT_API = async (payload) => {
    try {
      const response = await AxiosInstance.post(
        AxiosInstance.defaults.baseURL + "/user/add-document",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const UPDATE_DOCUMENT_API = async (payload) => {
    try {
      const response = await AxiosInstance.post(
        AxiosInstance.defaults.baseURL + "/user/ducoment-update",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const GET_DOCUMENT_API = async (payload) => {
    try {
      const response = await AxiosInstance.get(
        AxiosInstance.defaults.baseURL + `/user/get-document?user_id=${payload}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };