import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  CardImg,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaCamera } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import CustomDropdown from "../../../Components/CustomDropdown/CustomDropdown";
import LOGO from "../../../Assets/Images/logo1.png";
import {
  CITY_LIST_API,
  COUNTRY_LIST_API,
  SIGNUP_API,
  STATE_LIST_API,
} from "../../../Config/Api/AuthApi";
import { ADD_DOCUMENT_API } from "../../../Config/Api/DocumentApi";
import "./SignUp.scss";
const SignUp = () => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectState, setSelectState] = useState(null);
  const [selectCity, setSelectCity] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [userProfile, setUserProfile] = useState(null);
  const [documentDataName, setDocumentDataName] = useState(null);
  const [rememberMe, setRememberMe] = useState(
    window.localStorage.getItem("SignUpRememberMe") === "true"
  );
  useEffect(() => {
    if (rememberMe) {
      const storedData = window.localStorage.getItem("SignUpData");
      if (storedData) {
        const formData = JSON.parse(storedData);
        formik.setValues(formData);
      }
    }
  }, [rememberMe]);
  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        formik.setFieldValue("profile_img", file);
        setUserProfile(URL.createObjectURL(file));
      } else {
        toast.error("Selected file exceeds 5 MB limit.");
      }
    }
  };
  const handleDeleteProfile = (e) => {
    e.preventDefault();
    setUserProfile(null);
    const fileInput = document.querySelector(".fileUploadInput");
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        formik.setFieldValue("document", file);
        setDocumentDataName(file.name);
      } else {
        toast.error("Selected file exceeds 5 MB limit.");
      }
    }
  };
  const handleDeleteDocument = (e) => {
    e.preventDefault();
    setDocumentDataName(null);
    const fileInput = document.querySelector(".uploadDocument");
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const handleRememberMeChange = (e) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);
    window.localStorage.setItem(
      "SignUpRememberMe",
      isChecked ? "true" : "false"
    );
  };
  useEffect(() => {
    const getCountryList = async () => {
      try {
        const response = await COUNTRY_LIST_API();
        if (response && response.s === 1) {
          const countries = response.r;
          setCountryList(countries);
        }
      } catch (error) {
        console.error("Error fetching Country list:", error);
      }
    };

    getCountryList();
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      getCountryStateList(selectedCountry.id);
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectState) {
      getStateCityList(selectState.id);
    }
  }, [selectState]);
  const getCountryStateList = async (countryId) => {
    try {
      const response = await STATE_LIST_API({ country_id: countryId });
      if (response && response.s === 1) {
        const states = response.r;
        setStateList(states);
      }
    } catch (error) {
      console.error("Error fetching State list:", error);
    }
  };
  const getStateCityList = async (stateId) => {
    try {
      const response = await CITY_LIST_API({ state_id: stateId });
      if (response && response.s === 1) {
        const states = response.r;
        setCityList(states);
      }
    } catch (error) {
      console.error("Error fetching City list:", error);
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
      phno_cc: "",
      confirmPassword: "",
      countryId: "",
      stateId: "",
      cityId: "",
      companyName: "",
      companyAddress: "",
      document: null,
      profile_img: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]*$/, "First Name must contain only letters")
        .min(3, "First Name must be at least 3 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[A-Za-z ]*$/, "Last Name must contain only letters")
        .min(3, "Last Name must be at least 3 characters")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Email address is required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password must be at most 20 characters"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      countryId: Yup.string().required("Country is required"),
      companyName: Yup.string()
        .min(3, "Company Name must be at least 3 characters")
        .required("Company Name is required"),
      companyAddress: Yup.string()
        .min(3, "Company Address must be at least 3 characters")
        .required("Company Address is required"),
    }),
    onSubmit: async (values) => {
      setBusy(true);
      if (busy) {
        return;
      } else {
        const formData = new FormData();
        formData.append("f_name", values.firstName);
        formData.append("l_name", values.lastName);
        formData.append("email", values.email);
        formData.append("phno_cc", values.phno_cc);
        formData.append("phno", values.phoneNumber);
        formData.append("password", values.password);
        formData.append("company_name", values.companyName);
        formData.append("address", values.companyAddress);
        formData.append("country_id", values.countryId);
        formData.append("state_id", values.stateId);
        formData.append("city_id", values.cityId);
        formData.append("profile_img", values.profile_img);
        try {
          const signupResponse = await SIGNUP_API(formData);
          if (signupResponse?.s === 1) {
            toast.success(signupResponse.m);
            window.localStorage.setItem(
              "RoaveBox",
              JSON.stringify(signupResponse?.r)
            );
            if (signupResponse?.r?.id) {
              const userId = signupResponse?.r?.id;
              const documentFormData = new FormData();
              documentFormData.append("document", values.document);
              documentFormData.append("user_id", userId);
              const addDocumentResponse = await ADD_DOCUMENT_API(
                documentFormData
              );
              if (addDocumentResponse?.s) {
              } else {
                toast.error(addDocumentResponse.m);
              }
            }
            navigate("/manufactur/dashboard");
          } else {
            toast.error(signupResponse.m);
          }
        } catch (error) {
          toast.error(error.m);
          setBusy(false);
        } finally {
          setBusy(false);
        }
      }
    },
  });
  return (
    <div className="signUpWrapper">
      <div className="signCard">
        <Row className="justify-content-center align-items-end">
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
            className="mediamSize"
          >
            <p className="mainTitle">
              The right decisions. Innovative solutions.
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <div
                    className="signHeader"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CardImg
                      alt="logo"
                      src={LOGO}
                      style={{
                        width: "204px",
                      }}
                    />
                  </div>
                  <div className="uploadUserProfile">
                    <div className="userProfilePrev">
                      {userProfile && (
                        <CardImg
                          alt="logo"
                          src={userProfile}
                          style={{
                            width: "140px",
                            height: "140px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        className="fileUploadInput"
                        name="userProfile"
                        onChange={(e) => handleProfileChange(e)}
                      />
                      {userProfile ? (
                        <button
                          type="button"
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            zIndex: "2",
                            outline: "none",
                            border: "none",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                            width: "30px",
                            height: "30px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={handleDeleteProfile}
                        >
                          <MdClose color="#000" />
                        </button>
                      ) : (
                        <Button type="button" className="editBtn">
                          <FaCamera />
                        </Button>
                      )}
                    </div>
                    <p>Set your profile</p>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <Input
                    type="text"
                    className="form-control allInputCommon"
                    placeholder="First Name"
                    name="firstName"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.firstName && formik.errors.firstName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3 "
                >
                  <Input
                    type="text"
                    className="form-control allInputCommon"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.lastName && formik.errors.lastName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <Input
                    type="text"
                    className="form-control allInputCommon"
                    placeholder="Email Address"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                    autoComplete="new-password" // To bypass some autofill mechanisms
                    autoCorrect="off" // To disable autocorrection
                    autoCapitalize="none" // To disable autocapitalization
                    spellCheck="false" // To disable spellcheck
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormFeedback type="invalid">
                      {formik.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <PhoneInput
                    name="phoneNumber"
                    country={"in"}
                    placeholder="Enter Mobile Number"
                    value={formik.values.phno_cc + formik.values.phoneNumber}
                    onChange={(value, country) => {
                      const countryCodeLength = country.dialCode.length;
                      const phno_cc = value.slice(0, countryCodeLength);
                      const phno = value.slice(countryCodeLength);
                      formik.setFieldValue("phoneNumber", phno);
                      formik.setFieldValue("phno_cc", phno_cc);
                    }}
                    inputClass="inputClass"
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <div className="inputGroupPasword">
                    <Input
                      type={showPassword ? "text" : "password"}
                      className="form-control allInputCommon"
                      placeholder="Password"
                      name="password"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.password && formik.errors.password
                          ? true
                          : false
                      }
                      style={{
                        padding: "15px 50px 15px 16px",
                      }}
                    />
                    <Button
                      aria-label="show"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <BsEyeSlash /> : <BsEye />}
                    </Button>
                    {formik.touched.password && formik.errors.password ? (
                      <FormFeedback type="invalid">
                        {formik.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <div className="inputGroupPasword">
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control allInputCommon"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      onKeyUp={formik.handleBlur}
                      invalid={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                          ? true
                          : false
                      }
                      style={{
                        padding: "15px 50px 15px 16px",
                      }}
                      autoComplete="nope"
                    />
                     <Button
                      aria-label="show"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                    </Button>
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <FormFeedback type="invalid">
                        {formik.errors.confirmPassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <Input
                    type="text"
                    className="form-control allInputCommon"
                    placeholder="Company Name"
                    name="companyName"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.companyName && formik.errors.companyName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.companyName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <Input
                    type="text"
                    className="form-control allInputCommon"
                    placeholder="Company Address"
                    name="companyAddress"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyAddress}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.companyAddress &&
                      formik.errors.companyAddress
                        ? true
                        : false
                    }
                  />
                  {formik.touched.companyAddress &&
                  formik.errors.companyAddress ? (
                    <FormFeedback type="invalid">
                      {formik.errors.companyAddress}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <CustomDropdown
                    selectedValue={selectedCountry}
                    dropDownItems={countryList?.map((c, i) => ({
                      id: c?.id,
                      value: c?.name,
                      label: c?.name,
                      key: i,
                    }))}
                    placeholder={"Select Country"}
                    onSelect={(evt) => {
                      setSelectedCountry(evt);
                      formik.setFieldValue("countryId", evt.id);
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <CustomDropdown
                    selectedValue={selectState}
                    dropDownItems={stateList?.map((s, i) => ({
                      id: s?.id,
                      value: s?.name,
                      label: s?.name,
                      key: i,
                    }))}
                    placeholder={"Select State"}
                    onSelect={(evt) => {
                      console.log("evt", evt);
                      setSelectState(evt);
                      formik.setFieldValue("stateId", evt.id);
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <CustomDropdown
                    selectedValue={selectCity}
                    dropDownItems={cityList?.map((c, i) => ({
                      id: c?.id,
                      value: c?.name,
                      label: c?.name,
                      key: i,
                    }))}
                    placeholder={"Select City"}
                    onSelect={(evt) => {
                      setSelectCity(evt);
                      formik.setFieldValue("cityId", evt.id);
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={4}
                  className="mb-3"
                >
                  <div className="identityUpload">
                    <div className="uploadIconBox">
                      <MdOutlineFileUpload fontSize={"24px"} />
                      {documentDataName ? (
                        <p className="text">{documentDataName}</p>
                      ) : (
                        <p className="text">Identity</p>
                      )}
                      <input
                        type="file"
                        accept=".pdf,.psd,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        className="uploadDocument"
                        name="document"
                        onChange={(e) => handleDocumentChange(e)}
                      />
                      {documentDataName && (
                        <button
                          type="button"
                          className="editButton"
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            outline: "none",
                            border: "none",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                            width: "30px",
                            height: "30px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FaRegEdit fontSize={"18px"} />
                        </button>
                      )}
                      {documentDataName && (
                        <button
                          type="button"
                          className="deleteButton"
                          onClick={handleDeleteDocument}
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "40px",
                            zIndex: "2",
                            outline: "none",
                            border: "none",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                            width: "30px",
                            height: "30px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <RiDeleteBin6Line fontSize={"18px"} color="red" />
                        </button>
                      )}
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={12}
                  xxl={12}
                  className="mb-3"
                >
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="auth-remember-check"
                      value={rememberMe}
                      onChange={(e) => {
                        handleRememberMeChange(e);
                      }}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="auth-remember-check"
                    >
                      I Agree to all the terms and Condition and Privacy Policy
                    </Label>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <div className="submitButton">
                    <CustomButton
                      type="submit"
                      className="submitBtn"
                      btn_title="Sign Up"
                      loading={busy}
                      disabled={
                        !(
                          formik.dirty &&
                          formik.isValid &&
                          documentDataName &&
                          userProfile
                        )
                      }
                    />
                    <NavLink
                      to={"/login"}
                      onClick={() => navigate("/login")}
                      className="allReady"
                    >
                      Have an account? Log In
                    </NavLink>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SignUp;
