import { Box, IconButton } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { toast } from "react-toastify";
import {
  DELETE_PRODUCT_API,
  GET_ALL_PRODUCT_API,
} from "../../../Config/Api/Admin/Product/ProductApi";
import useDebounce from "../../../Components/useDebounce/useDebounce";
const Product = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchProduct, setSearchProduct] = useState("");
  const [productList, setProductList] = useState([]);
  const debouncedSearch = useDebounce(searchProduct, 400);
  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (d) => `${d.id}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">{Number(row.row.id) + 1}</div>
          );
        },
      },
      {
        Header: "Product",
        accessor: (d) => `${d.cover_img}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              <img
                srcSet={row.row.original.cover_img}
                alt="profilephoto"
                width={"45px"}
                height={"45px"}
                style={{ objectFit: "cover" }}
              />
            </div>
          );
        },
      },
      {
        Header: "Product Name",
        accessor: (d) => `${d.name}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">{row.row.original.name}</div>
          );
        },
      },
      {
        Header: "Royalty Amount",
        accessor: (d) => `${d.royalty_amount}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row.row.original.royalty_amount}
            </div>
          );
        },
      },
      {
        Header: "Action",
        Cell: (row) => {
          return (
            <Box sx={{ display: "flex", justifyContent: "center",gap:"10px" }}>
              <IconButton
              sx={{
                border: "none",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "3",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              }}
                aria-label="view"
                onClick={() =>
                  navigate("/admin/product/update-product", {
                    state: {
                      editProductData: row?.row?.original,
                      pId: row?.row?.original?.id,
                    },
                  })
                }
              >
               <IoEyeOutline fontSize={"18px"} color="#46ADF2" />
              </IconButton>
              <IconButton
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteProduct(row.row.original.id);
                }}
              >
               <RiDeleteBin6Line fontSize={"18px"} color="red" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, []);
  useEffect(() => {
    getAllProduct(0);
  }, [debouncedSearch]);

  const getAllProduct = async (count) => {
    try {
      setIsLoading(true);
      const response = await GET_ALL_PRODUCT_API({
        params: {
          count,
          search: debouncedSearch,
        },
      });
      if (response) {
        if (response?.s) {
          setProductList(prevList => count === 0 ? response?.r : [...prevList, ...response?.r]);
        } else {
          toast.error(response.m);
        }
      }
    } catch (error) {
      toast.error(error.m);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsLoading(true);
      const response = await DELETE_PRODUCT_API(productId);
      if (response) {
        if (response?.s) {
          getAllProduct(0);
        } else {
          toast.error(response.m);
        }
      }
    } catch (error) {
      toast.error(error.m);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6
          style={{
            fontSize: "32px",
            fontWeight: "500",
          }}
          variant="h4"
        >
          Product Management
        </h6>
        <button
          type="button"
          onClick={() => navigate("/admin/product/add-product")}
          style={{
            backgroundColor: "#46ADF2",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "capitalize",
            padding: "12px 28px",
            outline: "none",
            border: "none",
            borderRadius: "6px",
          }}
        >
          Add Product
          <BiPlus fontSize={"26px"} color="#ffffff" />
        </button>
      </div>
      <>
        <div
          style={{
            marginTop: "40px",
          }}
        >
          <CustomSearch
            value={searchProduct}
            onClear={() => {
              setSearchProduct("");
            }}
            onChange={(e) => setSearchProduct(e.target.value)}
            placeholder="Search Product"
          />
        </div>
        <div
          style={{
            marginTop: "40px",
          }}
        >
          <TableContainer
            columns={columns}
            data={productList}
            isGlobalFilter={true}
            customPageSize={100}
            isAddButton={false}
            pagination={false}
            noData={productList.length > 0 ? false : true}
            noDataTitle={"No Product"}
            isLoading={isLoading}
          />
        </div>
      </>
    </Fragment>
  );
};

export default Product;
