import React, { Fragment, useEffect, useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, IconButton, Typography } from "@mui/material";
import { GET_ORDER_PRODUCT_ALL } from "../../../Config/Api/ProductApi";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import useDebounce from "../../../Components/useDebounce/useDebounce";
import Swal from "sweetalert2";
import { FCM_NOTIFICATION } from "../../../Config/Api/Notification/Notification";
import { messaging } from "../../../Config/Firebase/Firebase";
import { getToken } from "firebase/messaging";
const ManufacturDashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchPurchase, setSearchPurchase] = useState("");
  const [purchaseList, setPurchaseList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const debouncedSearch = useDebounce(searchPurchase, 400);
  useEffect(() => {
    getPurchaseList();
  }, [debouncedSearch]);
  const getPurchaseList = async () => {
    const userDetailsString = localStorage.getItem("RoaveBox");
    if (userDetailsString) {
      try {
        const userDetails = JSON.parse(userDetailsString);
        const user_id = userDetails.id;
        const response = await GET_ORDER_PRODUCT_ALL({
          params: {
            user_id: user_id,
            search: debouncedSearch,
            is_blueprint: 1,
            count: pageNumber * 30,
          },
        });

        if (response?.s === 1 && response) {
          if (pageNumber === 0) {
            setPurchaseList(response?.r);
          } else {
            setPurchaseList((prevPurchaseList) => [
              ...prevPurchaseList,
              ...response?.r,
            ]);
          }
          if (response.r.length < 30) {
            setHasMore(false);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Session Expired",
            text: "Your session has expired. Please log in again.",
            confirmButtonText: "OK",
          }).then(() => {
            localStorage.clear();
            navigate("/login");
          });
        } else {
          console.error("Error fetching purchase list:", error);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };
  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BDv78Awy7R6Ap8c_ni9n9UE5_fWXQfeG7yXKrKoGp6Om7LW5H-NlnoNUo065SICFcwUv9JhoNMwA_mMcST0P9tI",
      });
      const deviceId = localStorage.getItem("deviceId");
      const formData = new FormData();
      formData.append("fcm_token", token);
      formData.append("device_id", deviceId);
      await FCM_NOTIFICATION(formData);
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }
    React.useEffect(() => {
      requestPermission();
    }, []);
  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: "",
        Cell: (row) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {Number(row.row.id) + 1}
            </Typography>
          );
        },
      },
      {
        Header: "Product",
        accessor: (d) => `${d?.product_details?.cover_img}`,
        Cell: ({ row }) => {
          return (
            <LazyLoadImage
              src={row?.original?.product_details?.cover_img}
              alt="Uploaded Image"
              effect="blur"
              width="45px"
              height="45px"
            />
          );
        },
      },
      {
        Header: "Product Name",
        accessor: (d) => `${d.name}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row?.original?.product_details?.name}
            </Typography>
          );
        },
      },
      {
        Header: "User Id ",
        accessor: (d) => `${d?.user_id}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row.original?.user_id}
            </Typography>
          );
        },
      },
      {
        Header: "Transaction Id ",
        accessor: (d) => `${d.transaction_id}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row.original?.transaction_id}
            </Typography>
          );
        },
      },
      {
        Header: "Payer Id ",
        accessor: (d) => `${d.payer_id}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row?.original?.payer_id}
            </Typography>
          );
        },
      },
      {
        Header: "Royalty Amount",
        accessor: (d) => `${d?.product_details?.royalty_amount}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {row?.original?.product_details?.royalty_amount}
            </Typography>
          );
        },
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <IconButton
                aria-label="view"
                onClick={() =>
                  navigate("/manufactur/purchase-history/view", {
                    state: {
                      purchaseId: row?.original?.id,
                    },
                  })
                }
                sx={{
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
              >
                <VisibilityIcon />
              </IconButton>
              {row?.original?.blueprint && (
                <IconButton
                  aria-label="Download"
                  sx={{
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                  }}
                  target="_blank"
                  download
                  href={`${row?.original?.blueprint?.media}`}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
  }, []);
  useEffect(() => {
    if (!hasMore) return;
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore]);
  return (
    <Fragment>
      <Box
        sx={{
          marginTop: "24px",
        }}
      >
        <CustomSearch
          value={searchPurchase}
          onClear={() => {
            setSearchPurchase("");
          }}
          onChange={(e) => setSearchPurchase(e.target.value)}
          placeholder="Search Purchase"
        />
      </Box>
      <Box sx={{ marginTop: "28px" }}>
        <TableContainer
          columns={columns}
          data={purchaseList}
          isGlobalFilter={true}
          customPageSize={30}
          isAddButton={false}
          pagination={false}
          noData={purchaseList.length > 0 ? false : true}
          noDataTitle={"No Found"}
          isLoading={isLoading}
        />
      </Box>
    </Fragment>
  );
};

export default ManufacturDashboard;
