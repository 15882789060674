import React from "react";
import { BiX } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import "./CustomSearch.scss";
const CustomSearch = (props) => {
  const { placeholder, onChange, onClear, value } = props;
  return (
    <div className="searchBoxDiv">
      <input
        autoComplete="off"
        type="text"
        name="focus"
        maxLength="200"
        className="searchInput"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {value !== undefined && value !== "" && (
        <button
          className="close-icon icon-times"
          type="reset"
          onClick={() => {
            onClear && onClear();
          }}
        >
          <BiX />
        </button>
      )}
      <BiSearch className="searchIcon" />
      {props.children}
    </div>
  );
};

export default CustomSearch;
