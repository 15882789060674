import AxiosInstance from "../../AxiosInstance";
// export const ADD_UPDATE_CONTACT_MANAGER_API = async (payload) => {
//     try {
//         const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/web-management/update-contact', payload);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };
export const GET_ANALYTICS_DATA  = async (type) => {
    try {
        const response = await AxiosInstance.get(AxiosInstance.defaults.baseURL +`/dashboard/analytics?type=${type}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const GET_REVENUE_DATA  = async (type) => {
    try {
        const response = await AxiosInstance.get(AxiosInstance.defaults.baseURL +`/dashboard/get-details?type=${type}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
