import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, FormFeedback, Input, NavLink } from "reactstrap";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { FORGOT_PASSWORD_API } from "../../../Config/Api/AuthApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import './Forgot.scss';
const Forgot = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please Enter valid Email Address")
        .required("Email Address Is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const params = {
        email: values.email,
      };
      try {
        const response = await FORGOT_PASSWORD_API(params);
        if (response) {
            if (response.s) {
                toast.success(response.m);
            } else {
                toast.error(response.m);
            }
        }
        resetForm();
    } catch (error) {
        toast.error(error.m)
        setIsLoading(false);
    } finally {
        setIsLoading(false);
    }
    },
  });
  return (
    <div className="forgotWrapper">
      <div className="loginCard">
        <div className="loginHeader">
          <h5 className="title">Forgot Password</h5>
          <p>Enter your email and we'll send you a link to reset your password.</p>
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <div className="inputGroup">
            <Input
              type="text"
              placeholder="Email Address"
              name="email"
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onKeyUp={formik.handleBlur}
              invalid={
                formik.touched.email && formik.errors.email ? true : false
              }
              autocomplete="email"
            />
            {formik.touched.email && formik.errors.email ? (
              <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
            ) : null}
          </div>
          <div className="submitButton">
            <CustomButton
              type="submit"
              className="submitBtn"
              btn_title="Forgot Password"
              loading={isLoading}
              disabled={!(formik.dirty && formik.isValid)}
            />
            <NavLink to={"/login"} onClick={() => navigate('/login')} className="allReady">
              Back To Login
            </NavLink>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Forgot;
