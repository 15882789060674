import React, { Fragment, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrume from "../../../../Components/BreadCrume/BreadCrume";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GET_ORDER_DETAILS } from "../../../../Config/Api/ProductApi";
const ManufacturPurchaseHistoryView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseUserDetails, setPurchaseUserDetails] = useState({});
  useEffect(() => {
    if (location?.state?.purchaseId) {
      const userId = location?.state?.purchaseId;
      getProductUserDetails(userId);
    }
  }, [location?.state?.purchaseId]);
  const getProductUserDetails = async (userId) => {
    try {
      const response = await GET_ORDER_DETAILS(userId);
      if (response?.s === 1 && response) {
        setPurchaseUserDetails(response?.r);
      }
    } catch (error) {
      console.error("Error fetching purchase list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Fragment>
      <BreadCrume
        showButton={false}
        title={"View Details"}
        onClick={() => navigate(-1)}
      />
      <Box
        sx={{
          maxWidth: "95%",
          margin: "100px auto 2px auto",
        }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <Box
                  sx={{
                    height: "365px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "10px 10px 10px 10px",
                  }}
                >
                  {isLoading ? (
                    <Skeleton height={"365px"} />
                  ) : (
                    <LazyLoadImage
                      src={purchaseUserDetails?.product_details?.cover_img}
                      alt="Uploaded Image"
                      effect="blur"
                      width="100%"
                      height="100%"
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000",
                        textAlign: "left",
                        width: "100%",
                        fontFamily: "Poppins",
                      }}
                    >
                      Product Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Product Name
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                            textAlign: "left",
                            width: "100%",
                            textTransform: "capitalize",
                            fontFamily: "Poppins",
                          }}
                        >
                          {purchaseUserDetails?.product_details?.name
                            ? purchaseUserDetails?.product_details?.name
                            : "N?N"}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Royalty Amount
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                            textAlign: "left",
                            width: "100%",
                            textTransform: "capitalize",
                            fontFamily: "Poppins",
                          }}
                        >
                          {purchaseUserDetails?.product_details?.royalty_amount
                            ? purchaseUserDetails?.product_details
                                ?.royalty_amount
                            : "N?N"}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Capacity
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                            textAlign: "left",
                            width: "100%",
                            textTransform: "capitalize",
                            fontFamily: "Poppins",
                          }}
                        >
                          {purchaseUserDetails?.product_details?.capacity
                            ? purchaseUserDetails?.product_details?.capacity
                            : "N?N"}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Size
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                            textAlign: "left",
                            width: "100%",
                            textTransform: "capitalize",
                            fontFamily: "Poppins",
                          }}
                        >
                          {purchaseUserDetails?.product_details?.size
                            ? purchaseUserDetails?.product_details?.size
                            : "N?N"}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Blue Print
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : purchaseUserDetails?.blueprint?.media ? (
                        <Button
                          type="button"
                          target="_blank"
                          download
                          href={`${purchaseUserDetails?.blueprint?.media}`}
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#000000",
                            textAlign: "left",
                            fontFamily: "Poppins",
                            backgroundColor: "#46ADF2",
                            color: "#ffffff",
                            "&:hover": {
                              backgroundColor: "#46ADF2",
                              color: "#ffffff",
                            },
                          }}
                        >
                          Download
                        </Button>
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000000",
                            textAlign: "left",
                            width: "100%",
                            textTransform: "capitalize",
                            fontFamily: "Poppins",
                          }}
                        >
                          Not Added
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000",
                        textAlign: "left",
                        width: "100%",
                        fontFamily: "Poppins",
                      }}
                    >
                      Payment Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Payer Id
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                        }}
                      >
                        {purchaseUserDetails?.payer_id
                          ? purchaseUserDetails?.payer_id
                          : "N?N"}
                      </Typography>)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Transaction ID
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                        }}
                      >
                        {purchaseUserDetails?.transaction_id
                          ? purchaseUserDetails?.transaction_id
                          : "N?N"}
                      </Typography>)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Total Amount
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                        }}
                      >
                        {purchaseUserDetails?.total_amount
                          ? `$ ${purchaseUserDetails?.total_amount}`
                          : "N?N"}
                      </Typography>)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#000000",
                        textAlign: "left",
                        width: "100%",
                        fontFamily: "Poppins",
                      }}
                    >
                      Status
                    </Typography>
                    {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Button
                      type="button"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#000000",
                        textAlign: "left",
                        fontFamily: "Poppins",
                        backgroundColor: "green",
                        color: "#ffffff",
                        marginTop: "10px",
                        "&:hover": {
                          backgroundColor: "green",
                          color: "#ffffff",
                        },
                      }}
                    >
                      {purchaseUserDetails?.payment_status ? purchaseUserDetails?.payment_status : "N/N"}
                    </Button>)}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000",
                        textAlign: "left",
                        width: "100%",
                        fontFamily: "Poppins",
                      }}
                    >
                      User Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Full Name
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                        }}
                      >
                        {purchaseUserDetails?.user_details
                          ? `${purchaseUserDetails?.user_details?.f_name} ${purchaseUserDetails?.user_details?.l_name}`
                          : "N?N"}
                      </Typography>)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Email
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                        }}
                      >
                        {purchaseUserDetails?.user_details?.email
                          ? purchaseUserDetails?.user_details?.email
                          : "N?N"}
                      </Typography>)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Phone Number
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                        }}
                      >
                        {purchaseUserDetails?.user_details?.phno
                          ? `${purchaseUserDetails?.user_details?.phno_cc} ${purchaseUserDetails?.user_details?.phno}`
                          : "N?N"}
                      </Typography>)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Company Name
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                        }}
                      >
                        {purchaseUserDetails?.user_details?.company_name
                          ? `${purchaseUserDetails?.user_details?.company_name}`
                          : "N?N"}
                      </Typography>)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          fontFamily: "Poppins",
                        }}
                      >
                        Address
                      </Typography>
                      {isLoading ? (
                        <Skeleton variant="text" animation="wave" width={200} />
                      ) : (<Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          textAlign: "left",
                          width: "100%",
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                        }}
                      >
                        {purchaseUserDetails?.user_details?.address
                          ? `${purchaseUserDetails?.user_details?.address}`
                          : "N?N"}
                      </Typography>)}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
};

export default ManufacturPurchaseHistoryView;
