import React from 'react'
import { FaArrowLeftLong } from "react-icons/fa6";
import './BreadCrume.scss';
import { Box, Button } from '@mui/material';
const BreadCrume = (props) => {
    const {title, onClick, title1, showButton, onSubmit} = props;
    return (
        <div className='customBreadCrume'>
            <button className='backArrow'
                onClick={onClick}
            >
                <FaArrowLeftLong color="#46ADF2" fontSize={"22px"} />
            </button>
            <h6 className='title'>
                {title}
            </h6>
            {showButton ? <Button type='button' sx={{
                background: '#FFFFFF',
                color:"#4D4E4E",
                fontSize:'18px',
                fontWeight:'500',
                textTransform:'capitalize',
                "&:hover":{
                    backgroundColor:'#ffffff'
                }

            }} onClick={onSubmit}>{title1}</Button> : <Box></Box>}
        </div>
    )
}

export default BreadCrume
