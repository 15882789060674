import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Skeleton from "react-loading-skeleton";

const TableContainer = ({
  columns,
  data,
  customPageSize,
  theadClass,
  maxHeight,
  onChangetr,
  pagination,
  noData,
  noDataTitle,
  isLoading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const tableRef = useRef(null);

  const handleScroll = () => {
    const bottom =
      tableRef.current.scrollHeight - tableRef.current.scrollTop ===
      tableRef.current.clientHeight;
    const top = tableRef.current.scrollTop === 0;

    if (bottom && canNextPage) {
      nextPage();
    } else if (top && canPreviousPage) {
      previousPage();
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef]);

  return (
    <Box className="tableContainer" ref={tableRef}>
      <Box
        sx={{
          overflow: "auto",
          maxHeight: {
            xs: "calc(100vh - 150px)",
            md: "calc(100vh - 150px)",
            lg: "calc(100vh - 150px)",
          },
        }}
      >
        <Table hover {...getTableProps()}>
          <TableHead
            className={theadClass}
            sx={{
              whiteSpace: "nowrap",
              backgroundColor: "#46ADF2",
              position: "sticky",
              top: 0,
              zIndex: 10,
            }}
          >
            {headerGroups?.map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup?.headers?.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        padding: "14px 12px",
                        color: "#ffffff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {column.render("Header")}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()} sx={{ whiteSpace: "nowrap" }}>
            {isLoading ? (
              [...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  {columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex}>
                      <Skeleton height={40} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : !noData && page.length > 0 ? (
              <>
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <TableRow
                        onChange={onChangetr}
                        spacing={8}
                        style={{
                          marginBottom:
                            rowIndex < page.length - 1 ? "10px" : "0",
                          marginTop: rowIndex < page.length - 1 ? "10px" : "0",
                        }}
                      >
                        {row?.cells?.map((cell) => {
                          return (
                            <TableCell
                              id="oops2"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                padding: "12px 12px",
                                color: "#000000",
                                whiteSpace: "nowrap",
                                textAlign: "center",
                              }}
                              key={cell.id}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td style={{ padding: "0" }} colSpan={"100%"}>
                  <div
                    className="noData"
                    style={{
                      textAlign: "center",
                      padding: "100px 0px",
                    }}
                  >
                    <div className="contentNoData">
                      <p>{noDataTitle}</p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

TableContainer.defaultProps = {
  pagination: true,
  noData: false,
  allSelcted: false,
  contentLoader: false,
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
