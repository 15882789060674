import React, { Fragment, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BreadCrume from "../../../Components/BreadCrume/BreadCrume";
import { GET_TO_FAVOURITE } from "../../../Config/Api/ProductApi";
const ManufacturFavouriteProduct = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [favouriteProduct, setFavouriteProduct] = useState([]);
  useEffect(() => {
    getFavouriteProduct();
  }, []);

  const getFavouriteProduct = async () => {
    setIsLoading(true);
    try {
      const response = await GET_TO_FAVOURITE();
      if (response && response.s === 1) {
        const product = response.r;
        setFavouriteProduct(product);
      }
    } catch (error) {
      console.error("Error fetching product list:", error);
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data
    }
  };
  return (
    <Fragment>
      <BreadCrume
        showButton={false}
        title={"Favourite Product"}
        onClick={() => navigate(-1)}
      />
      <Box
        sx={{
          maxWidth: "95%",
          margin: "100px auto 2px auto",
        }}
      >
        {isLoading ? <Skeleton height={365} count={3}/> : favouriteProduct?.length > 0 ? (
          favouriteProduct?.map((elem, index) => {
            return (
              <Card key={index} className="mb-3">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={3}>
                      <Box
                        sx={{
                          height: "365px",
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          padding: "10px 10px 10px 10px",
                        }}
                      >
                        {isLoading ? (
                          <Skeleton height={"365px"} />
                        ) : (
                          <LazyLoadImage
                            src={elem?.cover_img}
                            alt="Uploaded Image"
                            effect="blur"
                            width="100%"
                            height="100%"
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#000000",
                              textAlign: "left",
                              width: "100%",
                              fontFamily: "Poppins",
                            }}
                          >
                            Product Information
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                                fontFamily: "Poppins",
                              }}
                            >
                              Product ID
                            </Typography>
                            {isLoading ? (
                              <Skeleton
                                variant="text"
                                animation="wave"
                                width={200}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  textTransform: "capitalize",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {elem?.product_id ? elem?.product_id : "N?N"}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                                fontFamily: "Poppins",
                              }}
                            >
                              Product Name
                            </Typography>
                            {isLoading ? (
                              <Skeleton
                                variant="text"
                                animation="wave"
                                width={200}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  textTransform: "capitalize",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {elem?.name ? elem?.name : "N?N"}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                                fontFamily: "Poppins",
                              }}
                            >
                              Royalty Amount
                            </Typography>
                            {isLoading ? (
                              <Skeleton
                                variant="text"
                                animation="wave"
                                width={200}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  textTransform: "capitalize",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {elem?.royalty_amount
                                  ? elem?.royalty_amount
                                  : "N?N"}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                                fontFamily: "Poppins",
                              }}
                            >
                              Capacity
                            </Typography>
                            {isLoading ? (
                              <Skeleton
                                variant="text"
                                animation="wave"
                                width={200}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  textTransform: "capitalize",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {elem?.capacity ? elem?.capacity : "N?N"}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                                fontFamily: "Poppins",
                              }}
                            >
                              Size
                            </Typography>
                            {isLoading ? (
                              <Skeleton
                                variant="text"
                                animation="wave"
                                width={200}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  textTransform: "capitalize",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {elem?.size ? elem?.size : "N?N"}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                                fontFamily: "Poppins",
                              }}
                            >
                              Uses
                            </Typography>
                            {isLoading ? (
                              <Skeleton
                                variant="text"
                                animation="wave"
                                width={200}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  textTransform: "capitalize",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {elem?.uses ? elem?.uses : "N?N"}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                                fontFamily: "Poppins",
                              }}
                            >
                              Description
                            </Typography>
                            {isLoading ? (
                              <Skeleton
                                variant="text"
                                animation="wave"
                                width={200}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  textTransform: "capitalize",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {elem?.description ? elem?.description : "N?N"}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "100px 0px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000",
                textAlign: "center",
                width: "100%",
                fontFamily: "Poppins",
              }}
            >
              No Favourite Found
            </Typography>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default ManufacturFavouriteProduct;
