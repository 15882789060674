import React from "react";
import { Modal, ModalBody } from "reactstrap";
const CustomModel = (prop) => {
  const { show, children, onCloseClick } = prop;
  return (
    <Modal className='customModal' isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};
export default CustomModel;