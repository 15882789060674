import React, { Fragment, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import ManufacturFaq from "./ManufacturFaq/ManufacturFaq";
import "./ManufacturHelpSupport.scss";
import ManufacturPrivacyPolicy from "./ManufacturPrivacyPolicy/ManufacturPrivacyPolicy";
import ManufacturTermAndCondition from "./ManufacturTermAndCondition/ManufacturTermAndCondition";
import ManufacturContactUs from "./ManufacturContactUs/ManufacturContactUs";
import ManufacturAboutUs from "./ManufacturAboutUs/ManufacturAboutUs";
const ManufacturHelpSupport = () => {
    const [activeTabs, setActiveTabs] = useState("aboutUs");
    const tabTitle = [
        {
            label: "About Us",
            value: "aboutUs",
        },
        {
            label: "FAQ",
            value: "faq",
        },
        {
            label: "Privacy policy",
            value: "privacypolicy",
        },
        {
            label: "Terms & Condition",
            value: "termsCondition",
        },
        {
            label: "Contact Us",
            value: "contactUs",
        },
    ];
    const handleChangeTabs = (newValue) => {
        setActiveTabs(newValue);
    };
    return (
        <Fragment>
            <div className="manufacturHelpSupport">
                <Nav tabs>
                    {tabTitle.map(
                        (tab, idx) => (
                            <NavItem key={idx}>
                                <NavLink
                                    className={activeTabs === tab.value ? "active" : ""}
                                    onClick={() => handleChangeTabs(tab.value)}
                                >
                                    {tab.label}
                                </NavLink>
                            </NavItem>
                        )
                    )}
                </Nav>
                <TabContent activeTab={activeTabs}>
                    <TabPane tabId="aboutUs">
                        <ManufacturAboutUs />
                    </TabPane>
                    <TabPane tabId="faq">
                        <ManufacturFaq />
                    </TabPane>
                    <TabPane tabId="privacypolicy">
                        <ManufacturPrivacyPolicy />
                    </TabPane>
                    <TabPane tabId="termsCondition">
                        <ManufacturTermAndCondition />
                    </TabPane>
                    <TabPane tabId="contactUs">
                        <ManufacturContactUs />
                    </TabPane>
                </TabContent>
            </div>
        </Fragment>
    );
};

export default ManufacturHelpSupport;
