import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import {
  Badge,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { GET_NOTIFICATION } from "../../../Config/Api/Notification/Notification";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  GET_USER_API_ID,
  UPDATE_USER_API,
  UPDATE_USER_STATUS,
} from "../../../Config/Api/users/UsersApi";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = JSON.parse(localStorage.getItem("RoaveBox"));
  const [openNotification, setOpenNotification] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [notificationList, setNotificationList] = React.useState([]);
  const [activeNotification, setActiveNotification] = React.useState(null);
  React.useEffect(() => {
    const getNotification = async () => {
      try {
        const response = await GET_NOTIFICATION();
        if (response && response?.s === 1) {
          setNotificationList(response?.r);
        } else {
          toast.error(response?.m);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getNotification();
  }, []);

  React.useEffect(() => {
    getUserDetails();
  }, []);

  React.useEffect(() => {
    if (openNotification) {
      handleUpdateNotificationStatus();
    }
  }, [openNotification]);

  const getUserDetails = async () => {
    const userId = JSON.parse(localStorage.getItem("RoaveBox"));
    if (userId) {
      const id = userId?.id;
      const response = await GET_USER_API_ID(id);
      if (response?.s === 1 && response) {
        setActiveNotification(response?.r?.new_notification);
      }
    }
  };

  const formatNotificationDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const handleOpenAccount = () => {
    setOpenProfile(false);
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete Account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const userDetails = JSON.parse(localStorage.getItem("RoaveBox"));
        const formData = new FormData();
        formData.append("status", 0);
        formData.append("user_id", userDetails?.id);
        try {
          const response = await UPDATE_USER_STATUS(formData);
          if (response?.s === 1 && response) {
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              title: "Deleted!",
              text: response?.m,
            });
            navigate("/login");
          } else {
            Swal.fire({
              title: "Update!",
              text: response?.m,
              icon: "error",
            });
          }
        } catch (error) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  const handleLogout = () => {
    setOpenProfile(false);
    Swal.fire({
      title: "Are you sure?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        Swal.fire({
          position: "center",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          title: "Logged Out",
          text: "You have been successfully logged out.",
        });
        navigate("/login");
      }
    });
  };

  const handleUpdateNotificationStatus = async () => {
    const userId = JSON.parse(localStorage.getItem("RoaveBox"));
    if (userId) {
      const id = userId?.id;
      const formData = new FormData();
      formData.append("user_id", id);
      formData.append("new_notification", 0);
      try {
        const response = await UPDATE_USER_API(formData);
        if (response?.s === 1 && response) {
          setActiveNotification(null);
        } else {
          toast.error(response?.m);
        }
      } catch (error) {
        console.log(
          "Error occurred while updating notification status:",
          error
        );
        toast.error(
          "Failed to update notification status. Please try again later."
        );
      }
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: "500",
            fontSize: "24px",
            color: "#000000",
            fontFamily: "Poppins",
          }}
        >
          {location?.pathname === "/manufactur/dashboard"
            ? "Dashboard"
            : location?.pathname === "/manufactur/product-showcase"
            ? "Product"
            : location?.pathname === "/manufactur/help-support"
            ? "Help/Support"
            : "Purchase History"}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Favourite">
            <IconButton
              size="large"
              color="inherit"
              onClick={(e) => navigate("/manufactur/favourite-product")}
            >
              <FavoriteBorderIcon sx={{ fontSize: "28px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notification">
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={(e) => setOpenNotification(true)}
              sx={{
                position:'relative'
              }}
            >
              <Badge
                color="error"
                variant={activeNotification === 1 ? "dot" : "none"}
              >
                <NotificationsNoneIcon sx={{ fontSize: "32px" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Profile">
            <IconButton
              size="large"
              color="inherit"
              onClick={(e) => setOpenProfile(true)}
            >
              <Avatar alt="Remy Sharp" src={userDetails?.profile_img} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "70px" }}
            id="menu-appbar"
            anchorEl={openNotification}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(openNotification)}
            onClose={() => setOpenNotification(false)}
            PaperProps={{ style: { width: "400px" } }}
          >
            {notificationList?.length > 0 ? (
              notificationList?.map((elem, index) => (
                <List
                  key={index}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Avatar">
                        {elem?.title ? elem.title.charAt(0) : ""}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={elem?.title}
                      secondary={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gridGap: "10px",
                          }}
                        >
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {elem?.message}
                          </Typography>
                          <Typography
                            sx={{ display: "block" }}
                            component="span"
                            variant="caption"
                            color="text.secondary"
                          >
                            {formatNotificationDate(elem?.created_at)}{" "}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              ))
            ) : (
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#000",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "25px 0px",
                }}
              >
                No Notification Found
              </Typography>
            )}
          </Menu>

          <Menu
            sx={{ mt: "70px" }}
            id="menu-appbar"
            anchorEl={openProfile}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(openProfile)}
            onClose={() => setOpenProfile(false)}
            PaperProps={{ style: { width: "200px" } }}
          >
            <List
              sx={{
                cursor: "pointer",
              }}
            >
              <ListItem onClick={() => navigate("/manufactur/edit-profile")}>
                <Typography
                  textAlign="left"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#000",
                    width: "100%",
                  }}
                >
                  Edit Profile
                </Typography>
              </ListItem>
              <ListItem onClick={() => handleOpenAccount()}>
                <Typography
                  textAlign="left"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#000",
                    width: "100%",
                  }}
                >
                  Delete Account
                </Typography>
              </ListItem>
              <ListItem onClick={() => handleLogout()}>
                <Typography
                  textAlign="left"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#000",
                    width: "100%",
                  }}
                >
                  Logout
                </Typography>
              </ListItem>
            </List>
          </Menu>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Header;
