import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ToastComponent from "./Components/ToastComponent/ToastComponent";
import routes from "./routes";
import "sweetalert2/src/sweetalert2.scss";
import { getToken } from "firebase/messaging";
import { messaging } from "./Config/Firebase/Firebase";
import "./App.css";
import { FCM_NOTIFICATION } from "./Config/Api/Notification/Notification";
const App = () => {
  return (
    <Fragment>
      <ToastComponent />
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={route.component}
                exact={route.exact}
                isAuthenticated={route.isAuthenticated}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
