import React, { Fragment, useState } from "react";
import BrandLogo from "../../../Assets/Images/logo.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ICON1 from "../../../Assets/Images/icon1.png";
import ICON2 from "../../../Assets/Images/icon2.png";
import ICON3 from "../../../Assets/Images/icon3.png";
import ICON4 from "../../../Assets/Images/icon4.png";
import ICON5 from "../../../Assets/Images/icon5.png";
import ICON6 from "../../../Assets/Images/icon6.png";
import ICON7 from "../../../Assets/Images/icon7.png";
import ICON8 from "../../../Assets/Images/icon8.png";
import logoutIcon from '../../../Assets/Images/contactIcon.png';
import "./Sidebar.scss";
import Swal from "sweetalert2";

const Sidebar = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const navLinkRoutes = [
    {
      path: "/manufactur/dashboard",
      icon: ICON5,
      activeIcon: ICON1,
      title: "Dashboard",
    },
    {
      path: "/manufactur/product-showcase",
      icon: ICON6,
      activeIcon: ICON2,
      title: "Product Showcase",
    },
    {
      path: "/manufactur/purchase-history",
      icon: ICON7,
      activeIcon: ICON3,
      title: "Purchase History",
    },
    {
      path: "/manufactur/help-support",
      icon: ICON8,
      activeIcon: ICON4,
      title: "Help/Support",
    },
  ];
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        Swal.fire({
          position: "center",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          title: "Logged Out",
          text: "You have been successfully logged out.",
        });
        navigate("/login");
      }
    });
  };
  return (
    <Fragment>
      <div className="sidebar">
        <div className="sidebarContainer">
          <div className="brandLogo">
            <img src={BrandLogo} alt="logo" />
          </div>
          <div className="navigationLink">
            <ul className="nav_Item">
              {navLinkRoutes.map((elem, index) => (
                <li key={index}>
                  <NavLink 
                    to={elem.path} 
                    className="navLink"
                    onMouseEnter={() => setHoveredItem(index)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <div
                      className={
                        elem.path === location.pathname || index === hoveredItem
                          ? "active"
                          : "nav_Link"
                      }
                    >
                      <img
                        src={
                          elem.path === location.pathname || index === hoveredItem
                            ? elem.activeIcon
                            : elem.icon
                        }
                        alt="icon"
                      />
                      <span className="text">{elem.title}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="logOutButton" onClick={() => handleLogout()}>
              <button type="button"> <img src={logoutIcon} alt="logout"/> Log Out</button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
