import React, { Fragment, useMemo, useState, useEffect, useRef } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VIEWICON from "../../../../Assets/Images/edit.png";
import DELETEICON from "../../../../Assets/Images/reject.png";
import USERADD from "../../../../Assets/Images/accept.png";
import DummayUser from "../../../../Assets/Images/dummy-profile.jpg";
import {
  GET_ALL_USER_API,
  UPDATE_USER_API,
  UPDATE_USER_STATUS,
} from "../../../../Config/Api/users/UsersApi";
import blokedusericon from "../../../../utils/BlockedUser.svg";

import TableContainer from "../../../../Components/TableContainer/TableContainer";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import CustomSearch from "../../../../Components/CustomSearch/CustomSearch";
import CustomDropdown from "../../../../Components/CustomDropdown/CustomDropdown";
import "./AllMenufacture.scss";
import { toast } from "react-toastify";
import CustomModel from "../../../../Components/CustomModel/CustomModel";
import { IoEyeOutline } from "react-icons/io5";

import { RiDeleteBin6Line, RiUserForbidLine } from "react-icons/ri";
import Swal from "sweetalert2";
import { BiSolidCheckCircle } from "react-icons/bi";
const AllMenufacture = ({ activeTab }) => {
  const navigate = useNavigate();
  const loadingActionRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingAction, setLoadingAction] = useState(false);
  const [searchManufacture, setSearchManufacture] = useState("");
  const [allMenufactureList, setAllMenufactureList] = useState([]);
  const [selectedMenufacture, setSelectedManufacture] = useState({
    id: "1",
    value: "All Manufacturer",
    label: "All Manufacturer",
  });
  const [manufactureListDropDownList, setManufactureListDropDownList] =
    useState([
      {
        id: "1",
        value: "All Manufacturer",
        label: "All Manufacturer",
      },
      {
        id: "2",
        value: "Manufacturer Reject",
        label: "Manufacturer Reject",
        is_approve: -1,
      },
      {
        id: "3",
        value: "Manufacturer Accept",
        label: "Manufacturer Accept",
        is_approve: 1,
      },
    ]);

  let UpdateUser = (values) => {
    Swal.fire({
      title: ` Are you sure  ?`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${
        values?.type === 0
          ? "Delete!"
          : values?.type === -1
          ? "Block it!"
          : "Unblock it !"
      } `,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await UPDATE_USER_STATUS({
            status: values?.type,
            user_id: values?.Userid,
          });

          if (res?.s == 1) {
            // getUser();
            getAllMenufactureList();
            Swal.fire({
              position: "center",
              icon: "success",
              title:
                values?.type === 0
                  ? "Deleted"
                  : values?.type === -1
                  ? "Blocked "
                  : "Unblocked",
              text: ` Account has been ${
                values?.type === 0
                  ? "Deleted"
                  : values?.type === -1
                  ? "Blocked "
                  : "Unblocked"
              }`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  const debouncedSearch = useDebounce(searchManufacture, 400);
  const [isreasonmodalopen, setisreasonmodalopen] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        Header: "#",
        accessor: (d) => `${d.id}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">{Number(row.row.id) + 1}</div>
          );
        },
      },
      {
        Header: "Profile Photo",
        accessor: (d) => `${d?.profile_img}`,
        Cell: (row) => {
          return (
            <Box>
              <img
                src={
                  row?.row?.original?.profile_img
                    ? row?.row?.original?.profile_img
                    : DummayUser
                }
                alt="profilephoto"
                width={"45px"}
                height={"45px"}
                style={{ objectFit: "cover" }}
              />
            </Box>
          );
        },
      },
      {
        Header: "Username",
        accessor: (d) => `${d?.f_name} ${d?.l_name}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row?.row?.original?.f_name} {row?.row?.original?.l_name}
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: (d) => `${d?.email}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">{row?.row?.original?.email}</div>
          );
        },
      },
      {
        Header: "Address",
        accessor: (d) => `${d?.address}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row?.row?.original?.address}
            </div>
          );
        },
      },
      {
        Header: "Mobile Number",
        accessor: (d) => `${d?.phno}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              {row?.row?.original?.phno ? row?.row?.original?.phno : "N/A"}
            </div>
          );
        },
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <Box  sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:"5px"}}>
              <IconButton
               sx={{
                border: "none",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "3",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              }}
                aria-label="view"
                onClick={() =>
                  navigate("/admin/manufaturer-details", {
                    state: {
                      userId: row?.original?.id,
                    },
                  })
                }
              >
                 <IoEyeOutline fontSize={"18px"} color="#46ADF2" />
              </IconButton>
              
              {selectedMenufacture?.value === "Manufacturer Accept" && (
                <IconButton
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                  aria-label="BlockUnblock"
                  onClick={() =>
                    UpdateUser({
                      type: row?.original?.status === 1 ? -1 : 1,
                      Userid: row?.original?.id,
                    })
                  }
                >
                {row?.original?.status == 1 ? (
                  <RiUserForbidLine fontSize={"18px"} color="#FF9356" />
                ) : (
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={blokedusericon}
                  />
                )}  
                </IconButton>
              )}
              {selectedMenufacture?.value === "Manufacturer Accept" && (
                <IconButton
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                  aria-label="delete"
                  onClick={() =>  UpdateUser({ type: 0, Userid: row?.row?.original?.id })}
                >
                 <RiDeleteBin6Line fontSize={"18px"} color="red" />
                </IconButton>
              )}
              {selectedMenufacture?.value === "Manufacturer Reject" && (
                <IconButton
                sx={{
                  border: "none",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                  aria-label="add"
                  onClick={() => updateMenufactureStatus(row?.original?.id, 1)}
                >
                  <BiSolidCheckCircle fontSize={"18px"} color="green" />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
  }, [selectedMenufacture]);

  useEffect(() => {
    if (activeTab === "manufacturers") {
      if (selectedMenufacture?.value !== "All Manufacturer") {
        getAllMenufactureList(selectedMenufacture?.is_approve);
      } else {
        getAllMenufactureList();
      }
    }
  }, [debouncedSearch, activeTab, selectedMenufacture]);

  const getAllMenufactureList = async (isApprove) => {
    try {
      const response = await GET_ALL_USER_API({
        params: {
          role: 1,
          count: 0,
          search: debouncedSearch,
          ...(isApprove && { is_approve: isApprove }),
        },
      });
      if (response && response?.s === 1) {
        setAllMenufactureList(response?.r);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const updateMenufactureStatus = async (id, action) => {
    if (loadingActionRef.current) return; // If API call is already in progress, prevent further calls
    loadingActionRef.current = true;
    if (action == -1) {
      setisreasonmodalopen(true);
    } else {
      try {
        const response = await UPDATE_USER_API({
          user_id: id,
          is_approve: action,
        });
        if (response?.s === 1 && response) {
          if (action === 1 || action === -1) {
            toast.success(response?.m);
            getAllMenufactureList(selectedMenufacture?.is_approve);
          }
        } else {
          toast.error(response?.m);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        loadingActionRef.current = false; // Reset loading state after API call is completed
      }
    }
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={3}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={12} md={6} lg={7}>
          <CustomSearch
            value={searchManufacture}
            onClear={() => {
              setSearchManufacture("");
            }}
            onChange={(e) => setSearchManufacture(e.target.value)}
            placeholder="Search Manufacture"
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={5}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          item
        >
          <CustomDropdown
            selectedValue={selectedMenufacture}
            dropDownItems={manufactureListDropDownList.map((c, i) => ({
              id: c?.id,
              value: c?.value,
              label: c?.label,
              is_approve: c?.is_approve,
            }))}
            placeholder={"Select Manufacture"}
            onSelect={(evt) => {
              setSelectedManufacture(evt);
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "28px" }}>
        <TableContainer
          columns={columns}
          data={allMenufactureList}
          isGlobalFilter={true}
          customPageSize={30}
          isAddButton={false}
          pagination={false}
          noData={allMenufactureList.length > 0 ? false : true}
          noDataTitle={"No Found Manufacturer"}
          isLoading={isLoading}
          maxHeight={{xs:"calc(100vh - 310px)",md:"calc(100vh - 310px)", overflowY: "auto"}}
        />
      </Box>
      <CustomModel
        show={isreasonmodalopen}
        onCloseClick={() => setisreasonmodalopen(false)}
      >
        <div className="deleteModel">
          <h6>Are you sure you want to Delete Account?</h6>
          <div className="action">
            <button
              type="button"
              className="cBtn yes"
              onClick={() => setisreasonmodalopen(false)}
            >
              Yes
            </button>
            <button
              type="button"
              className="cBtn no"
              onClick={() => setisreasonmodalopen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModel>
    </Fragment>
  );
};

export default AllMenufacture;
