import React, { Fragment, useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { Form, FormFeedback, Input, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { toast } from "react-toastify";
import "./ContactManager.scss";
import ReactInputMask from "react-input-mask";
import Skeleton from "react-loading-skeleton";
import { ADD_UPDATE_CONTACT_MANAGER_API, GET_CONTACT_MANAGER_API} from "../../../../Config/Api/Admin/ContactManagerApi";
const ContactManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditModeTwo, setIsEditModeTwo] = useState(false);
  useEffect(() => {
    getContactManagerManager();
  }, [])
  const getContactManagerManager = async () => {
    try {
      setIsLoading(true);
      const response = await GET_CONTACT_MANAGER_API();
      if (response) {
        if (response?.s) {
          formikContactValidation.setValues({
            email: response?.r?.email,
            phoneNumber: response?.r?.phno,
            address: response?.r?.address,
          });
          formikSocialMediaValidation.setValues({
            instagram: response?.r?.instagram,
            facebook: response?.r?.facebook,
            twitter: response?.r?.twitter,
            linkedIn: response?.r?.linkedIn,
          });
          setIsEditMode(true);
          setIsEditModeTwo(true);
        } else {
          toast.error(response.m);
        }
      }
    } catch (error) {
      toast.error(error.m);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const formikContactValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phno: "",
      address: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter valid email address")
        .required("Email address is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("phno", values.phoneNumber);
      formData.append("address", values.address);
      try {
        setIsLoading(true);
        const response = await ADD_UPDATE_CONTACT_MANAGER_API(formData);
        if (response) {
          if (response?.s) {
            toast.success(response.m);
            getContactManagerManager();
          } else {
            toast.error(response.m);
          }
        }
      } catch (error) {
        toast.error(error.m);
        setBusy(false);
        setIsLoading(false);
      } finally {
        setBusy(false);
        setIsLoading(false);
      }
      resetForm();
    },
  });
  const formikSocialMediaValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      instagram: "",
      facebook: "",
      linkedin: "",
      twitter: "",
    },
    validationSchema: Yup.object({
      instagram: Yup.string().matches(
        /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+\/?$/,
        "Please enter valid instagram URL"
      ),
      facebook: Yup.string().matches(
        /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_]+\/?$/,
        "Please enter valid facebook URL"
      ),
      twitter: Yup.string().matches(
        /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/,
        "Please enter valid twitter URL"
      ),
      linkedin: Yup.string().matches(
        /^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z0-9_]+\/?$/,
        "Please enter valid linkedIn URL"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("instagram", values.instagram);
      formData.append("facebook", values.facebook);
      formData.append("linkdin", values.linkedin);
      formData.append("twitter", values.twitter);
      try {
        setIsLoading(true);
        const response = await ADD_UPDATE_CONTACT_MANAGER_API(formData);
        if (response) {
          if (response?.s) {
            toast.success(response.m);
            getContactManagerManager();
          } else {
            toast.error(response.m);
          }
        }
      } catch (error) {
        toast.error(error.m);
        setBusy(false);
        setIsLoading(false);
      } finally {
        setBusy(false);
        setIsLoading(false);
      }
      resetForm();
    },
  });
  return (
    <Fragment>
      <div className="contactManager">
          <div className="contactContainer">
          {isLoading ? <Skeleton height={250}/> : <div className="titleManagerCard">
              <div className="headingSection">
                <h6>Contact Details</h6>
                {isEditMode && (
                  <button
                    className="editButton"
                    type="button"
                    onClick={() => setIsEditMode(false)}
                  >
                    <FaRegEdit fontSize={"18px"} />
                  </button>
                )}
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formikContactValidation.handleSubmit();
                  return false;
                }}
              >
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formikContactValidation.handleChange(e);
                    }}
                    onBlur={formikContactValidation.handleBlur}
                    value={formikContactValidation.values.email}
                    onKeyUp={formikContactValidation.handleBlur}
                    invalid={
                      formikContactValidation.touched.email &&
                      formikContactValidation.errors.email
                        ? true
                        : false
                    }
                    disabled={isEditMode}
                  />
                  {formikContactValidation.touched.email &&
                  formikContactValidation.errors.email ? (
                    <FormFeedback type="invalid">
                      {formikContactValidation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <ReactInputMask
                    mask={"+\\91 9999999999"}
                    maskChar={""}
                    name="phoneNumber"
                    className={
                      formikContactValidation.errors.phoneNumber &&
                      formikContactValidation.touched.phoneNumber
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    id="exampleFormControlInput1"
                    onChange={formikContactValidation.handleChange}
                    onKeyUp={formikContactValidation.handleBlur}
                    value={formikContactValidation.values.phoneNumber}
                    placeholder="Phone Number"
                    disabled={isEditMode}
                  />
                  {formikContactValidation.touched.phoneNumber &&
                  formikContactValidation.errors.phoneNumber ? (
                    <FormFeedback type="invalid">
                      {formikContactValidation.errors.phoneNumber}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <div className="inputGroupPasword">
                    <Input
                      rows={3}
                      style={{
                        resize: "none",
                      }}
                      type={"textarea"}
                      className="form-control"
                      placeholder="Address"
                      name="address"
                      value={formikContactValidation.values.address}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formikContactValidation.handleChange(e);
                      }}
                      onBlur={formikContactValidation.handleBlur}
                      onKeyUp={formikContactValidation.handleBlur}
                      invalid={
                        formikContactValidation.touched.address &&
                        formikContactValidation.errors.address
                          ? true
                          : false
                      }
                      disabled={isEditMode}
                    />
                    {formikContactValidation.touched.address &&
                    formikContactValidation.errors.address ? (
                      <FormFeedback type="invalid">
                        {formikContactValidation.errors.address}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="submitButton">
                  <CustomButton
                    type="submit"
                    className="submitBtn"
                    btn_title="Save"
                    loading={busy}
                    disabled={
                      !(
                        formikContactValidation.isValid &&
                        formikContactValidation.dirty &&
                        !isEditMode
                      )
                    }
                  />
                </div>
              </Form>
            </div>}
            {isLoading ? <Skeleton height={250}/> : <div
              className="titleManagerCard"
              style={{
                marginTop: "20px",
              }}
            >
              <div className="headingSection">
                <h6>Social Media Links</h6>
                {isEditModeTwo && (
                  <button
                    className="editButton"
                    type="button"
                    onClick={() => setIsEditModeTwo(false)}
                  >
                    <FaRegEdit fontSize={"18px"} />
                  </button>
                )}
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formikSocialMediaValidation.handleSubmit();
                  return false;
                }}
              >
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Instagram"
                    name="instagram"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formikSocialMediaValidation.handleChange(e);
                    }}
                    onBlur={formikSocialMediaValidation.handleBlur}
                    value={formikSocialMediaValidation.values.instagram}
                    onKeyUp={formikSocialMediaValidation.handleBlur}
                    invalid={
                      formikSocialMediaValidation.touched.instagram &&
                      formikSocialMediaValidation.errors.instagram
                        ? true
                        : false
                    }
                    disabled={isEditModeTwo}
                  />
                  {formikSocialMediaValidation.touched.instagram &&
                  formikSocialMediaValidation.errors.instagram ? (
                    <FormFeedback type="invalid">
                      {formikSocialMediaValidation.errors.instagram}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Facebook"
                    name="facebook"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formikSocialMediaValidation.handleChange(e);
                    }}
                    onBlur={formikSocialMediaValidation.handleBlur}
                    value={formikSocialMediaValidation.values.facebook}
                    onKeyUp={formikSocialMediaValidation.handleBlur}
                    invalid={
                      formikSocialMediaValidation.touched.facebook &&
                      formikSocialMediaValidation.errors.facebook
                        ? true
                        : false
                    }
                    disabled={isEditModeTwo}
                  />
                  {formikSocialMediaValidation.touched.facebook &&
                  formikSocialMediaValidation.errors.facebook ? (
                    <FormFeedback type="invalid">
                      {formikSocialMediaValidation.errors.facebook}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Twitter"
                    name="twitter"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formikSocialMediaValidation.handleChange(e);
                    }}
                    onBlur={formikSocialMediaValidation.handleBlur}
                    value={formikSocialMediaValidation.values.twitter}
                    onKeyUp={formikSocialMediaValidation.handleBlur}
                    invalid={
                      formikSocialMediaValidation.touched.twitter &&
                      formikSocialMediaValidation.errors.twitter
                        ? true
                        : false
                    }
                    disabled={isEditModeTwo}
                  />
                  {formikSocialMediaValidation.touched.twitter &&
                  formikSocialMediaValidation.errors.twitter ? (
                    <FormFeedback type="invalid">
                      {formikSocialMediaValidation.errors.twitter}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="inputGroup" style={{ marginBottom: "20px" }}>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Linkedin"
                    name="linkedin"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formikSocialMediaValidation.handleChange(e);
                    }}
                    onBlur={formikSocialMediaValidation.handleBlur}
                    value={formikSocialMediaValidation.values.linkedin}
                    onKeyUp={formikSocialMediaValidation.handleBlur}
                    invalid={
                      formikSocialMediaValidation.touched.linkedin &&
                      formikSocialMediaValidation.errors.linkedin
                        ? true
                        : false
                    }
                    disabled={isEditModeTwo}
                  />
                  {formikSocialMediaValidation.touched.linkedin &&
                  formikSocialMediaValidation.errors.linkedin ? (
                    <FormFeedback type="invalid">
                      {formikSocialMediaValidation.errors.linkedin}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="submitButton">
                  <CustomButton
                    type="submit"
                    className="submitBtn"
                    btn_title="Save"
                    loading={busy}
                    disabled={
                      !(
                        formikSocialMediaValidation.isValid &&
                        formikSocialMediaValidation.dirty &&
                        !isEditModeTwo
                      )
                    }
                  />
                </div>
              </Form>
            </div>}
          </div>
      </div>
    </Fragment>
  );
};

export default ContactManager;
