import AxiosInstance from "../../../AxiosInstance";
export const GET_ALL_PRODUCT_API = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + "/product/get-all",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DELETE_PRODUCT_API = async (productId) => {
  const payload = {
    id: productId,
  };
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/product/delete-product",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const ADD_PRODUCT_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/product/add",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UPDATE_PRODUCT_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/product/update",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const ADD_MEDIA_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/product/add-media",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UPDATE_MEDIA_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/product/update-media",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DELETE_MEDIA_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/product/delete-product",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const ADD_DOCUMENT_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/user/add-document",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UPDATE_DOCUMENT_API = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "user/ducoment-update",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_DOCUMENT_API = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + `/user/get-document?user_id=${payload}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_BY_ID_PRODUCT_API = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL +
        `/product/get-by-id?product_id=${payload}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const ORDER_PRODUCT = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL +
        `/order/get-orders`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const ADD_BLUEPRINT = async (payload) => {
  try {
   
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL +`/order/add-blueprint-media`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
