import AxiosInstance from "../../../AxiosInstance";

export const UPDATE_GENERAL_DOCS = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      AxiosInstance.defaults.baseURL + "/web-management/update-general-docs",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GET_GENERAL_DOCS = async (payload) => {
  try {
    const response = await AxiosInstance.get(
      AxiosInstance.defaults.baseURL + "/web-management/get-general-docs",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
