import AxiosInstance from "../../../AxiosInstance";
export const ADD_TESTIMONIALS_MANAGER_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/web-management/add-testimonials', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const DELETE_TESTIMONIALS_MANAGER_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/web-management/update-testimonials', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const GET_TESTIMONIALS_API = async () => {
    try {
        const response = await AxiosInstance.get(AxiosInstance.defaults.baseURL + '/web-management/get-testimonials');
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const UPDATE_TESTIMONIALS_MANAGER_API = async (payload) => {
    try {
        const response = await AxiosInstance.post(AxiosInstance.defaults.baseURL + '/web-management/update-testimonials', payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};